import { Box } from "@material-ui/core";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC, ReactElement, useCallback, useMemo } from "react";
import styled from "styled-components";

import { NewGraduateMemo } from "../../contactRooms/ContactRoomItem/parts/NewGraduateSummarySidebar/NewGraduateMemo";

import { NewGraduateRichMenu } from "../../contactRooms/ContactRoomItem/parts/NewGraduateSummarySidebar/NewGraduateRichMenu";
import { NewGraduateStatusChip } from "../NewGraduateStatusChip";

import { IconList, Section } from "./components";

import { Button, Divider, Loading, Modal, Typography } from "~/components/uiParts";
import {
  useEmployee,
  useEmployees,
  useNewGraduate,
  useUpdateNewGraduateMemo,
} from "~/hooks/employee";
import { useRichMenuIdByLineUserId } from "~/hooks/richMenu/useRichMenuIdByLineUserId";

type Props = {
  open: boolean;
  onCancel: () => void;
  newGraduate: NewGraduateToDisplay;
};

export const SPNewGraduateDetailModal: FC<Props> = ({
  open,
  onCancel,
  newGraduate: _newGraduate,
}) => {
  // NOTE: モーダル内部でデータ更新を行うため再度取得
  const { data: newGraduate, isLoading: isLoadingEmployee } = useNewGraduate({
    newGraduateId: _newGraduate.id || "",
  });
  const { data: richMenuData, isLoading: isLoadingRichMenuUrl } = useRichMenuIdByLineUserId({
    lineUserId:
      newGraduate?.selectedAuthenticationFlowType === "line" ? newGraduate?.lineUserId : undefined,
  });

  // 担当者・サポートメンバー
  const { data: mentor, isLoading: isLoadingMentor } = useEmployee(newGraduate?.mentorUserId || "");
  const { data: supportMembers, isLoading: isLoadingSupportMembers } = useEmployees(
    newGraduate?.supportMemberEmployeeIds || []
  );

  // 候補者ステータス

  // 社内メモ
  const { updateNewGraduateMemo } = useUpdateNewGraduateMemo();
  const handleUpdateMemo = useCallback(
    async (newMemo: string) => {
      if (!newGraduate) return;
      await updateNewGraduateMemo(newGraduate, newMemo);
    },
    [newGraduate, updateNewGraduateMemo]
  );

  const displayRichMenuSection = useMemo(() => {
    if (newGraduate?.selectedAuthenticationFlowType !== "line") return false;
    // NOTE: ブロックされている場合などでrichMenuDataがnullで返ってくる場合はセクションごと表示しない
    if (!isLoadingRichMenuUrl && richMenuData === null) return false;

    return true;
  }, [isLoadingRichMenuUrl, newGraduate?.selectedAuthenticationFlowType, richMenuData]);

  const renderElementWithLoading = (isLoading: boolean, element: ReactElement) =>
    isLoading ? <Loading size="small" /> : element;

  return (
    <StyledModal
      fullWidth
      fullScreen
      open={open}
      title="詳細情報"
      titleSize="body1"
      content={
        <Box display="flex" flexDirection="column" gridGap="32px">
          <Section label={"担当者"}>
            {renderElementWithLoading(
              isLoadingMentor,
              mentor ? <IconList employees={[mentor]} /> : <Typography>未設定</Typography>
            )}
          </Section>

          <Section label={"サポートメンバー"}>
            {renderElementWithLoading(
              isLoadingSupportMembers,
              supportMembers && supportMembers.length > 0 ? (
                <IconList employees={supportMembers} />
              ) : (
                <Typography>未設定</Typography>
              )
            )}
          </Section>

          <Section label="候補者ステータス">
            {renderElementWithLoading(
              isLoadingEmployee,
              newGraduate ? (
                <NewGraduateStatusChip newGraduate={newGraduate} />
              ) : (
                <Typography>未設定</Typography>
              )
            )}
          </Section>

          <Divider />

          {newGraduate && (
            <NewGraduateMemo
              newGraduateId={newGraduate.id}
              memo={newGraduate.admin_memo ?? ""}
              onUpdateMemo={handleUpdateMemo}
            />
          )}

          {displayRichMenuSection && (
            <NewGraduateRichMenu
              richMenuUrl={richMenuData?.imageUrl}
              isLoading={isLoadingRichMenuUrl}
            />
          )}

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              borderRadius="circle"
              variant="outlined"
              color="default"
              fullWidth
              onClick={() => {
                newGraduate &&
                  window.open(`/employee/${newGraduate.id}`, "_blank", "noopener noreferrer");
              }}
            >
              候補者詳細を見る
            </Button>
          </Box>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    margin: 0;
  }
`;
