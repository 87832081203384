import { Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { OnnEventSlotDate, NewInterviewEvent, BriefingSessionEvent } from "@onn/common";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { SelectMenu } from "../../employees";

import { BriefingSessionCategoryFormPart } from "./components/BriefingSessionCategoryFormPart";
import { OnnEventTypeFormPart } from "./components/OnnEventTypeFormPart";
import { useCreateOrEditOnnEventSlotDateForm } from "./useCreateOrEditOnnEventSlotDateForm";

import {
  Button,
  DatePickerV2,
  Icon,
  Loading,
  Modal,
  SelectForm,
  Tooltip,
  Typography,
} from "~/components/uiParts";
import { useAdmins, useCurrentUser } from "~/hooks/employee";

import { useBriefingSessionCategories } from "~/hooks/onnEvent/useBriefingSessionCategories";
import { useOnnEventPlaces } from "~/hooks/onnEventPlace/useOnnEventPlaces";

type Props = {
  open: boolean;
  onCancel: () => void;
  data: { onnEvent: NewInterviewEvent | BriefingSessionEvent } & (
    | {
        mode: "create";
      }
    | {
        mode: "edit";
        onnEventSlotDate: OnnEventSlotDate;
      }
  );
};

export const CreateOrEditOnnEventSlotDateModal: FC<Props> = ({ open, onCancel, data }) => {
  const { currentUser } = useCurrentUser();

  const mode = data.mode;
  const onnEventSlotDate = data.mode === "edit" ? data.onnEventSlotDate : undefined;
  const { data: admins, isLoading: isLoadingAdmins } = useAdmins(currentUser.tenantId);
  const { data: onnEventPlaces = [], isLoading: isLoadingPlaces } = useOnnEventPlaces();
  const { data: briefingSessionCategories = [], isLoading: isLoadingBriefingSessionCategories } =
    useBriefingSessionCategories({
      onnEventId: data.onnEvent.id,
    });

  const adminsMap = new Map((admins || []).map((employee) => [employee.id, employee]));

  const {
    form,
    handleSubmit,
    isSubmitButtonDisabled,
    fromTimeItemsWithValueTypeString,
    untilTimeItemsWithValueTypeString,
    handleChangeCandidateDateFromTime,
    handleChangeCandidateDateUntilTime,
  } = useCreateOrEditOnnEventSlotDateForm({
    onnEventSlotDate,
    mode,
    onCancel,
    onnEvent: data.onnEvent,
  });

  return (
    <StyledModal
      open={open}
      title={mode === "edit" ? "予約枠編集" : "予約枠追加"}
      onCancel={onCancel}
      content={
        <Box display="flex" flexDirection="column" gridRowGap="32px">
          {data.onnEvent.type === "briefing_session" && (
            <BriefingSessionCategoryFormPart
              form={form}
              isLoading={isLoadingBriefingSessionCategories}
              briefingSessionCategories={briefingSessionCategories}
            />
          )}
          <Box>
            <Box display="flex" gridGap="12px" alignItems="center">
              <Typography variant="body2" bold>
                予約枠
              </Typography>
            </Box>
            <Box mt="12px">
              <Box display="flex" minHeight="40px">
                <Box mr="8px" width="160px">
                  <Controller
                    name="slotDate"
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      // TODO: デザインに揃える
                      <StyledDatePicker
                        fullWidth
                        placeholder="日程を選択"
                        value={value}
                        onChange={(date) => {
                          onChange(date);
                          form.trigger(["slotDate", "slotFromTimeString", "slotUntilString"]);
                        }}
                      />
                    )}
                  />
                </Box>
                <Box mr="8px">
                  <Controller
                    name="slotFromTimeString"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <StyledSelectForm
                        {...field}
                        icon="clock"
                        menuItems={fromTimeItemsWithValueTypeString}
                        selected={field.value || null}
                        onChange={(e) => {
                          handleChangeCandidateDateFromTime(e);
                        }}
                        errorBorder={!!fieldState.error}
                        errorText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
                <Box mr="8px" mt="8px">
                  <Typography color="textSecondary">〜</Typography>
                </Box>
                <Box mr="8px">
                  <Controller
                    name="slotUntilString"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <StyledSelectForm
                        {...field}
                        icon="clock"
                        menuItems={untilTimeItemsWithValueTypeString}
                        selected={field.value || null}
                        onChange={(e) => {
                          handleChangeCandidateDateUntilTime(e);
                        }}
                        errorBorder={!!fieldState.error}
                        errorText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
                <Box mr="16px">
                  <Controller
                    name="capacity"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        freeSolo
                        disableClearable
                        options={[...Array(100)].map((_, i) => String(i + 1))}
                        onChange={(_, value) => {
                          field.onChange(value);
                          form.trigger("capacity");
                        }}
                        value={String(field.value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              form.trigger("capacity");
                            }}
                            label="定員"
                            value={String(field.value)}
                            variant="outlined"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" gridGap="12px" alignItems="center">
              <Typography variant="body2" bold>
                {data.onnEvent.type === "briefing_session" ? "主担当" : "選考担当者"}
              </Typography>
              <Tooltip
                title="Onnの管理者アカウントを持っているメンバーのみ表示されます。"
                placement="top-start"
              >
                <Icon icon="help" size="sm" color="grey" />
              </Tooltip>
            </Box>
            <Box mt="12px" width={"240px"}>
              <Controller
                name="assigneeId"
                control={form.control}
                render={({ field: { onChange, value } }) =>
                  // NOTE: SelectMenuは検索対象のEmployeeが全て出揃っていること前提のコンポーネント
                  isLoadingAdmins ? (
                    <Loading size="small" />
                  ) : (
                    <SelectMenu
                      selectedEmployee={value ? adminsMap.get(value) : undefined}
                      selectEmployee={(employee) => onChange(employee?.id)}
                      employees={admins || []}
                      isMultiple={false}
                    />
                  )
                }
              />
            </Box>
          </Box>
          {data.onnEvent.type === "briefing_session" && (
            <Box>
              <Box display="flex" gridGap="12px" alignItems="center">
                <Typography variant="body2" bold>
                  副担当者
                </Typography>
                <Tooltip
                  title="Onnの管理者アカウントを持っているメンバーのみ表示されます。"
                  placement="top-start"
                >
                  <Icon icon="help" size="sm" color="grey" />
                </Tooltip>
              </Box>
              <Box mt="12px" width={"240px"}>
                <Controller
                  name="subAssigneeIds"
                  control={form.control}
                  render={({ field: { onChange, value } }) =>
                    // NOTE: SelectMenuは検索対象のEmployeeが全て出揃っていること前提のコンポーネント
                    isLoadingAdmins ? (
                      <Loading size="small" />
                    ) : (
                      <SelectMenu
                        selectedEmployees={(admins || []).filter((v) => value.includes(v.id))}
                        selectEmployees={(employees) => onChange(employees.map((v) => v.id))}
                        employees={admins || []}
                        isMultiple={true}
                      />
                    )
                  }
                />
              </Box>
            </Box>
          )}
          <OnnEventTypeFormPart
            form={form}
            eventPlaces={{
              isLoading: isLoadingPlaces,
              data: onnEventPlaces,
            }}
          />
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end">
          <Button variant="text" color="default" borderRadius="regular" onClick={onCancel}>
            キャンセル
          </Button>
          <Box width={16} />
          <Button
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
          >
            {mode === "edit" ? "変更" : "追加"}
          </Button>
        </Box>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
    height: 100%;

    & > .MuiBox-root {
      height: 100%;

      /* content */
      & > .MuiBox-root:nth-child(2) {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }
  }
`;

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 228px;
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px;
    width: 160px;
    padding: 8px 12px 8px 12px;
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 14px;
  }
  .MuiIconButton-root {
    padding: 4px;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    min-width: 100px;
    height: 40px;
  }
`;

const StyledAutocomplete = styled(Autocomplete<string, undefined, true, true>)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6.5px 14px;
    height: 40px;
    width: 100px;
  }
  .MuiFormHelperText-root {
    width: 250px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
  }
`;
