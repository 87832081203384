import { Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Drawer } from "@mui/material";
import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components";

import { RichMenuTab } from "./RichMenuTab/RichMenuTab";

import { Icon, Typography, Divider } from "~/components/uiParts";
import { useDeleteAllRichMenusCache } from "~/hooks/richMenu/useAllRichMenus";

const drawerWidth = "480px";
export const OptionSettingDrawer: FC<{
  isOpen: boolean;
  close: () => void;
  recruitmentStatusId: string | null;
  type: "registrationRichMenu" | "portalRichMenu";
}> = ({ isOpen, close, recruitmentStatusId, type }) => {
  // NOTE: 未登録者用の設定の場合は、リッチメニューしか表示させないように注意する
  const tabs: Array<{ label: string; value: string }> = useMemo(() => {
    return [{ label: "リッチメニュー", value: "richMenu" }];
  }, []);

  const { onClickCloseWithEffect } = useOnCloseWithEffect(close);

  return (
    <StyledDrawer
      variant="persistent"
      anchor="right"
      open={isOpen}
      hideBackdrop
      PaperProps={{
        width: drawerWidth,
      }}
    >
      <Box padding={"20px"} position={"relative"}>
        <Box
          width={"100%"}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography bold variant="h4">
            オプション設定
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: "24px",
            top: "22px",
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={onClickCloseWithEffect}
        >
          <Icon icon="close" size="md" color="grey" />
        </Box>
      </Box>
      <Divider margin={0} />
      <TabContext value={"richMenu"}>
        <StyledTabList indicatorColor="primary" textColor="primary">
          {tabs.map((tab) => (
            <StyledTab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </StyledTabList>
        <StyledTabPanel value="richMenu">
          {isOpen && (
            <RichMenuTab
              recruitmentStatusId={recruitmentStatusId}
              type={type}
              closeDrawer={onClickCloseWithEffect}
            />
          )}
        </StyledTabPanel>
      </TabContext>
    </StyledDrawer>
  );
};

// NOTE: 再度リッチメニュー設定のサイドバーを開いた時に、古い状態でRHFを初期化しないため、キャッシュを削除する
const useOnCloseWithEffect = (close: () => void) => {
  const { deleteAllRichMenusCache } = useDeleteAllRichMenusCache();

  const onClickCloseWithEffect = useCallback(() => {
    deleteAllRichMenusCache();
    close();
  }, [close, deleteAllRichMenusCache]);

  return { onClickCloseWithEffect };
};

const StyledDrawer = styled(Drawer)`
  &.MuiDrawer-root {
    > .MuiPaper-root {
      width: ${drawerWidth};
      height: 100%;
    }
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    width: 100%;
    max-width: 100%;
  }
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 0;
    max-width: 100%;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  height: 100%;
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
