import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { LeftSide } from "./LeftSide/LeftSide";

import { Main } from "./Main/Main";
import { PageContextProvider } from "./pageContext";

export const Page: FC = () => {
  return (
    <PageContextProvider>
      <Box display={"flex"}>
        <Box width={"240px"}>
          <LeftSide />
        </Box>
        <Main />
      </Box>
    </PageContextProvider>
  );
};
