import { v4 } from "uuid";

import { RichMenuCellSetting } from "../RichMenuCellSetting/RichMenuCellSetting";

import { IPortalRichMenu, portalRichMenuSchema } from "./schema";

export class PortalRichMenu extends RichMenuCellSetting implements IPortalRichMenu {
  static readonly validator = portalRichMenuSchema;
  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly externalRichMenuId: string;
  readonly imagePath: string;
  readonly deleted: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(data: ExcludeMethods<PortalRichMenu>) {
    const parsedInit = PortalRichMenu.validator.parse(data);
    super(parsedInit);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.externalRichMenuId = parsedInit.externalRichMenuId;
    this.imagePath = parsedInit.imagePath;
    this.deleted = parsedInit.deleted;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(
    init: Omit<ExcludeMethods<PortalRichMenu>, "id" | "createdAt" | "updatedAt" | "deleted">
  ): PortalRichMenu {
    return new PortalRichMenu({
      ...init,
      id: v4(),
      deleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(
    data: Omit<
      ExcludeMethods<PortalRichMenu>,
      "id" | "tenantId" | "spaceId" | "createdAt" | "updatedAt"
    >
  ): PortalRichMenu {
    return new PortalRichMenu({
      ...this,
      ...data,
      updatedAt: new Date(),
    });
  }

  public logicallyDelete(): PortalRichMenu {
    return new PortalRichMenu({
      ...this,
      deleted: true,
      updatedAt: new Date(),
    });
  }

  public isSameSetting(
    other: ExcludeMethods<RichMenuCellSetting> & Pick<PortalRichMenu, "imagePath">
  ): boolean {
    return (
      this.imagePath === other.imagePath &&
      this.cell1Type === other.cell1Type &&
      this.cell1ExternalSiteUrl === other.cell1ExternalSiteUrl &&
      this.cell1MessageText === other.cell1MessageText &&
      this.cell2Type === other.cell2Type &&
      this.cell2ExternalSiteUrl === other.cell2ExternalSiteUrl &&
      this.cell2MessageText === other.cell2MessageText &&
      this.cell3Type === other.cell3Type &&
      this.cell3ExternalSiteUrl === other.cell3ExternalSiteUrl &&
      this.cell3MessageText === other.cell3MessageText &&
      this.cell4Type === other.cell4Type &&
      this.cell4ExternalSiteUrl === other.cell4ExternalSiteUrl &&
      this.cell4MessageText === other.cell4MessageText &&
      this.cell5Type === other.cell5Type &&
      this.cell5ExternalSiteUrl === other.cell5ExternalSiteUrl &&
      this.cell5MessageText === other.cell5MessageText &&
      this.cell6Type === other.cell6Type &&
      this.cell6ExternalSiteUrl === other.cell6ExternalSiteUrl &&
      this.cell6MessageText === other.cell6MessageText
    );
  }
}
