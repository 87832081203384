import { ContactRoomUnreadCountInfo, AllContactRoom } from "@onn/common";
import React, { FC, createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { KeyedMutator } from "swr";

import { useContactRoomsV2 } from "~/hooks/contactRoom";
import { useContactRoomUnreadCountV2 } from "~/hooks/contactRoomUnreadCount";
import { useCurrentUser } from "~/hooks/employee";
import { UnreadCountInfoRepository } from "~/infrastructure/api/unreadCountInfoRepository";

const unreadCountInfoRepository = new UnreadCountInfoRepository();

export const ContactContextV2 = createContext<{
  /** Map<コンタクトルームID, 未読数> */
  contactRoomUnreadCountMap: Map<string, number>;
  totalUnreadCount: number;
  isLoadingUnreadMessageCountList: boolean;
  contactRooms: AllContactRoom[];
  contactRoomsWithoutCurrentUser: AllContactRoom[];
  isLoadingContactRooms: boolean;
  lastUpdatedAt: Date;
  contactRoomsMutate: KeyedMutator<AllContactRoom[]>;
}>({
  contactRoomUnreadCountMap: new Map(),
  totalUnreadCount: 0,
  isLoadingUnreadMessageCountList: true,
  contactRooms: [],
  contactRoomsWithoutCurrentUser: [],
  isLoadingContactRooms: true,
  lastUpdatedAt: new Date(),
  contactRoomsMutate: async () => [],
});

export const ContactProviderV2: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    isLoadingAllUnreadCountsForEmployee,
    accessibleUnreadCounts = [],

    contactRooms = [],
    isLoadingContactRooms,
    contactRoomsMutate,
    contactRoomsWithoutCurrentUser,
  } = useFetch();
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());
  const { unreadCountMap, totalUnreadCount } = useUnreadCountsForDisplay(
    accessibleUnreadCounts,
    setLastUpdatedAt
  );

  return (
    <ContactContextV2.Provider
      value={{
        contactRoomUnreadCountMap: unreadCountMap,
        isLoadingUnreadMessageCountList: isLoadingAllUnreadCountsForEmployee,
        totalUnreadCount,
        contactRoomsWithoutCurrentUser,
        contactRooms,
        isLoadingContactRooms,
        lastUpdatedAt,
        contactRoomsMutate,
      }}
    >
      {children}
    </ContactContextV2.Provider>
  );
};

const useFetch = () => {
  const { currentUser } = useCurrentUser();

  const { data: allUnreadCountsForEmployee, isLoading: isLoadingAllUnreadCountsForEmployee } =
    useContactRoomUnreadCountV2();

  // NOTE: 暫定対応
  const {
    data: contactRooms,
    isLoading: isLoadingContactRooms,
    mutate: contactRoomsMutate,
  } = useContactRoomsV2({
    tenantId: currentUser.tenantId,
    isIncludeCurrentUserContactRoom: true,
  });

  // 担当者・フォロワーになっているコンタクトルームに紐付く未読情報
  // 担当・フォロワーになっていないコンタクトルームの未読情報もfirestoreで管理しているため
  const accessibleUnreadCounts = useMemo(() => {
    return allUnreadCountsForEmployee
      ? allUnreadCountsForEmployee.filter((v) => {
          return contactRooms
            ? contactRooms.find((contactRoom) => contactRoom.id === v.contactRoomId)
            : undefined;
        })
      : [];
  }, [contactRooms, allUnreadCountsForEmployee]);

  const contactRoomsWithoutCurrentUser = useMemo(
    () => (contactRooms ? contactRooms.filter((v) => v.employeeId !== currentUser.id) : []),
    [contactRooms, currentUser.id]
  );

  return {
    isLoadingAllUnreadCountsForEmployee,
    accessibleUnreadCounts,
    contactRooms,
    isLoadingContactRooms,
    contactRoomsMutate,
    contactRoomsWithoutCurrentUser,
  };
};

const useUnreadCountsForDisplay = (
  init: { contactRoomId: string; unreadCount: number }[],
  onChange: (date: Date) => void
) => {
  const { currentUser } = useCurrentUser();

  const [unreadCountsForDisplay, setUnreadCountsForDisplay] = useState<
    {
      contactRoomId: string;
      unreadCount: number;
    }[]
  >([]);

  useEffect(() => {
    // useState呼び出し時には init は空配列になっているため、こちらで初期化する
    setUnreadCountsForDisplay(init);
  }, [init]);

  const updateCount = (updatedInfo: ContactRoomUnreadCountInfo) => {
    setUnreadCountsForDisplay((counts) =>
      counts.map((v) =>
        v.contactRoomId === updatedInfo.contactRoomId
          ? {
              contactRoomId: updatedInfo.contactRoomId,
              unreadCount: updatedInfo.unreadCount,
            }
          : v
      )
    );
  };

  const totalUnreadCount = useMemo(() => {
    return unreadCountsForDisplay.reduce((acc, crr) => {
      acc += crr.unreadCount;
      return acc;
    }, 0);
  }, [unreadCountsForDisplay]);

  useEffect(() => {
    if (unreadCountsForDisplay.length < 0) return;
    const unsubscribeFunctions = unreadCountInfoRepository.listenUnreadCountInfo({
      contactRoomIds: unreadCountsForDisplay.map((v) => v.contactRoomId),
      employeeId: currentUser.id,
      tenantId: currentUser.tenantId,
      onChangedUnreadCountInfo: (updatedInfo) => {
        updateCount(updatedInfo);
        onChange(new Date());
      },
    });
    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
    // 参照が変わる度に実行すると無駄にlistenが走るので、lengthが変わった時だけ実行する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadCountsForDisplay.length]);

  const unreadCountMap = useMemo(() => {
    return new Map(unreadCountsForDisplay.map((v) => [v.contactRoomId, v.unreadCount]));
  }, [unreadCountsForDisplay]);

  return {
    unreadCountMap,
    totalUnreadCount,
  };
};
