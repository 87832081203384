import { RecruitmentStatus, OnnEvent, EmployeeTag, OnnTask, Employee } from "@onn/common";
import { useCallback } from "react";

export const useGenerateSettingCSV = () => {
  const generateSettingCSV = useCallback(
    ({
      employeeTags,
      onnEvents,
      onnTasks,
      recruitmentStatuses,
      allEmployees,
    }: {
      employeeTags: EmployeeTag[];
      onnEvents: OnnEvent[];
      onnTasks: OnnTask[];
      recruitmentStatuses: RecruitmentStatus[];
      allEmployees: Employee[];
    }) => {
      const baseData = [
        [
          "「選考ステータス」コピー欄",
          "「担当者」コピー欄",
          "「タグ」コピー欄",
          "「イベント」コピー欄",
          "「タスク」コピー欄",
        ],
        [
          "該当する「選考ステータス」は以下候補からコピーして入社者情報記入欄にペーストしてください",
          "該当する「担当者」は以下候補からコピーして入社者情報記入欄にペーストしてください",
          "該当する「タグ」は以下候補からコピーして入社者情報記入欄にペーストしてください",
          "該当する「イベント」は以下候補からコピーして入社者情報記入欄にペーストしてください",
          "該当する「タスク」は以下候補からコピーして入社者情報記入欄にペーストしてください",
        ],
      ];
      const generatedData = [...baseData];

      const normalOnnEvents = onnEvents.filter((event) => event.type === "normal");
      const assignableAssigneeEmails = allEmployees.flatMap((employee) =>
        employee.assignedAsNewcomer ? [] : employee.email
      );
      // lengthは長い方に合わせる
      const length = Math.max(
        recruitmentStatuses.length,
        employeeTags.length,
        normalOnnEvents.length,
        onnTasks.length,
        assignableAssigneeEmails.length
      );

      for (let index = 0; index < length; index++) {
        const employeeTag = employeeTags[index];
        const normalOnnEvent = normalOnnEvents[index];
        const onnTask = onnTasks[index];
        const recruitmentStatus = recruitmentStatuses[index];
        const assignableAssigneeEmail = assignableAssigneeEmails[index];
        generatedData.push([
          recruitmentStatus ? recruitmentStatus.label : "",
          assignableAssigneeEmail || "",
          employeeTag ? employeeTag.name : "",
          normalOnnEvent ? normalOnnEvent.title : "",
          onnTask ? onnTask.title : "",
        ]);
      }

      return generatedData;
    },
    []
  );

  return { generateSettingCSV };
};
