import { Table, TableBody, TableHead } from "@mui/material";
import React, { FC } from "react";

import styled from "styled-components";

import { TableHeaderRow } from "./Header/TableHeaderRow";
import {
  RecruitmentProcessRecordOnnEventItemRow,
  RecruitmentProcessRecordOnnEventItemRowProps,
} from "./body/rows/RecruitmentProcessRecordOnnEventItemRow/RecruitmentProcessRecordOnnEventItemRow";
import {
  RecruitmentProcessRecordOnnTaskItemRow,
  RecruitmentProcessRecordOnnTaskItemRowProps,
} from "./body/rows/RecruitmentProcessRecordOnnTaskItemRow/RecruitmentProcessRecordOnnTaskItemRow";
import { RecruitmentProcessRecordRow } from "./body/rows/RecruitmentProcessRecordRow/RecruitmentProcessRecordRow";

export type TableData = {
  processRecord: {
    recruitmentStatusId: string;
    recruitmentStatusLabel: string;
    progressStatus: string;
    items: (
      | RecruitmentProcessRecordOnnEventItemRowProps
      | RecruitmentProcessRecordOnnTaskItemRowProps
    )[];
    createdAt: Date;
    updatedAt: Date;
  };
}[];

export const RecruitmentProcessTable: FC<{ tableData: TableData }> = ({ tableData }) => {
  return (
    <StyledTable>
      <TableHead>
        <TableHeaderRow />
      </TableHead>
      <TableBody>
        {tableData.map((data) => {
          return (
            <>
              <RecruitmentProcessRecordRow
                key={data.processRecord.recruitmentStatusId}
                recruitmentStatusLabel={data.processRecord.recruitmentStatusLabel}
                progressStatus={data.processRecord.progressStatus}
                updatedAt={data.processRecord.updatedAt}
              />
              {data.processRecord.items.map((item) => {
                switch (item.type) {
                  case "onnEvent": {
                    return (
                      <RecruitmentProcessRecordOnnEventItemRow key={item.onnEvent.id} {...item} />
                    );
                  }
                  case "onnTask": {
                    return (
                      <RecruitmentProcessRecordOnnTaskItemRow key={item.onnTaskId} {...item} />
                    );
                  }
                }
              })}
            </>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &.MuiTable-root {
    width: 100%;
    table-layout: fixed;
  }
  .MuiTableRow-root {
    .MuiTableCell-body,
    .MuiTableCell-head {
      border: 1px solid ${(props) => props.theme.palette.grey[100]};
      border-top: none;
    }
    .MuiTableCell-body:first-child,
    .MuiTableCell-head:first-child {
      border-left: none;
    }
    .MuiTableCell-body:last-child,
    .MuiTableCell-head:last-child {
      border-right: none;
    }
  }
`;
