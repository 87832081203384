import { PortalRichMenu } from "./PortalRichMenu/PortalRichMenu";
import { PortalRichMenuRelation } from "./PortalRichMenuRelation/PortalRichMenuRelation";

export class PortalRichMenuRelationWithRichMenu extends PortalRichMenuRelation {
  portalRichMenu: PortalRichMenu;

  constructor(
    init: ExcludeMethods<PortalRichMenuRelation> & {
      portalRichMenu: ExcludeMethods<PortalRichMenu>;
    }
  ) {
    super(init);
    this.portalRichMenu = new PortalRichMenu(init.portalRichMenu);
  }
}
