import { Box, Tab } from "@material-ui/core";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import { OnnEvent, Employee, NewInterviewEvent, BriefingSessionEvent } from "@onn/common";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { useEventDetail } from "../hooks/useHooks";

import { TabPage as AnswerResultTab } from "./AnswerResultTab/TabPage";
import { TabPage as AnswerResultTabForNewInterviewEvent } from "./AnswerResultTabForNewInterviewEvent/TabPage";
import { EventDetailSummary } from "./EventDetailSummary/EventDetailSummary";
import { EventDetailSummaryPaperForNewInterviewAndBriefingSession } from "./EventDetailSummary/EventDetailSummaryPaperForNewInterviewAndBriefingSession";
import { TabPage as OnnEventContentsTab } from "./OnnEventContentsTab/TabPage";
import { TabPage as OnnEventSlotsTab } from "./OnnEventSlotsTab/TabPage";
import { TabPage as BriefingSessionEventSlotsTab } from "./OnnEventSlotsTabForBriefingSessionEvent/TabPage";

import { EventTypeDisplaySwitcher } from "~/components/domains/events/EventTypeDisplaySwitcher";
import { Typography, Loading } from "~/components/uiParts";

import { useCurrentUser, useEmployees } from "~/hooks/employee";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant/useTenant";
import { NotFound } from "~/pages/NotFound";

export const Page = () => {
  const { tenant } = useTenant();
  const { switchSpaceTemporary } = useCurrentSpace();
  const { currentUser } = useCurrentUser();
  const params = useParams();
  const onnEventId = params.id;

  const { data: onnEvent, isLoading: isLoadingOnnEvent } = useOnnEvent({
    tenantId: tenant.tenantId,
    onnEventId,
  });
  const { data: assigneeEmployees, isLoading: isLoadingEmployee } = useEmployees(
    onnEvent?.assigneeIds
  );

  useEffect(() => {
    if (!onnEvent) return;
    switchSpaceTemporary(onnEvent.spaceId);
  }, [switchSpaceTemporary, onnEvent]);

  const isLoading = isLoadingOnnEvent || isLoadingEmployee;
  if (isLoading) return <Loading size="large" fullHeight />;
  if (!onnEventId || !onnEvent) {
    return <NotFound />;
  }

  return (
    <EventDetail
      currentUser={currentUser}
      onnEvent={onnEvent}
      assigneeEmployees={assigneeEmployees || []}
    />
  );
};

const EventDetail = ({
  currentUser,
  onnEvent,
  assigneeEmployees,
}: {
  currentUser: Employee;
  onnEvent: OnnEvent;
  assigneeEmployees: Employee[];
}) => {
  const { activeTab, handleChangeActiveTab, tabs, onClickBulkRemindButton, onClickAssignButton } =
    useEventDetail({
      onnEvent,
    });

  return (
    <>
      <StyledLink to="/events">
        <Typography variant="body2" color="textSecondary">
          ← イベント一覧
        </Typography>
      </StyledLink>
      <EventTypeDisplaySwitcher
        onnEvent={onnEvent}
        renderForNormal={() => (
          <EventDetailSummary
            currentUser={currentUser}
            onnEvent={onnEvent}
            assigneeEmployees={assigneeEmployees || []}
            onClickAssignButton={onClickAssignButton}
          />
        )}
        renderForNewInterview={(onnEvent) => (
          <EventDetailSummaryPaperForNewInterviewAndBriefingSession
            currentUser={currentUser}
            onnEvent={onnEvent}
          />
        )}
        renderForBriefingSession={(onnEvent) => (
          <EventDetailSummaryPaperForNewInterviewAndBriefingSession
            currentUser={currentUser}
            onnEvent={onnEvent}
          />
        )}
      />
      <Box mt={3}>
        <TabContext value={activeTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeActiveTab}
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <EventTypeDisplaySwitcher
            onnEvent={onnEvent}
            renderForNormal={() => (
              <TabPanelsForNormalEvent
                currentUser={currentUser}
                onnEvent={onnEvent}
                onClickBulkRemindButton={onClickBulkRemindButton}
              />
            )}
            renderForNewInterview={(onnEvent) => (
              <TabPanelsForNewInterview currentUser={currentUser} onnEvent={onnEvent} />
            )}
            renderForBriefingSession={(onnEvent) => (
              <TabPanelsForBriefingInterview currentUser={currentUser} onnEvent={onnEvent} />
            )}
          />
        </TabContext>
      </Box>
    </>
  );
};

const TabPanelsForNormalEvent = ({
  currentUser,
  onnEvent,
  onClickBulkRemindButton,
}: {
  currentUser: Employee;
  onnEvent: OnnEvent;
  onClickBulkRemindButton: (tenantId: string, eventId: string) => void;
}) => {
  return (
    <>
      <StyledTabPanel value="eventContents">
        <OnnEventContentsTab currentUser={currentUser} onnEvent={onnEvent} />
      </StyledTabPanel>
      <StyledTabPanel value="answer">
        <AnswerResultTab
          currentUser={currentUser}
          onnEvent={onnEvent}
          onClickBulkRemindButton={onClickBulkRemindButton}
        />
      </StyledTabPanel>
    </>
  );
};

const TabPanelsForNewInterview = ({
  currentUser,
  onnEvent,
}: {
  currentUser: Employee;
  onnEvent: NewInterviewEvent;
}) => {
  return (
    <>
      <StyledTabPanel value="eventContents">
        <OnnEventContentsTab currentUser={currentUser} onnEvent={onnEvent} />
      </StyledTabPanel>
      <StyledTabPanel value="eventSlots">
        <OnnEventSlotsTab onnEvent={onnEvent} />
      </StyledTabPanel>
      <StyledTabPanel value="interviewSchedule">
        <AnswerResultTabForNewInterviewEvent onnEvent={onnEvent} />
      </StyledTabPanel>
    </>
  );
};

const TabPanelsForBriefingInterview = ({
  currentUser,
  onnEvent,
}: {
  currentUser: Employee;
  onnEvent: BriefingSessionEvent;
}) => {
  return (
    <>
      <StyledTabPanel value="eventContents">
        <OnnEventContentsTab currentUser={currentUser} onnEvent={onnEvent} />
      </StyledTabPanel>
      <StyledTabPanel value="eventSlots">
        <BriefingSessionEventSlotsTab onnEvent={onnEvent} />
      </StyledTabPanel>
    </>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin-bottom: 48px;
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    padding: 0px 20px;
  }
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
