import { Box } from "@material-ui/core";
import { NewInterviewEvent, NormalEvent } from "@onn/common";
import { format } from "date-fns";
import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components";

import { AnswerStatusChip } from "../AnswerStatusChip";

import { Icon, Typography } from "~/components/uiParts";

import theme from "~/config/theme";

type StatusForNormalEvent =
  | "not_notifiable"
  | "rejected"
  | "withdrew"
  | "before_answer_and_read"
  | "before_answer_and_unread"
  | "unregistered_attendance_and_past"
  | "unregistered_attendance_and_not_past"
  | "absent_attendance"
  | "registered_attendance";

type StatusForNewInterviewEvent =
  | "rejected"
  | "withdrew"
  | "before_answer"
  | "unregistered_attendance_and_past"
  | "unregistered_attendance_and_not_past"
  | "absent_attendance"
  | "registered_attendance";
type Props = (
  | {
      type: NormalEvent["type"];
      lastReadAt: Date | null;
      status: StatusForNormalEvent;
    }
  | {
      type: NewInterviewEvent["type"];
      lastReadAt?: undefined;
      status: StatusForNewInterviewEvent;
    }
) & {
  openMenu: MouseEventHandler<HTMLDivElement>;
};

export const AnswerStatusCell: FC<Props> = ({ lastReadAt, status, openMenu }) => {
  switch (status) {
    case "not_notifiable":
      return (
        <CellWrapper bgcolor={theme.palette.grey[50]} p={"1px"}>
          <Box width="100%" display="flex" justifyContent="center">
            <Icon color="grey300" size="xl" icon="ban" />
          </Box>
        </CellWrapper>
      );
    case "rejected":
      return (
        <CellWrapper bgcolor={theme.palette.grey[50]} p={"1px"}>
          <Typography variant="body2" color="textSecondary">
            不採用
          </Typography>
        </CellWrapper>
      );
    case "withdrew":
      return (
        <CellWrapper bgcolor={theme.palette.grey[50]} p={"1px"}>
          <Typography variant="body2" color="textSecondary">
            辞退
          </Typography>
        </CellWrapper>
      );
    case "before_answer_and_read": {
      // before_answer_and_readになる時点でlastReadAtは存在するが型ガードをしている
      if (!lastReadAt) return;
      const label = format(lastReadAt, "yyyy/MM/dd 既読");
      return (
        <CellWrapper p={"1px"}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
        </CellWrapper>
      );
    }
    case "before_answer_and_unread":
      return (
        <CellWrapper bgcolor={theme.palette.info} p={"1px"}>
          <Box width="100%" display="flex" justifyContent="center">
            <Icon color="lightGrey" size="xl" icon="horizontalBar" />
          </Box>
        </CellWrapper>
      );
    case "before_answer": {
      return (
        <CellWrapper bgcolor={theme.palette.grey[50]} p={"1px"}>
          <Typography variant="body2" color="textSecondary">
            ー
          </Typography>
        </CellWrapper>
      );
    }
    case "unregistered_attendance_and_past":
      return (
        <CellWrapper bgcolor={theme.palette.secondary.light} p={"1px"}>
          <AnswerStatusChip status={status} openMenu={openMenu} />
        </CellWrapper>
      );

    case "unregistered_attendance_and_not_past":
      return (
        <CellWrapper bgcolor={theme.palette.blue.light} p={"1px"}>
          <AnswerStatusChip status={status} openMenu={openMenu} />
        </CellWrapper>
      );

    case "absent_attendance":
      return (
        <CellWrapper bgcolor={theme.palette.grey[50]} p={"1px"}>
          <AnswerStatusChip status={status} openMenu={openMenu} />
        </CellWrapper>
      );
    case "registered_attendance":
      return (
        <CellWrapper bgcolor={theme.palette.primary.light} p={"1px"}>
          <AnswerStatusChip status={status} openMenu={openMenu} />
        </CellWrapper>
      );
    default: {
      const _: never = status;
      return <></>;
    }
  }
};

const CellWrapper = styled(Box)<{ bgcolor?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px 1px 0px 0px;
`;
