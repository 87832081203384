import {
  CandidateDate,
  OnnEventDeterminedDate,
  OnnEventAnswer,
  OnnEvent,
  APISchema,
} from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

export const useOnnEventRelatedEmployee = ({
  id,
  employeeId,
}: {
  id?: string;
  employeeId?: string;
}): SWRResponse<Response, Error> => {
  const asyncJob = async ({ id, employeeId }: QueryParam): Promise<Response> => {
    const queryParam: QueryParam = {
      id,
      employeeId,
    };
    const {
      data: { onnEvent, onnEventAnswer, onnEventDeterminedDate },
    } = await apiClient.get(`/api/onn-events/id`, queryParam);
    return {
      onnEvent: new OnnEvent({
        ...onnEvent,
        candidateDates: onnEvent.candidateDates.map((date) => new CandidateDate(date)),
      }),
      onnEventAnswer: onnEventAnswer ? new OnnEventAnswer(onnEventAnswer) : null,
      onnEventDeterminedDate: onnEventDeterminedDate
        ? new OnnEventDeterminedDate(onnEventDeterminedDate)
        : null,
    };
  };

  return useSWR(id && employeeId ? generateKeyObj({ id, employeeId }) : null, asyncJob);
};

const generateKeyObj = (param: QueryParam) => {
  return {
    endpoint: `/api/onn-events/id`,
    ...param,
  } as const;
};

type QueryParam = APISchema["/api/onn-events/id"]["GET"]["query"];
export type Response = {
  onnEvent: OnnEvent;
  onnEventAnswer: OnnEventAnswer | null;
  onnEventDeterminedDate: OnnEventDeterminedDate | null;
};
