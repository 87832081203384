import { isPast } from "date-fns";
import { sortBy } from "lodash";

import { NewGraduateToDisplay } from "../../Employee";
import { OnnEventDeterminedDate } from "../OnnEventDeterminedDate";
import { AttendanceStatus } from "../OnnEventDeterminedDate/schema";
import { OnnEventSlotDate } from "../OnnEventSlotDate";

import { OnnEventAnswer } from "./OnnEventAnswer";

export type OnnEventAnswerStatus =
  | "withdrew"
  | "rejected"
  | "before_answer"
  | "unregistered_attendance_and_past"
  | "unregistered_attendance_and_not_past"
  | "absent_attendance"
  | "registered_attendance";

// NOTE: 説明会・選考イベント用
export class OnnEventAnswerForDisplay extends OnnEventAnswer {
  selectedOnnEventSlotDate?: OnnEventSlotDate;
  onnEventDeterminedDate?: OnnEventDeterminedDate;
  newGraduateToDisplay: NewGraduateToDisplay;
  assigneeInfo?: {
    id: string;
    name: string;
    iconUrl?: string;
  };
  constructor(init: ExcludeMethods<OnnEventAnswerForDisplay>) {
    super(init);
    this.selectedOnnEventSlotDate = init.selectedOnnEventSlotDate;
    this.onnEventDeterminedDate = init.onnEventDeterminedDate;
    this.assigneeInfo = init.assigneeInfo;
    this.newGraduateToDisplay = init.newGraduateToDisplay;
  }

  public getStatusForDisplay(): OnnEventAnswerStatus {
    if (this.newGraduateToDisplay.recruitmentStatusType === "withdrew") return "withdrew";
    if (this.newGraduateToDisplay.recruitmentStatusType === "rejected") return "rejected";
    if (!this.onnEventDeterminedDate || !this.selectedOnnEventSlotDate) return "before_answer";
    if (this.onnEventDeterminedDate.attendanceStatus === "ATTENDED") return "registered_attendance";
    if (this.onnEventDeterminedDate.attendanceStatus === "ABSENT") return "absent_attendance";
    return isPast(this.selectedOnnEventSlotDate.from)
      ? "unregistered_attendance_and_past"
      : "unregistered_attendance_and_not_past";
  }

  /**
   * 回答済みかどうかを返す
   * - 辞退・不採用の場合も回答済みの場合は、trueを返すので注意
   */
  public isAlreadyAnswered(): boolean {
    return this.getStatusForDisplay() !== "before_answer";
  }

  static getLabelFromStatus(status: OnnEventAnswerStatus) {
    switch (status) {
      case "withdrew":
        return "辞退";
      case "rejected":
        return "不採用";
      case "before_answer":
        return "-";
      case "unregistered_attendance_and_past":
        return "参加未登録";
      case "unregistered_attendance_and_not_past":
        return "回答済み";
      case "absent_attendance":
        return "不参加";
      case "registered_attendance":
        return "参加済み";
      default: {
        const _exhaustiveCheck: never = status;
        return _exhaustiveCheck;
      }
    }
  }

  /**
   * 第一ソート：アクティブ➝非アクティブ（辞退・不採用）
   * 第二ソート：開催日時順（未設定は最後尾）
   * 第三ソート：配信対象追加順
   */
  static sortForDisplay(
    onnEventAnswersForDisplay: OnnEventAnswerForDisplay[]
  ): OnnEventAnswerForDisplay[] {
    const sortByRejectedOrWithdrew = (onnEventAnswerForDisplay: OnnEventAnswerForDisplay) => {
      return onnEventAnswerForDisplay.newGraduateToDisplay.isRejectedOrWithdrew();
    };
    const sortBySelectedOnnEventSlotDateFromDate = (
      onnEventAnswerForDisplay: OnnEventAnswerForDisplay
    ) => {
      // 未設定は最後尾にするためInfinityを返す
      if (!onnEventAnswerForDisplay.selectedOnnEventSlotDate) return Infinity;
      return onnEventAnswerForDisplay.selectedOnnEventSlotDate.from;
    };
    const sortByCreatedAtDate = (onnEventAnswerForDisplay: OnnEventAnswerForDisplay) => {
      return onnEventAnswerForDisplay.createdAt;
    };

    return sortBy(onnEventAnswersForDisplay, [
      sortByRejectedOrWithdrew,
      sortBySelectedOnnEventSlotDateFromDate,
      sortByCreatedAtDate,
    ]);
  }

  /**
   * OnnEventDeterminedDate の AttendanceStatus を AnswerStatus に変換する
   */
  static convertDeterminedDateAttendanceStatusToAnswerStatus(
    status: AttendanceStatus
  ): Extract<
    ReturnType<OnnEventAnswerForDisplay["getStatusForDisplay"]>,
    "registered_attendance" | "absent_attendance" | "unregistered_attendance_and_past"
  > {
    switch (status) {
      case "ABSENT": {
        return "absent_attendance";
      }
      case "ATTENDED": {
        return "registered_attendance";
      }
      case "UNREGISTERED": {
        return "unregistered_attendance_and_past";
      }
      default: {
        const _exhaustiveCheck: never = status;
        return _exhaustiveCheck;
      }
    }
  }
}
