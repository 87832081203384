import { Role } from "@onn/common";
import React, { FC } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import { portalRoutes } from "./portalRoutes";

import { AdminLayout, DefaultLayout, AdminSettingLayout } from "~/components/layouts";
import { useCurrentUser } from "~/hooks/employee";
import { useScrollTopByDetectTransition, useSnackbar } from "~/hooks/shared";
import { NotFound } from "~/pages/NotFound";
import { Page as CreateAnnouncement } from "~/pages/announcement/create/components/page";
import { Page as AnnouncementDeliverySetting } from "~/pages/announcement/deliverySetting/components/page";
import { Page as AdminAnnouncementDetail } from "~/pages/announcement/detail/components/page";
import { Page as EditAnnouncement } from "~/pages/announcement/edit/components/page";
import { ContactMessages } from "~/pages/contactMessages";
import { CancelContactMessagesOrder } from "~/pages/contactMessages/CancelContactMessagesOrder";
import { EditContactMessagesOrder } from "~/pages/contactMessagesOrder/edit";
import { NewGraduateEmployeeDetail } from "~/pages/employee/NewGraduate";
import { EmployeeTagsDetailPage } from "~/pages/employee_tag/components";
import { Page as CreateEvent } from "~/pages/events/create/page";
import { Page as DeliverySettingIndex } from "~/pages/events/deliverySetting/page";
import { Page as EventDetailIndex } from "~/pages/events/detail/components";
import { Page as EditEvent } from "~/pages/events/edit/components/page";
import { Page as EventsIndex } from "~/pages/events/list/page";
import { Page as OnnEventSettingsIndex } from "~/pages/events/settings/page";
import { Page as SlotDefaultValueSettingIndex } from "~/pages/events/slotDefaultValueSetting/page";
import { LibraryEdit } from "~/pages/library/edit";
import { Page as CreateOnnTaskIndex } from "~/pages/onn_task/create";
import { Page as OnnTaskDeliverySettingIndex } from "~/pages/onn_task/deliverySetting";
import { Page as OnnTaskDetail } from "~/pages/onn_task/detail";
import { Page as EditOnnTaskIndex } from "~/pages/onn_task/edit";
import { TasksForNewGraduate } from "~/pages/onn_task/list";
import { Page as Scenario } from "~/pages/scenario/page";
import { SettingsAccount } from "~/pages/settings/account";
import { SettingsAdmin } from "~/pages/settings/admin";
import { ToolIndex } from "~/pages/tool";
import { NewGraduateTopIndex } from "~/pages/top/NewGraduate";
import { getDecodedLiffState } from "~/util";

// 入社者以外の特定のロールを持つユーザーがアクセスできるルート
const SpecificRolesRoute = ({ roles, component: Component }: { roles: Role[]; component: FC }) => {
  useScrollTopByDetectTransition();
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const liffState = getDecodedLiffState();
  if (liffState) {
    return <></>;
  }

  if (currentUser.isNewcomer()) {
    return <Navigate to="/portal" />;
  }

  if (!roles.includes(currentUser.role)) {
    enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
    return <Navigate to="/" />;
  }

  return <Component />;
};

export const RouterWrapper: FC = () => {
  return useRoutes([
    {
      path: "/",
      element: (
        <AdminLayout isCentralChildren>
          <Outlet />
        </AdminLayout>
      ),
      children: [
        {
          index: true,
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={NewGraduateTopIndex} />
          ),
        },
        {
          path: "/employee/:id",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={NewGraduateEmployeeDetail}
            />
          ),
        },
        {
          path: "/tools",
          element: <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={ToolIndex} />,
        },
        {
          path: "/onn_tasks",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={TasksForNewGraduate} />
          ),
        },
        {
          path: "/onn_tasks/new",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={CreateOnnTaskIndex} />
          ),
        },
        {
          path: "/onn_tasks/:id/edit",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={EditOnnTaskIndex} />
          ),
        },
        {
          path: "/onn_tasks/:id",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={OnnTaskDetail} />
          ),
        },
        {
          path: "/onn_tasks/:id/delivery_setting",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={OnnTaskDeliverySettingIndex}
            />
          ),
        },
        {
          path: "/events",
          element: <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={EventsIndex} />,
        },
        {
          path: "/events/new",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={() => SpecificRolesRoute({ roles: [Role.ADMIN], component: CreateEvent })}
            />
          ),
        },
        {
          path: "/events/:id/delivery_setting",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={DeliverySettingIndex}
            />
          ),
        },
        {
          path: "/events/:id/slot_default_value_setting",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={SlotDefaultValueSettingIndex}
            />
          ),
        },
        {
          path: "/events/:id/edit",
          element: <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={EditEvent} />,
        },
        {
          path: "/events/:id",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={EventDetailIndex} />
          ),
        },
        {
          path: "/events/settings",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={OnnEventSettingsIndex}
            />
          ),
        },
        {
          path: "/announcements/new",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={CreateAnnouncement} />
          ),
        },
        {
          path: "/announcements/:id/edit",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={EditAnnouncement} />
          ),
        },
        {
          path: "/announcements/:id",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={AdminAnnouncementDetail}
            />
          ),
        },
        {
          path: "/announcements/:id/delivery_setting",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={AnnouncementDeliverySetting}
            />
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      ),
      children: [
        {
          path: "/contact_rooms",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={ContactMessages} />
          ),
        },
        {
          path: "/contact_rooms/contact_messages_orders/new",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={EditContactMessagesOrder} />,
        },
        {
          path: "/contact_rooms/contact_messages_orders/:id/cancel",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN]} component={CancelContactMessagesOrder} />
          ),
        },
        {
          path: "/libraries/new",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={LibraryEdit} />,
        },
        {
          path: "/libraries/:id/edit",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={LibraryEdit} />,
        },
        {
          path: "/scenarios/:id",
          element: <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={Scenario} />,
        },
      ],
    },
    {
      path: "/settings",
      element: (
        <AdminSettingLayout>
          <Outlet />
        </AdminSettingLayout>
      ),
      children: [
        {
          path: "/settings/account",
          element: (
            <SpecificRolesRoute roles={[Role.ADMIN, Role.MEMBER]} component={SettingsAccount} />
          ),
        },
        {
          path: "/settings/admin",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={SettingsAdmin} />,
        },
        {
          path: "/settings/employee_tags/:id",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.MEMBER]}
              component={EmployeeTagsDetailPage}
            />
          ),
        },
      ],
    },
    ...portalRoutes,
    {
      path: "/",
      element: (
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
};
