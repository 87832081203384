import { Box } from "@material-ui/core";

import React, { FC } from "react";
import styled from "styled-components";

import {
  Typography,
  TooltipWhenTextTruncated,
  UncontrolledMenu,
  IconButton,
} from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  onClickRichMenuSetting: () => void;
};
// NOTE: 一旦「共通」シナリオのみが表示されるようにしている
// 複数シナリオが作成できるようになるときに作り直す
export const ScenarioListTable: FC<Props> = ({ onClickRichMenuSetting }) => {
  const widthOptions = ["90%", "10%"];
  const { currentUser } = useCurrentUser();
  return (
    <VirtualizedTableV2
      rowHeight={90}
      widthOptions={widthOptions}
      headers={[
        {
          text: "タイトル",
        },
        {
          text: "",
        },
      ]}
      rows={["共通"]}
      rowRenderer={({ key, index, style }) => {
        const contents = [<TitleCell key={key} />];
        if (currentUser.isAdmin()) {
          contents.push(
            <ScenarioManageMenu key={key} onClickRichMenuSetting={onClickRichMenuSetting} />
          );
        }
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/scenarios/common`,
                isTargetBlank: false,
              }}
              widthOptions={widthOptions}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

const TitleCell = () => {
  return (
    <Box>
      <Box>
        <TooltipWhenTextTruncated text={"共通"}>
          {(ref) => (
            <StyledTitle ref={ref} variant="body1" bold>
              共通
            </StyledTitle>
          )}
        </TooltipWhenTextTruncated>
      </Box>
    </Box>
  );
};

const ScenarioManageMenu: FC<{
  onClickRichMenuSetting: () => void;
}> = ({ onClickRichMenuSetting }) => {
  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={[
        {
          onClick: onClickRichMenuSetting,
          text: "未登録者用リッチメニュー設定",
        },
      ]}
    />
  );
};

const StyledTitle = styled(Typography)`
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
