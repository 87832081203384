import { Box } from "@material-ui/core";
import {
  EmployeeTag,
  OnnEvent,
  OnnEventEvaluationRank,
  OnnTask,
  RecruitmentStatus,
  RecruitmentStatusWithRelatedInfo,
} from "@onn/common";
import React, { createContext, useCallback, useContext, useMemo } from "react";

import { Loading } from "~/components/uiParts";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useAllOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventEvaluationRanks } from "~/hooks/onnEventEvaluationRank/useOnnEventEvaluationRanks";
import { useOnnTasks } from "~/hooks/onnTask";
import { useRecruitmentStatuses } from "~/hooks/recruitmentStatus";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type ContextType = {
  allOnnEvents: OnnEvent[];
  allOnnTasks: OnnTask[];
  availableOnnEvents: OnnEvent[];
  availableOnnTasks: OnnTask[];
  availableRecruitmentStatuses: RecruitmentStatus[];
  allRecruitmentStatuses: RecruitmentStatus[];
  allTags: EmployeeTag[];
  allTagsMap: Map<string, EmployeeTag>;
  allOnnEventEvaluationRanks: OnnEventEvaluationRank[];
  allOnnEventsMap: Map<string, OnnEvent>;
  allOnnTasksMap: Map<string, OnnTask>;
  allRecruitmentStatusesMap: Map<string, RecruitmentStatusWithRelatedInfo>;
  allOnnEventEvaluationRanksMap: Map<string, OnnEventEvaluationRank>;
};

/**
 * シナリオ編集画面でのみ使用するコンテキスト
 */
export const EditModeContext = createContext<ContextType | undefined>(undefined);

export const useEditModeContext = () => {
  const c = useContext(EditModeContext);
  if (!c) throw new Error("useCtx must be inside a EditModeContextProvider with a value");
  return c;
};

export const EditModeContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { currentSpace } = useCurrentSpace();

  const { data: allOnnEvents = [], isLoading: isLoadingEvent } = useAllOnnEvent();
  const { extractAvailableOnnEvents } = useExtractAvailableOnnEvents();
  const availableOnnEvents = extractAvailableOnnEvents(allOnnEvents);
  const allOnnEventsMap = useMemo(
    () => new Map(allOnnEvents.map((event) => [event.id, event])),
    [allOnnEvents]
  );

  const { data: allOnnTasks = [], isLoading: isLoadingTask } = useOnnTasks({
    tenantId: currentSpace.id,
  });
  const { extractAvailableOnnTasks } = useExtractAvailableOnnTasks();
  const availableOnnTasks = extractAvailableOnnTasks(allOnnTasks);
  const allOnnTasksMap = useMemo(
    () => new Map(allOnnTasks.map((task) => [task.id, task])),
    [allOnnTasks]
  );

  const { allRecruitmentStatuses, isLoading: isLoadingRecruitmentStatuses } =
    useAllRecruitmentStatuses();
  const { extractAvailableRecruitmentStatuses } = useExtractAvailableRecruitmentStatuses();
  const availableRecruitmentStatuses = extractAvailableRecruitmentStatuses(allRecruitmentStatuses);
  const allRecruitmentStatusesMap = useMemo(
    () => new Map(allRecruitmentStatuses.map((status) => [status.id, status])),
    [allRecruitmentStatuses]
  );

  const { allTags, isLoading: isLoadingTags } = useAllTags();
  const allTagsMap = useMemo(() => new Map(allTags.map((tag) => [tag.id, tag])), [allTags]);

  const { allOnnEventEvaluationRanks, isLoading: isLoadingOnnEventEvaluationRanks } =
    useAllOnnEventEvaluation();
  const allOnnEventEvaluationRanksMap = useMemo(() => {
    return new Map(allOnnEventEvaluationRanks.map((rank) => [rank.id, rank]));
  }, [allOnnEventEvaluationRanks]);

  const isLoading =
    isLoadingEvent ||
    isLoadingTask ||
    isLoadingRecruitmentStatuses ||
    isLoadingTags ||
    isLoadingOnnEventEvaluationRanks;
  return (
    <EditModeContext.Provider
      value={{
        allOnnEvents,
        allOnnTasks,
        availableOnnEvents,
        availableOnnTasks,
        availableRecruitmentStatuses,
        allRecruitmentStatuses,
        allTags,
        allOnnEventEvaluationRanks,
        allOnnEventsMap,
        allOnnTasksMap,
        allRecruitmentStatusesMap,
        allOnnEventEvaluationRanksMap,
        allTagsMap,
      }}
    >
      {isLoading ? (
        <Box height={"50vh"}>
          <Loading size="large" />
        </Box>
      ) : (
        children
      )}
    </EditModeContext.Provider>
  );
};

const useExtractAvailableOnnEvents = () => {
  const extractAvailableOnnEvents = useCallback((allOnnEvents: OnnEvent[]) => {
    const availableOnnEvents = allOnnEvents.filter((onnEvent) => {
      if (onnEvent.isNormalEvent()) {
        // NOTE: 通常イベントは配信可能の場合のみ表示
        return onnEvent.canAnswer();
      }
      return true;
    });
    return availableOnnEvents;
  }, []);

  return { extractAvailableOnnEvents };
};

const useExtractAvailableOnnTasks = () => {
  const extractAvailableOnnTasks = useCallback((allOnnTasks: OnnTask[]) => {
    return allOnnTasks.filter((onnTask) => onnTask.canAnswer());
  }, []);

  return { extractAvailableOnnTasks };
};

const useAllRecruitmentStatuses = () => {
  const { data: allRecruitmentStatuses = [], isLoading } = useRecruitmentStatuses({
    withDeleted: true,
  });
  // NOTE: 複数シナリオに対応したあとは、同一シナリオ内でのみ使用可能なステータスのみ表示する
  return { allRecruitmentStatuses, isLoading };
};

const useExtractAvailableRecruitmentStatuses = () => {
  const extractAvailableRecruitmentStatuses = useCallback(
    (recruitmentStatuses: RecruitmentStatusWithRelatedInfo[]) => {
      return recruitmentStatuses.filter((recruitmentStatus) => !recruitmentStatus.deleted);
    },
    []
  );

  return { extractAvailableRecruitmentStatuses };
};

const useAllTags = () => {
  const { data, isLoading } = useEmployeeTags();
  const allTags = data?.employeeTags || [];
  return { allTags, isLoading };
};

const useAllOnnEventEvaluation = () => {
  const { data: allOnnEventEvaluationRanks = [], isLoading } = useOnnEventEvaluationRanks();
  return { allOnnEventEvaluationRanks, isLoading };
};
