import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { ScenarioEditLockRepository } from "~/infrastructure/api/scenarioEditLockRepository";

const generateKey = ({ tenantId, spaceId }: { tenantId: string; spaceId: string }) => {
  return {
    path: "uncompletedScenarioEditLocks",
    tenantId,
    spaceId,
  } as const;
};

const scenarioEditLockRepository = new ScenarioEditLockRepository();

export const useUncompletedScenarioEditLocks = () => {
  const { currentSpace } = useCurrentSpace();

  return useSWR(
    generateKey({
      tenantId: currentSpace.tenantId,
      spaceId: currentSpace.id,
    }),
    async ({ tenantId, spaceId }) => {
      const scenarioEditLock = await scenarioEditLockRepository.findByIsCompleted({
        tenantId,
        spaceId,
        isCompleted: false,
      });
      return scenarioEditLock;
    }
  );
};

export const useMutateUncompletedScenarioEditLocks = () => {
  const mutateUncompletedScenarioEditLocks = useCallback(
    (args: { tenantId: string; spaceId: string }) => {
      return mutate(generateKey(args));
    },
    []
  );

  return { mutateUncompletedScenarioEditLocks };
};
