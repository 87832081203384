import { Employee } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { apiClient } from "~/libs";

const getKey = ({ tenantId }: { tenantId: string }) => {
  return {
    endpoint: "/api/employee/acceptance-employees",
    tenantId,
  } as const;
};

export const useAllAssignees = (): SWRResponse<Employee[], Error> => {
  const { currentUser } = useCurrentUser();

  return useSWR(getKey({ tenantId: currentUser.tenantId }), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);

    const assignees = response.data.map((v) => {
      return Employee.plainToInstance(v);
    });

    return assignees;
  });
};
