import { Box } from "@mui/material";
import React, { FC } from "react";
import { FormProvider, useFormContext } from "react-hook-form";

import { AsyncSetting } from "./AsyncSetting/AsyncSetting";
import { CellLinkSetting } from "./CellLinkSetting/CellLinkSetting";
import { ImageSetting } from "./ImageSetting/ImageSetting";
import { RichMenuTabContextProvider, useRichMenuTabContext } from "./RichMenuTabContext";
import { useSubmit } from "./hooks/useSubmit";
import { InputState, useForm } from "./useForm";

import { Button } from "~/components/uiParts";

const DrawerLabelAreaHeight = "66px";
const TabListAreaHeight = "50px";

type Props = {
  recruitmentStatusId: string | null;
  type: "registrationRichMenu" | "portalRichMenu";
  closeDrawer: () => void;
};
export const RichMenuTab: FC<Props> = ({ recruitmentStatusId, type, closeDrawer }) => {
  return (
    <RichMenuTabContextProvider recruitmentStatusId={recruitmentStatusId} type={type}>
      <RichMenuTabCore
        recruitmentStatusId={recruitmentStatusId}
        type={type}
        closeDrawer={closeDrawer}
      />
    </RichMenuTabContextProvider>
  );
};
export const RichMenuTabCore: FC<Props> = ({ recruitmentStatusId, closeDrawer }) => {
  const { form } = useForm();
  const isSetRichMenu = form.watch("isSetRichMenu") === "true";
  const isPortalRichMenu = recruitmentStatusId !== null;
  const isShowCellLinkSetting = isSetRichMenu;
  const isShowAsyncSetting = isSetRichMenu && isPortalRichMenu;

  return (
    <FormProvider {...form}>
      <Box
        padding={"24px"}
        display="flex"
        flexDirection={"column"}
        rowGap="32px"
        sx={{
          height: `calc(100vh - ${DrawerLabelAreaHeight} - ${TabListAreaHeight})`,
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
          flexGrow={1}
          display="flex"
          flexDirection={"column"}
          rowGap="32px"
        >
          <ImageSetting />
          {isShowCellLinkSetting && <CellLinkSetting isPortalRichMenu={isPortalRichMenu} />}
          {isShowAsyncSetting && <AsyncSetting />}
        </Box>
        <Box>
          <ButtonToSave closeDrawer={closeDrawer} />
        </Box>
      </Box>
    </FormProvider>
  );
};

const ButtonToSave: FC<{ closeDrawer: () => void }> = ({ closeDrawer }) => {
  const form = useFormContext<InputState>();
  const isError = Object.keys(form.formState.errors).length > 0;
  const { isUploadingRichMenuImage } = useRichMenuTabContext();
  const isDisabled =
    isUploadingRichMenuImage || isError || form.formState.isSubmitting || !form.formState.isDirty;
  const isLoading = isUploadingRichMenuImage || form.formState.isSubmitting;

  const { submit } = useSubmit({ closeDrawer });

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      borderRadius="regular"
      isLoading={isLoading}
      disabled={isDisabled}
      onClick={submit}
    >
      保存して反映
    </Button>
  );
};
