import { OnnEvent } from "@onn/common";
import { BriefingSessionEvent } from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { FC, useEffect } from "react";

import { useParams } from "react-router-dom";

import { CreateOrEditBriefingSessionEvent } from "../../_shared/CreateOrEditBriefingSessionEvent/page";

import { EventEditor } from "./EventEditor";

import { EventTypeDisplaySwitcher } from "~/components/domains/events/EventTypeDisplaySwitcher";
import { Loading } from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useOnnEvent, useCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent";

import { useBriefingSessionCategories } from "~/hooks/onnEvent/useBriefingSessionCategories";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { NotFound } from "~/pages/NotFound";

export const Page: FC = () => {
  const { tenant } = useTenant();
  const { switchSpaceTemporary } = useCurrentSpace();
  const params = useParams();
  const onnEventId = params.id;
  const { data: currentOnnEvent, isLoading } = useOnnEvent({
    tenantId: tenant.tenantId,
    onnEventId: onnEventId,
  });

  useEffect(() => {
    if (!currentOnnEvent) return;
    switchSpaceTemporary(currentOnnEvent.spaceId);
  }, [currentOnnEvent, switchSpaceTemporary]);

  if (isLoading) return <Loading size="large" fullHeight />;

  if (!onnEventId || !currentOnnEvent) {
    return <NotFound />;
  }
  return (
    <EventTypeDisplaySwitcher
      onnEvent={currentOnnEvent}
      renderForNormal={(onnEvent) => <EditPageForNormalOrInterview onnEvent={onnEvent} />}
      renderForNewInterview={(onnEvent) => <EditPageForNormalOrInterview onnEvent={onnEvent} />}
      renderForBriefingSession={(onnEvent) => (
        <EditPageForBriefingSessionEvent onnEvent={onnEvent} />
      )}
    />
  );
};

/**
 * 通常イベント・面談イベントの編集画面
 */
const EditPageForNormalOrInterview: FC<{ onnEvent: OnnEvent }> = ({ onnEvent }) => {
  const { currentUser } = useCurrentUser();

  const { data: candidateDatesWithNumberOfParticipants, isLoading: isLoadingCandidateDate } =
    useCandidateDatesWithNumberOfParticipants({
      currentEmployeeId: currentUser.id,
      onnEventId: onnEvent.id,
    });

  if (isLoadingCandidateDate) return <Loading size="large" fullHeight />;

  return (
    <EventEditor
      currentOnnEvent={onnEvent}
      candidateDatesWithNumberOfParticipants={candidateDatesWithNumberOfParticipants || []}
    />
  );
};

/**
 * 説明会イベントの編集画面
 */
const EditPageForBriefingSessionEvent: FC<{ onnEvent: BriefingSessionEvent }> = ({ onnEvent }) => {
  const { data: briefingSessionCategories = [], isLoading } = useBriefingSessionCategories({
    onnEventId: onnEvent.id,
  });

  if (isLoading) return <Loading size="large" fullHeight />;
  return (
    <CreateOrEditBriefingSessionEvent
      mode="edit"
      onnEvent={onnEvent}
      briefingSessionCategories={briefingSessionCategories}
    />
  );
};
