import { ContactRoomPin } from "@onn/common";
import React, { FC } from "react";

import { useHandleOnClickPin } from "./useHandleOnClickPin";

import { IconButton, UncontrolledMenu } from "~/components/uiParts";

type Props = {
  selectedContactRoomId: string;
  contactRoomPin?: ContactRoomPin;
  openManageContactTeamModal?: () => void;
};

export const DetailMenu: FC<Props> = ({
  openManageContactTeamModal,
  selectedContactRoomId,
  contactRoomPin,
}) => {
  const { isPinned, handleOnClickPin, handleOnClickUnpin } = useHandleOnClickPin({
    selectedContactRoomId,
    contactRoomPin,
  });

  type MenuItemOption = React.ComponentProps<typeof UncontrolledMenu>["menuItemOptions"][number];
  const menuItemOptionsWithUndefined: (MenuItemOption | undefined)[] = [
    openManageContactTeamModal && {
      text: "対応チーム設定",
      onClick: openManageContactTeamModal,
    },
    isPinned
      ? {
          text: "ピン解除",
          onClick: handleOnClickUnpin,
        }
      : {
          text: "ピン留め",
          onClick: handleOnClickPin,
        },
  ];
  const menuItemOptions = menuItemOptionsWithUndefined.filter(
    (item): item is MenuItemOption => item !== undefined
  );

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={menuItemOptions}
    />
  );
};
