import { z } from "zod";

export const scenarioEditLockSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  isCompleted: z.boolean(),
  operationEmployeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface

export type IScenarioEditLockSchema = z.infer<typeof scenarioEditLockSchema>;
