import { PortalRichMenuRelationWithRichMenu, RegistrationRichMenu } from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

export const generateAllRichMenusKeyObj = (spaceId: string) => {
  return {
    endpoint: `/rich_menu_api/rich-menus`,
    spaceId,
  } as const;
};

export const useAllRichMenus = () => {
  const { currentSpace } = useCurrentSpace();
  return useSWR(generateAllRichMenusKeyObj(currentSpace.id), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);
    return {
      portalRichMenusRelationWithRichMenu: response.data.portalRichMenuRelationWithRichMenu.map(
        (relation) =>
          new PortalRichMenuRelationWithRichMenu(
            Object.assign(relation, { richMenu: relation.portalRichMenu })
          )
      ),
      registrationRichMenu: response.data.registrationRichMenu
        ? new RegistrationRichMenu(response.data.registrationRichMenu)
        : null,
    };
  });
};

export const useDeleteAllRichMenusCache = () => {
  const { currentSpace } = useCurrentSpace();
  const { cache } = useSWRConfig();
  const deleteAllRichMenusCache = useCallback(() => {
    cache.delete(JSON.stringify(generateAllRichMenusKeyObj(currentSpace.id)));
  }, [cache, currentSpace.id]);

  return { deleteAllRichMenusCache };
};
