import { Box } from "@material-ui/core";
import {
  generateSampleCsvDataForCreateNewGraduates,
  EmployeeTag,
  OnnEvent,
  OnnTask,
  RecruitmentStatus,
  Employee,
} from "@onn/common";
import React, { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent } from "../../_share/DownloadSampleFileStepContent";

import { UploadFileStepContent } from "../../_share/UploadFileStepContent";

import { useGenerateSettingCSV } from "./useGenerateSampleCSV";

import { useHandleClickUploadButton } from "./useHandleClickUploadButton";
import { useHandleInputFile } from "./useHandleInputFile";

import { Modal, VerticalStepper } from "~/components/uiParts";
import { usePrompt } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type Props = {
  open: boolean;
  onCancel: () => void;
  inviteType: "newGraduate";
  employeeTags: EmployeeTag[];
  onnEvents: OnnEvent[];
  onnTasks: OnnTask[];
  recruitmentStatuses: RecruitmentStatus[];
  allEmployees: Employee[];
  onCreateNewGraduateWithCsv?: () => void | Promise<void>;
};

export const InviteNewGraduateWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onnEvents,
  onCreateNewGraduateWithCsv,
  ...props
}) => {
  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const { errorMessages, handleInputFile, isCheckingCsv, loadedFileName, inputFile } =
    useHandleInputFile();

  const { handleClickUploadButton, isUploading } = useHandleClickUploadButton({
    inputFile,
    closeModal: onCancel,
    onCreateNewGraduateWithCsv,
  });

  const fileLabel = useMemo(() => {
    if (loadedFileName) return loadedFileName;
    if (isCheckingCsv) return "ファイルをチェックしています…";
    return "ファイルが選択されていません";
  }, [loadedFileName, isCheckingCsv]);

  usePrompt("候補者の招待が正常に完了しない場合があります", isUploading);
  const isUploadButtonDisabled = isCheckingCsv || isUploading || errorMessages.length > 0;
  const isUploadButtonLoading = isCheckingCsv || isUploading;

  const { generateSettingCSV } = useGenerateSettingCSV();
  const sampleCsv = useMemo(
    () => generateSampleCsvDataForCreateNewGraduates(currentSpace.name),
    [currentSpace.name]
  );
  const settingCsv = useMemo(
    () =>
      generateSettingCSV({
        employeeTags: props.employeeTags,
        onnEvents: onnEvents,
        onnTasks: props.onnTasks,
        recruitmentStatuses: props.recruitmentStatuses,
        allEmployees: props.allEmployees,
      }),
    [
      generateSettingCSV,
      props.employeeTags,
      props.onnTasks,
      props.recruitmentStatuses,
      props.allEmployees,
      onnEvents,
    ]
  );

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            sampleFile={{
              csvData: sampleCsv,
              filename: "候補者一括登録_アップロード用サンプルファイル",
            }}
            settingFile={{
              csvData: settingCsv,
              filename: "候補者一括登録_設定可能な項目一覧",
            }}
          />
        );
      case 2:
        return (
          <UploadFileStepContent
            errorMessage={errorMessages.join("\n")}
            fileLabel={fileLabel}
            onCancel={onCancel}
            handleOnClickUpload={handleClickUploadButton}
            isUploadButtonDisabled={isUploadButtonDisabled}
            isUploadButtonLoading={isUploadButtonLoading}
            filePickerProps={{
              onChange: handleInputFile,
              accepts: ["csv"],
              multiple: false,
            }}
          />
        );
    }
  }, [
    activeStep,
    sampleCsv,
    settingCsv,
    errorMessages,
    fileLabel,
    handleInputFile,
    handleClickUploadButton,
    isUploadButtonDisabled,
    isUploadButtonLoading,
    onCancel,
    handleNext,
  ]);

  return (
    <Modal
      open={open}
      title={
        isShowSpaceOnScreen(spaces) ? `候補者一括登録｜${currentSpace.name}` : "候補者一括登録"
      }
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
