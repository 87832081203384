import { useMemo } from "react";

import { useAllNewcomers } from "~/hooks/employee";

import { useDeterminedDate } from "~/hooks/onnEvent";

/**
 * イベントの回答者として選択可能な新卒候補者を取得する
 */
export const useSelectableNewGraduatesForAnswerEvent = ({ onnEventId }: { onnEventId: string }) => {
  const { data: allNewComers, isLoading } = useAllNewcomers();

  const { data: determinedDates } = useDeterminedDate({
    onnEventId,
  });

  const selectableNewGraduates = useMemo(() => {
    const alreadyAnsweredEmployeeIds = determinedDates?.map((v) => v.employeeId) || [];
    if (!allNewComers) return [];
    return allNewComers.filter((newGraduate) => {
      if (newGraduate.isNotRegisteredAndInvited()) return false;

      if (newGraduate.isRejectedOrWithdrew()) return false;
      if (alreadyAnsweredEmployeeIds.includes(newGraduate.id)) return false;

      return true;
    });
  }, [allNewComers, determinedDates]);

  return { selectableNewGraduates, isLoading };
};
