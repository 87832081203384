import { OnnEvent } from "@onn/common";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import { OnnEventCondition } from "../../../../types/condition";
import { Choice } from "../../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { CommonProps } from "../type";

import {
  CANDIDATE_DATE_ID_VALUE_SEPARATOR,
  SLOT_ID_VALUE_SEPARATOR,
} from "./SlotOrCandidateDateSelector";

import { AbsenceStatusChoices } from "./useChoices";

type Props = CommonProps<OnnEventCondition>;

export const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
  allOnnEvents,
  selectedOnnEvent,
}: Pick<Props, "index" | "onChangeCondition" | "condition"> & {
  allOnnEvents: OnnEvent[];
  selectedOnnEvent?: OnnEvent;
}) => {
  const onChangeEvent = useCallback(
    (newChoice: Choice<string>) => {
      // NOTE: イベントを変更した場合はeventId以外をリセットする
      const onnEvent = allOnnEvents.find((e) => e.id === newChoice.value);
      if (!onnEvent) return;
      onChangeCondition(index, {
        target: "onnEvent",
        eventId: newChoice.value,
        type: onnEvent.type,
      });
    },
    [allOnnEvents, index, onChangeCondition]
  );

  const onChangeAbsenceStatuses = useCallback(
    (newChoices: Choice<AbsenceStatusChoices>[]) => {
      if (!selectedOnnEvent) {
        return;
      }
      onChangeCondition(index, {
        ...condition,
        attendanceStatuses: isEmpty(newChoices) ? undefined : newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition, selectedOnnEvent]
  );

  const onChangeCandidateDates = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        candidateDateIds: isEmpty(newChoices)
          ? undefined
          : newChoices.flatMap((c) => c.value.split(CANDIDATE_DATE_ID_VALUE_SEPARATOR)),
      });
    },
    [condition, index, onChangeCondition]
  );

  const onChangeSlotIds = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        slotIds: isEmpty(newChoices)
          ? undefined
          : newChoices.flatMap((c) => c.value.split(SLOT_ID_VALUE_SEPARATOR)),
      });
    },
    [condition, index, onChangeCondition]
  );

  const onChangeEventFormatTypes = useCallback(
    (newChoices: Choice<"offline" | "online">[]) => {
      onChangeCondition(index, {
        ...condition,
        eventFormatTypes: isEmpty(newChoices) ? undefined : newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangeEvent,
    onChangeCandidateDates,
    onChangeSlotIds,
    onChangeAbsenceStatuses,
    onChangeEventFormatTypes,
  };
};
