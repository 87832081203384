import { useCallback, useState } from "react";

import {
  AnyInputCondition,
  AnyValidCondition,
} from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";
import { useSearchNewGraduateIds } from "~/hooks/employee";
import { useSearchNewGraduatesPerPage } from "~/hooks/employee/useSearchNewGraduatesPerPage";

export const useNewGraduateTable = () => {
  const [conditions, setConditions] = useState<AnyInputCondition[]>([{ target: undefined }]);
  const [validConditions, setValidConditions] = useState<AnyValidCondition[]>([]);
  const [logicType, setLogicType] = useState<LogicType>("AND");
  const [validConditionsCount, setValidConditionsCount] = useState(0);
  const [keywordsString, setKeywordsString] = useState("");

  const { data: newGraduatesSearchIds, mutate: mutateSearchNewGraduateIds } =
    useSearchNewGraduateIds({
      type: logicType,
      conditions: validConditions,
      keywordsString,
    });

  const {
    data: searchNewGraduatesPerPageData,
    loadNextPage,
    mutate: mutateAllPagesOfSearchNewGraduatesPerPageData,
    mutateWithOptimisticUpdate,
  } = useSearchNewGraduatesPerPage({
    conditions: validConditions,
    logicType,
    keywordsString,
    onFetch: () => {
      mutateSearchNewGraduateIds();
    },
  });

  const onResetSearchConditions = useCallback(() => {
    setConditions([{ target: undefined }]);
    setValidConditions([]);
    setValidConditionsCount(0);
    setLogicType("AND");
  }, []);

  const onSearchConfirm = useCallback(
    async (logicType: LogicType, conditions: AnyValidCondition[]) => {
      setConditions(conditions.length ? conditions : [{ target: undefined }]);
      setValidConditions(conditions.length ? conditions : []);
      setValidConditionsCount(conditions.length);
      setLogicType(logicType);

      if (conditions.length === 0) {
        onResetSearchConditions();
      }
    },
    [onResetSearchConditions]
  );

  const onChangeKeywordsString = useCallback((keywordsString: string) => {
    setKeywordsString(keywordsString);
  }, []);

  return {
    conditions,
    logicType,
    keywordsString,
    searchNewGraduatesPerPageData,
    searchResultAllIds: newGraduatesSearchIds,
    searchResultCount: newGraduatesSearchIds?.size,
    validConditionsCount,
    onSearchConfirm,
    onResetSearchConditions,
    onChangeKeywordsString,
    onLoadMore: () => {
      loadNextPage();
    },
    mutateAllPagesOfSearchNewGraduatesPerPage: {
      mutate: mutateAllPagesOfSearchNewGraduatesPerPageData,
      mutateWithOptimisticUpdate,
    },
  };
};

export type MutateSearchNewGraduatesPerPageFunctions = {
  mutateAllPagesOfSearchNewGraduatesPerPage: {
    mutate: ReturnType<typeof useSearchNewGraduatesPerPage>["mutate"];
    mutateWithOptimisticUpdate: ReturnType<
      typeof useSearchNewGraduatesPerPage
    >["mutateWithOptimisticUpdate"];
  };
};
