import { OnnFormTask, OnnFormTaskAnswer } from "@onn/common";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { PageForFormTaskCore } from "./components/PageForFormTaskCore";

import { PageForFormTaskWhenPreview } from "./components/PageForFormTaskWhenPreview";

import { Loading } from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";
import { useOnnTask, useOnnTaskFormAnswerDraft } from "~/hooks/onnTask";
import { useFormRevisions } from "~/hooks/onnTask/useFormRevisions";
import { useQuery } from "~/hooks/shared";
import { NotFound } from "~/pages/NotFound";

export const Page: FC = () => {
  const { query } = useQuery();

  const params = useParams<"id">();
  const onnTaskId = params.id;
  const isPreview = query.get("preview") === "true";

  const { data: onnTask, isLoading: isLoadingOnnTask } = useOnnTask({
    onnTaskId: isPreview ? undefined : onnTaskId,
  });

  const { data: onnFormTaskAnswers, isLoading: isLoadingOnnFormTaskAnswers } =
    useOnnFormTasksAnswersOnlyCountable({ onnTaskId });

  if (isPreview) return <PageForFormTaskWhenPreview />;

  if (isLoadingOnnTask || isLoadingOnnFormTaskAnswers) return <Loading size="large" fullHeight />;
  if (!onnTask || !onnFormTaskAnswers) {
    return <NotFound />;
  }

  if (onnTask.type === "FORM_TASK") {
    return (
      <PageForFormTask
        onnFormTask={onnTask}
        onnFormTaskAnswer={onnFormTaskAnswers[0] as (typeof onnFormTaskAnswers)[number]}
      />
    );
  } else {
    return <>TODOタスク: 未実装</>;
  }
};

const PageForFormTask: FC<{
  onnFormTask: OnnFormTask;
  onnFormTaskAnswer: OnnFormTaskAnswer;
}> = ({ onnFormTask, onnFormTaskAnswer }) => {
  const { data: formRevisionsData, isLoading } = useFormRevisions({
    onnFormTaskId: onnFormTask.id,
    isOnlyLatest: true,
  });

  const { currentUser } = useCurrentUser();
  const {
    data: draft,
    isLoading: draftIsLoading,
    isValidating: draftIsValidating,
  } = useOnnTaskFormAnswerDraft({
    tenantId: currentUser.tenantId,
    employeeId: currentUser.id,
    onnTaskFormAnswerId: onnFormTask.id,
  });

  if (isLoading) return <Loading size="large" fullHeight />;
  if (!formRevisionsData) {
    return <NotFound />;
  }
  if (formRevisionsData?.formRevisions.length === 0) {
    return <NotFound />;
  }
  if (!draft || draftIsLoading || draftIsValidating) {
    // 常に再取得された値を使うために、再検証中はローディング中にする
    // 再検証中にキャッシュを返してしまった場合、キャッシュされたものでDBのデータが上書きされてしまう
    return <Loading size="large" fullHeight />;
  }
  const formRevision = formRevisionsData.formRevisions[
    formRevisionsData.formRevisions.length - 1
  ] as (typeof formRevisionsData.formRevisions)[number];

  return (
    <PageForFormTaskCore
      onnFormTask={onnFormTask}
      questions={formRevision.questions}
      formRevision={formRevision}
      onnFormTaskAnswer={onnFormTaskAnswer}
      sampleFileSignedUrlMapObj={formRevisionsData.meta.sampleFileSignedUrlMapObj}
      draft={draft}
    />
  );
};
