import { Box } from "@material-ui/core";
import { ContactRoom, Employee, EmployeeTag } from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components";

import { NewGraduateDetailLink } from "./NewGraduateDetailLink";
import { NewGraduateMemo } from "./NewGraduateMemo";
import { NewGraduateRichMenu } from "./NewGraduateRichMenu";
import { NewGraduateTags } from "./NewGraduateTags";

import { NewGraduateReminder } from "~/components/domains/contactRooms/NewGraduateReminder/NewGraduateReminder";

import { Divider } from "~/components/uiParts";
import { useUpdateNewGraduateMemo } from "~/hooks/employee";
import { useRichMenuIdByLineUserId } from "~/hooks/richMenu/useRichMenuIdByLineUserId";

// ====================
// props
// ====================

type Props = {
  newHire: Employee;
  contactRoom: ContactRoom;
  tags: EmployeeTag[];
};

// ====================
// main
// ====================

/**
 * コンタクト詳細の右サイドペインとして表示する (初期は新卒候補者のみ表示)
 */
export const NewGraduateSummarySidebar: FC<Props> = ({ newHire, contactRoom, tags }) => {
  const { updateNewGraduateMemo } = useUpdateNewGraduateMemo();
  const { data: richMenuData, isLoading: isLoadingRichMenuUrl } = useRichMenuIdByLineUserId({
    lineUserId: newHire.selectedAuthenticationFlowType === "line" ? newHire.lineUserId : undefined,
  });

  // ====================
  // event handler
  // ====================

  const handleUpdateMemo = useCallback(
    async (newMemo: string) => {
      await updateNewGraduateMemo(newHire, newMemo);
    },
    [newHire, updateNewGraduateMemo]
  );

  const displayRichMenuSection = useMemo(() => {
    if (newHire.selectedAuthenticationFlowType !== "line") return false;
    // NOTE: ブロックされている場合などでrichMenuDataがnullで返ってくる場合はセクションごと表示しない
    if (!isLoadingRichMenuUrl && richMenuData === null) return false;

    return true;
  }, [isLoadingRichMenuUrl, newHire.selectedAuthenticationFlowType, richMenuData]);

  // ====================
  // component
  // ====================

  return (
    <>
      <StyledBox display="flex" flexDirection="column" width="360px" bgcolor="#fff" height="100%">
        <StyledContentBox p="24px" flex={1}>
          <Box mb="30px">
            <NewGraduateReminder contactRoomId={contactRoom.id} />
          </Box>

          <NewGraduateMemo
            newGraduateId={newHire.id}
            memo={newHire.admin_memo || ""}
            onUpdateMemo={handleUpdateMemo}
          />

          <Box my="32px" />

          <Divider />

          <Box my="32px" />

          <NewGraduateTags tags={tags} />

          {displayRichMenuSection && (
            <>
              <Box my="32px" />

              <NewGraduateRichMenu
                richMenuUrl={richMenuData?.imageUrl}
                isLoading={isLoadingRichMenuUrl}
              />
            </>
          )}

          <Box my="32px" />
        </StyledContentBox>

        <NewGraduateDetailLink newHireId={newHire.id} />
      </StyledBox>
    </>
  );
};

// ====================
// style
// ====================

const StyledBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[10]};
`;

const StyledContentBox = styled(Box)`
  overflow-y: auto;
`;
