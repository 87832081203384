import { ISpaceSetting, spaceSettingSchema } from "./schema";

export class SpaceSetting implements ISpaceSetting {
  static validator = spaceSettingSchema;
  id: string;
  spaceId: string;
  tenantId: string;
  registrationMessageForPreEntry?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<SpaceSetting>) {
    this.id = init.id;
    this.spaceId = init.spaceId;
    this.tenantId = init.tenantId;
    this.registrationMessageForPreEntry = init.registrationMessageForPreEntry;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static create(
    init: Pick<
      ExcludeMethods<SpaceSetting>,
      "spaceId" | "tenantId" | "registrationMessageForPreEntry"
    >,
    id?: string
  ) {
    const createdAt = new Date();
    const updatedAt = new Date();
    const parsedInit = SpaceSetting.validator.parse({ ...init, id, createdAt, updatedAt });

    return new SpaceSetting(parsedInit);
  }
}
