import { RecruitmentStatus, RecruitmentStatusWithRelatedInfo } from "@onn/common";

import { useCallback } from "react";

import { InputState } from "./InputState";
import { useFormContext } from "./useScenarioForm";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";
import { usePageContext } from "~/pages/scenario/pageContext";

/**
 * 左側の選考ステータスのリスト表示のwarningのステータスを更新する
 */
export const useUpdateWarningRecruitmentStatuses = () => {
  const { getValues } = useFormContext();
  const { recruitmentStatusMap } = useRecruitmentStatusList();
  const { setWarningRecruitmentStatuses } = usePageContext();

  const updateWarningRecruitmentStatuses = useCallback(() => {
    const currentFormValue = getValues();
    const warningRecruitmentStatuses: RecruitmentStatus[] = currentFormValue.body.flatMap(
      ({ blocks, recruitmentStatusId }) => {
        return extractWarningRecruitmentStatuses({
          blocks,
          recruitmentStatusId,
          recruitmentStatusMap,
        });
      }
    );

    setWarningRecruitmentStatuses(warningRecruitmentStatuses);
  }, [getValues, recruitmentStatusMap, setWarningRecruitmentStatuses]);

  return { updateWarningRecruitmentStatuses };
};

const extractWarningRecruitmentStatuses = ({
  blocks,
  recruitmentStatusId,
  recruitmentStatusMap,
}: {
  blocks: InputState["body"][number]["blocks"];
  recruitmentStatusId: string;
  recruitmentStatusMap: Map<string, RecruitmentStatusWithRelatedInfo>;
}) => {
  const recruitmentStatus = recruitmentStatusMap.get(recruitmentStatusId);
  if (recruitmentStatus === undefined) {
    return [];
  }

  const warningRecruitmentStatuses: RecruitmentStatus[] = [];

  blocks.forEach((block) => {
    const { actions } = block;
    actions.forEach(({ actionSetting }) => {
      switch (actionSetting.type) {
        case "ChangeRecruitmentStatusActionSetting": {
          const actionTargetRecruitmentStatus = recruitmentStatusMap.get(
            actionSetting.recruitmentStatusId
          );
          if (actionTargetRecruitmentStatus === undefined) {
            warningRecruitmentStatuses.push(recruitmentStatus);
          }
          break;
        }
        case "DeliverOnnEventActionSetting":
        case "DeliverOnnTaskActionSetting":
        case "SendContactMessageActionSetting": {
          return;
        }
        default: {
          const _exhaustiveCheck: never = actionSetting;
          return _exhaustiveCheck;
        }
      }
    });
  });

  return warningRecruitmentStatuses;
};
