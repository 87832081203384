import React, { FC, useState, ComponentProps, useMemo } from "react";
import styled from "styled-components";

import { CustomVariant, Tooltip, Typography } from "~/components/uiParts";
import { UserIcon, IconSize, ICON_SIZE } from "~/components/uiParts/UserIcon";

// semiSmall, small以外はまだデザインが無いので仮の値を設定
const EXTRA_USERS_TEXT_VARIANT: { [key in IconSize]: CustomVariant } = {
  extraSmall: "overline",
  semiExtraSmall: "caption",
  semiSmall: "caption",
  small: "body1",
  medium: "h3",
  large: "h1",
};

type UserInfo = {
  profileIconImageUrl?: string;
  username: string;
  borderColor?: ComponentProps<typeof UserIcon>["borderColor"];
};

type Props = {
  usersInfo: UserInfo[];
  /**
   * undefinedの場合、親要素の幅によって表示するアイコン数を動的に計算します
   */
  max?: number;
  /**
   * trueの場合、hoverしたときusernameを表示します
   */
  tooltip?: boolean;
  onClick?: () => void;
  className?: string;
  iconSize: IconSize;
};

export const UserIconGroup: FC<Props> = ({
  usersInfo,
  max,
  tooltip = false,
  onClick,
  className,
  iconSize,
}) => {
  const [width, setWidth] = useState<number>(0);

  const firstIconWidth = ICON_SIZE[iconSize];
  const afterTheFirstWidth = firstIconWidth * 0.8; // 2番目以降のアイコンや+nの部分
  const [iconUsersInfo, extraUsersInfo] = useMemo(() => {
    /**
     * 幅に収まる、アイコンと+nの部分の最大個数を計算
     * +1 はfirstIconの分
     * 0 で小数点以下を切り捨てる
     * maxの最小値は2
     * maxが指定されていても幅に収まる個数を優先する
     */
    const calcMaxCount = Math.max(((width - firstIconWidth) / afterTheFirstWidth + 1) | 0, 2);
    const maxCount = !max || max > calcMaxCount ? calcMaxCount : max;

    if (usersInfo.length > maxCount) {
      return [usersInfo.slice(0, maxCount - 1), usersInfo.slice(maxCount - 1)];
    }

    return [usersInfo, []];
  }, [usersInfo, width, max, firstIconWidth, afterTheFirstWidth]);

  const extraUsersNameLabel = useMemo(
    () => extraUsersInfo.map((userInfo) => userInfo.username).join(" / "),
    [extraUsersInfo]
  );

  return (
    <StyledContainer
      className={className}
      ref={(node: HTMLDivElement) => (node ? setWidth(node.offsetWidth) : void 0)}
      $margin={firstIconWidth - afterTheFirstWidth}
    >
      <StyledClickArea onClick={onClick} $pointer={!!onClick}>
        {iconUsersInfo.map((user, index) => (
          <StyledUserIconWrapper
            key={`${user.username}_${index}`}
            $zIndex={iconUsersInfo.length - index}
          >
            <UserIcon
              circular
              username={user.username}
              profileIconImageUrl={user.profileIconImageUrl}
              borderColor={user.borderColor}
              size={iconSize}
              hover={tooltip}
            />
          </StyledUserIconWrapper>
        ))}
        {extraUsersInfo.length > 0 && (
          <Tooltip title={extraUsersNameLabel} disableHoverListener={!tooltip} arrow>
            <StyledTypography
              variant={EXTRA_USERS_TEXT_VARIANT[iconSize]}
              color="textPrimary"
              bold
              $width={afterTheFirstWidth}
            >
              +{extraUsersInfo.length}
            </StyledTypography>
          </Tooltip>
        )}
      </StyledClickArea>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $margin: number }>`
  width: 100%;

  /* 先頭以外にネガティブマージンをあててかぶせる */
  & div:not(:first-child) {
    margin-left: -${({ $margin }) => $margin}px;
  }
`;
const StyledClickArea = styled.div<{ $pointer: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  ${({ $pointer }) => $pointer && "cursor: pointer;"}
`;
const StyledUserIconWrapper = styled.div<{ $zIndex: number }>`
  /* 左側のアイコンが上に重なるように */
  z-index: ${({ $zIndex }) => $zIndex};
`;
const StyledTypography = styled(Typography)<{ $width: number }>`
  width: ${({ $width }) => $width}px;
  height: 100%;
  text-align: center;
`;
