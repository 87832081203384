import { Box, Grid } from "@material-ui/core";
import { prefectures } from "@onn/common";
import React, { useCallback } from "react";

import { PrefectureCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import {
  Choice,
  SelectMultipleConditionDropdown,
} from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { CommonProps } from "./type";

const TARGET = "prefecture";
const PREFECTURES = prefectures.map((e) => ({ label: e, value: e }));
const ADDRESS_TYPES = [
  { label: "自宅", value: "address" },
  { label: "帰省先", value: "hometownAddress" },
];

type Props = CommonProps<PrefectureCondition>;
export const PrefectureConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { onChangePrefectures, onChangeAddressType } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
  });

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target) => onChangeTarget(index, target)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectMultipleConditionDropdown
            placeHolder="検索対象を選択"
            key={"address_type"}
            selectedChoices={ADDRESS_TYPES.filter((c) => condition.types?.includes(c.value))}
            choices={ADDRESS_TYPES}
            onChange={onChangeAddressType}
          />
        </Grid>
        <Grid item xs={5}>
          <SelectMultipleConditionDropdown
            placeHolder="都道府県を選択"
            key={"prefectures"}
            selectedChoices={PREFECTURES.filter((c) => condition.prefectures?.includes(c.value))}
            choices={PREFECTURES}
            withSearch
            searchPlaceholder="都道府県で検索"
            onChange={onChangePrefectures}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
}: Pick<Props, "index" | "onChangeCondition" | "condition">) => {
  const onChangePrefectures = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        prefectures: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  const onChangeAddressType = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        types: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangePrefectures,
    onChangeAddressType,
  };
};
