import { useCallback } from "react";

import { useAllNewcomers } from "~/hooks/employee";

export const useGenerateSettingSampleCSV = () => {
  const { data: allNewComers = [], isLoading } = useAllNewcomers();

  const generateSettingSampleCSV = useCallback(() => {
    return [
      ["「Onn固有ID」コピー欄", "姓", "名", "メールアドレス"],
      ...allNewComers.map((v) => {
        return [v.uniqueId, v.lastName, v.firstName, v.email];
      }),
    ];
  }, [allNewComers]);

  return { generateSettingSampleCSV, isLoading };
};
