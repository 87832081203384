import { NewGraduateToDisplay } from "@onn/common";
import React, { FC, useMemo } from "react";

import { NewGraduateUseIcon } from "~/components/domains/employees/NewGraduateUseIcon";
import { UserIconWithLabel } from "~/components/uiParts";

type Props = {
  iconPath?: string;
  userName: string;
  to?: string;
  newGraduate?: NewGraduateToDisplay;
  isPinned?: boolean;
};

export const NewGraduateIconWithLabel: FC<Props> = React.memo(
  ({ iconPath, userName, to, newGraduate, isPinned }) => {
    const userIconWithLabel = useMemo(() => {
      if (newGraduate && !newGraduate.deleted)
        return <NewGraduateUseIcon newGraduate={newGraduate} isPinned={isPinned} />;
      return <UserIconWithLabel iconPath={iconPath} name={userName} to={to} isPinned={isPinned} />;
    }, [newGraduate, iconPath, userName, to, isPinned]);

    return userIconWithLabel;
  }
);
