import { v4 } from "uuid";

import { Announcement } from "../../domain";

export const createIMockAnnouncement = (mock: Partial<Announcement> = {}): Announcement => {
  return Announcement.create({
    id: v4(),
    creatorId: "creatorId",
    title: "title",
    content: "content",
    filePaths: [],
    tenantId: "tenantId",
    spaceId: "spaceId",
    ...mock,
  });
};
