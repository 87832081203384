import { Box, Grid } from "@material-ui/core";
import React, { FC, memo } from "react";

import { NewGraduateTab } from "./parts/NewGraduateTab";
import { SearchResultCountTooltip } from "./parts/NewGraduateTab/SearchResultCountTooltip";
import { useNewGraduateTable } from "./useNewGraduateTable";
import { useViewModel } from "./useViewModel";

import { Loading, Typography } from "~/components/uiParts";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const NewGraduateTopIndex: FC = memo(() => {
  // Note: Space切り替え時にページが持っているstateをリセットするため、Space名をkeyに置く
  // See: https://ja.react.dev/learn/preserving-and-resetting-state#option-2-resetting-state-with-a-key
  const { currentSpace } = useCurrentSpace();

  const {
    searchNewGraduatesPerPageData,
    searchResultAllIds,
    keywordsString,
    conditions,
    logicType,
    validConditionsCount,
    searchResultCount,
    onSearchConfirm,
    onResetSearchConditions,
    onChangeKeywordsString,
    onLoadMore,
    mutateAllPagesOfSearchNewGraduatesPerPage,
  } = useNewGraduateTable();

  if (
    searchNewGraduatesPerPageData == null ||
    searchResultCount == null ||
    searchResultAllIds == null
  ) {
    return <Loading size="large" fullHeight />;
  }

  return (
    <div key={currentSpace.id}>
      <NewGraduateTopIndexCore
        searchNewGraduatesPerPageData={searchNewGraduatesPerPageData}
        searchResultAllIds={searchResultAllIds}
        keywordsString={keywordsString}
        onChangeKeywordsString={onChangeKeywordsString}
        conditions={conditions}
        logicType={logicType}
        validConditionsCount={validConditionsCount}
        searchResultCount={searchResultCount}
        onSearchConfirm={onSearchConfirm}
        onResetSearchConditions={onResetSearchConditions}
        onLoadMore={onLoadMore}
        mutateAllPagesOfSearchNewGraduatesPerPage={mutateAllPagesOfSearchNewGraduatesPerPage}
      />
    </div>
  );
});

const NewGraduateTopIndexCore = memo(
  ({
    searchNewGraduatesPerPageData,
    searchResultAllIds,
    keywordsString,
    onChangeKeywordsString,
    conditions,
    logicType,
    validConditionsCount,
    searchResultCount,
    onSearchConfirm,
    onResetSearchConditions,
    onLoadMore,
    mutateAllPagesOfSearchNewGraduatesPerPage,
  }: ReturnType<typeof useNewGraduateTable> & {
    searchNewGraduatesPerPageData: NonNullable<
      ReturnType<typeof useNewGraduateTable>["searchNewGraduatesPerPageData"]
    >;
    searchResultCount: NonNullable<ReturnType<typeof useNewGraduateTable>["searchResultCount"]>;
    searchResultAllIds: NonNullable<ReturnType<typeof useNewGraduateTable>["searchResultAllIds"]>;
  }) => {
    const {
      onResetSelectedNewGraduateIds,
      onSelectNewGraduateId,
      selectedNewGraduateIds,
      toggleSelectAll,
      allSelectionState,
      onConfirmAddTags,
    } = useViewModel({
      searchNewGraduatesPerPageData,
      searchResultAllIds,
      mutateAllPagesOfSearchNewGraduatesPerPage,
    });

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box display="inline-flex" pr={2}>
              <Typography variant="h4" bold display="inline">
                候補者
              </Typography>
              <Box width="8px" />
              <SearchResultCountTooltip totalCount={searchResultCount} />
            </Box>
          </Grid>
        </Grid>
        <NewGraduateTab
          searchResultCount={searchResultCount}
          searchNewGraduatesPerPageData={searchNewGraduatesPerPageData}
          mutateAllPagesOfSearchNewGraduatesPerPage={mutateAllPagesOfSearchNewGraduatesPerPage}
          searchValue={keywordsString}
          setSearchValue={onChangeKeywordsString}
          conditions={conditions}
          logicType={logicType}
          validConditionsCount={validConditionsCount}
          onSearchConfirm={onSearchConfirm}
          onResetSearchConditions={onResetSearchConditions}
          onResetSelectedNewGraduateIds={onResetSelectedNewGraduateIds}
          onSelectNewGraduateId={onSelectNewGraduateId}
          selectedNewGraduateIds={selectedNewGraduateIds}
          toggleSelectAll={toggleSelectAll}
          allSelectionState={allSelectionState}
          onConfirmAddTags={onConfirmAddTags}
          onLoadMore={onLoadMore}
        />
      </>
    );
  }
);
