import { Box } from "@mui/material";
import { contactMessagePlaceHolderToKey } from "@onn/common";
import React, { useSyncExternalStore, FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useMenu } from "../../../components/useMenu";

import { ModalOfContactMessageTextSetting } from "../Modal/ModalOfContactMessageTextSetting";

import { ContactMessageTemplateMenu } from "~/components/domains/contactRooms/ContactRoomItem/parts";
import { IconButton, TextareaAutosize, Typography } from "~/components/uiParts";
import theme from "~/config/theme";

// NOTE: {}を囲んだ文字列をハイライトとする
const patterns = Object.keys(contactMessagePlaceHolderToKey).map((v) => `{${v}}`);

type Props = {
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
};

export const TextareaOfContactMessageText: FC<Props> = ({ value, onChange, errorMessage }) => {
  const [isOpenModalOfContactMessageTextSetting, setIsOpenModalOfContactMessageTextSetting] =
    useState(false);
  const contactMessageTemplatesMenuHandling = useMenu();
  const textareaAreaRef = useRef<HTMLTextAreaElement>(null);
  const highlightRef = useRef<HTMLDivElement>(null);
  const [isFocusedTextArea, setIsFocusedTextArea] = useState(false);

  const store = useRef({ textareaHeight: 0 });
  const textareaHeight = useSyncExternalStore(
    (callback) => {
      const textarea = textareaAreaRef.current;
      if (!textarea) return () => {};

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (!entry.borderBoxSize[0]) return;
          store.current.textareaHeight = entry.borderBoxSize[0].blockSize;
          callback();
        }
      });

      resizeObserver.observe(textarea);

      return () => {
        resizeObserver.disconnect();
      };
    },
    () => store.current.textareaHeight
  );

  useEffect(() => {
    const inputElement = textareaAreaRef.current;

    if (inputElement) {
      // フォーカスが当たった時の処理
      const handleFocus = () => {
        setIsFocusedTextArea(true);
      };

      // フォーカスが外れた時の処理
      const handleBlur = () => {
        setIsFocusedTextArea(false);
      };

      // イベントリスナーを追加
      inputElement.addEventListener("focus", handleFocus);
      inputElement.addEventListener("blur", handleBlur);

      // クリーンアップ関数でイベントリスナーを削除
      return () => {
        inputElement.removeEventListener("focus", handleFocus);
        inputElement.removeEventListener("blur", handleBlur);
      };
    }
  }, []);

  const getHighlightedText = useCallback((text: string) => {
    // パターンを正規表現にまとめて全てを分割
    const regex = new RegExp(patterns.join("|"), "g");
    const parts = text.split(regex);

    const matches = text.match(regex) || [];

    return parts
      .reduce((acc, part, index) => {
        return [
          ...acc,
          part,
          matches[index] ? (
            <span key={index} style={{ color: theme.palette.primary.main }}>
              {matches[index]}
            </span>
          ) : null,
        ];
      }, [] as React.ReactNode[])
      .concat("\n"); // NOTE: 最後に改行を追加してTextareaと行数を合わせる
  }, []);

  // NOTE: 表示のズレを解消するためにハイライト用のdivとtextareaのスクロールを連動させる
  const handleScroll = useCallback(() => {
    if (highlightRef.current && textareaAreaRef.current) {
      highlightRef.current.scrollTop = textareaAreaRef.current.scrollTop;
      highlightRef.current.scrollLeft = textareaAreaRef.current.scrollLeft;
    }
  }, []);

  /**
   * メッセージにテキストを挿入する
   */
  const handleInsertTextToMessage = useCallback(
    (text: string) => {
      const target = textareaAreaRef.current;
      if (!target) return onChange(`${value}${text}`);

      // カーソルの前の文字列
      const firstHalfCurrentValue = value.substring(0, target.selectionStart);
      // カーソルの後の文字列
      const secondHalfCurrentValue = value.substring(target.selectionStart);
      onChange(`${firstHalfCurrentValue}${text}${secondHalfCurrentValue}`);
    },
    [onChange, value]
  );

  return (
    <>
      <StyledForm $isError={!!errorMessage}>
        <Box position="relative">
          <StyledHighlight
            ref={highlightRef}
            $isFocusedTextArea={isFocusedTextArea}
            $height={textareaHeight}
          >
            {getHighlightedText(value)}
          </StyledHighlight>
          <StyledTextareaAutosize
            ref={textareaAreaRef}
            value={value}
            fullWidth
            placeholder="メッセージを記入しましょう"
            onChange={(e) => onChange(e.target.value)}
            minRows={5}
            maxRows={5}
            onScroll={handleScroll}
          />
        </Box>
        <StyledBox
          display="flex"
          columnGap="8px"
          justifyContent="start"
          alignItems="center"
          padding="8px"
        >
          <IconButton
            key="template"
            icon="document"
            size="sm"
            color="grey"
            borderRadius="regular"
            buttonRef={contactMessageTemplatesMenuHandling.anchorEl}
            onClick={() => contactMessageTemplatesMenuHandling.openMenu()}
          />
          <StyledIconButton
            key="code"
            icon="code"
            size="md"
            color="grey"
            borderRadius="regular"
            onClick={() => setIsOpenModalOfContactMessageTextSetting(true)}
          />
        </StyledBox>
      </StyledForm>
      {errorMessage && (
        <StyledTypography variant="overline" color={errorMessage ? "error" : "textSecondary"}>
          {errorMessage}
        </StyledTypography>
      )}
      {/*  メッセージのテンプレート管理用メニュー */}
      <ContactMessageTemplateMenu
        menuStartAnchorEl={
          contactMessageTemplatesMenuHandling.isOpen
            ? contactMessageTemplatesMenuHandling.anchorEl.current
            : null
        }
        closeMenu={contactMessageTemplatesMenuHandling.closeMenu}
        reflectMessage={(value) => handleInsertTextToMessage(value)}
      />
      <ModalOfContactMessageTextSetting
        open={isOpenModalOfContactMessageTextSetting}
        onCancel={() => setIsOpenModalOfContactMessageTextSetting(false)}
        onSubmit={(value) => handleInsertTextToMessage(`{${value}}`)} // 変数は{}で囲む
      />
    </>
  );
};

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 4px;
  }
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  border: none !important;

  text-decoration-color: black;
  caret-color: black;
  background: transparent;
  color: transparent;
  z-index: 1;
`;

const StyledHighlight = styled(Box)<{
  $fullWidth?: boolean;
  $error?: boolean;
  $isDisplayCounter?: boolean;
  $isFocusedTextArea: boolean;
  $height: number;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.$isDisplayCounter ? "16px 16px 32px 16px" : "16px")};
  ${(props) => (props.$isFocusedTextArea ? "padding: 17.5px 15px;" : "")}
  height: ${(props) => props.$height}px;

  font-size: 16px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-y: auto;
  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const StyledForm = styled.form<{ $isError: boolean }>`
  border: 1px solid
    ${(props) => (props.$isError ? props.theme.palette.error.main : props.theme.palette.grey[200])};
  border-radius: 4px;
  background-color: white;

  &:hover {
    border: 1px solid
      ${(props) =>
        props.$isError ? props.theme.palette.error.main : props.theme.palette.action.active};
  }

  &:focus-within {
    border: 2px solid
      ${(props) =>
        props.$isError ? props.theme.palette.error.main : props.theme.palette.primary.main};
  }
`;

const StyledBox = styled(Box)`
  ${StyledForm}:focus-within & {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    margin: -1px;
  }
`;

const StyledTypography = styled(Typography)`
  display: block;
`;
