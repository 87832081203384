import { z } from "zod";

import { RichMenuCellTypeSchema } from "../inputTypeSchemas/RichMenuCellTypeSchema";

/////////////////////////////////////////
// REGISTRATION RICH MENU SCHEMA
/////////////////////////////////////////

export const RegistrationRichMenuSchema = z.object({
  cell1Type: RichMenuCellTypeSchema,
  cell2Type: RichMenuCellTypeSchema,
  cell3Type: RichMenuCellTypeSchema,
  cell4Type: RichMenuCellTypeSchema,
  cell5Type: RichMenuCellTypeSchema,
  cell6Type: RichMenuCellTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  externalRichMenuId: z.string(),
  imagePath: z.string(),
  cell1ExternalSiteUrl: z.string().nullish(),
  cell1MessageText: z.string().nullish(),
  cell2ExternalSiteUrl: z.string().nullish(),
  cell2MessageText: z.string().nullish(),
  cell3ExternalSiteUrl: z.string().nullish(),
  cell3MessageText: z.string().nullish(),
  cell4ExternalSiteUrl: z.string().nullish(),
  cell4MessageText: z.string().nullish(),
  cell5ExternalSiteUrl: z.string().nullish(),
  cell5MessageText: z.string().nullish(),
  cell6ExternalSiteUrl: z.string().nullish(),
  cell6MessageText: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RegistrationRichMenu = z.infer<typeof RegistrationRichMenuSchema>;

/////////////////////////////////////////
// REGISTRATION RICH MENU OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const RegistrationRichMenuOptionalDefaultsSchema = RegistrationRichMenuSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type RegistrationRichMenuOptionalDefaults = z.infer<
  typeof RegistrationRichMenuOptionalDefaultsSchema
>;

export default RegistrationRichMenuSchema;
