import { APISchema, SpaceSetting } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/space_api/space-settings"]["GET"];

const generateKey = ({ tenantId, spaceId }: { tenantId?: string; spaceId?: string }) => {
  if (!tenantId || !spaceId) return null;
  return {
    endpoint: "/space_api/space-settings",
    tenantId,
    spaceId,
  } as const;
};
export const useSpaceSetting = ({ tenantId, spaceId }: { tenantId?: string; spaceId?: string }) => {
  const key = generateKey({
    tenantId,
    spaceId,
  });

  return useSWR(key, async ({ endpoint, spaceId, tenantId }) => {
    const query: EndPoint["query"] = {
      spaceId,
      tenantId,
    };
    const response = await apiClient.get(endpoint, query);
    return response.data.spaceSetting ? new SpaceSetting(response.data.spaceSetting) : null;
  });
};
