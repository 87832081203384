import {
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEventDeterminedDate,
} from "@onn/common";

import { useContext, useMemo, useState } from "react";

import {
  StatusForDisplayEventTable,
  getStatusForDisplayEventTable,
} from "../../../components/AnswerResultTab/utils/getStatusForDisplayEventTable";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";
import { captureException } from "~/util";

/**
 * ステータスによる絞り込みと結果
 */
export const useFilterByStatus = ({
  onnEventAnswersWithEmployee,
  employeeIdToLatestEmployeeActiveLogMap,
  getOnnEventDeterminedDate,
  getAnsweredCandidateDate,
}: {
  onnEventAnswersWithEmployee: OnnEventAnswerWithEmployee[];
  employeeIdToLatestEmployeeActiveLogMap: Map<string, EmployeeActiveLog>;
  getOnnEventDeterminedDate: (employeeId: string) => OnnEventDeterminedDate | undefined;
  getAnsweredCandidateDate: (
    employeeId: string
  ) => CandidateDateWithNumberOfParticipants | undefined;
}) => {
  // ステータスに絞り込み条件
  const [selectedStatusTypes, setSelectedStatusTypes] = useState<StatusForDisplayEventTable[]>([]);

  const { recruitmentStatusMap } = useContext(RecruitmentStatusesContext);

  // ステータスによる絞り込み結果
  const answersFilteredByAnswerStatus = useMemo(() => {
    if (!selectedStatusTypes.length) return onnEventAnswersWithEmployee;
    return onnEventAnswersWithEmployee.filter((answer) => {
      const recruitmentStatus = recruitmentStatusMap.get(answer.employee.recruitmentStatusId || "");
      if (!recruitmentStatus) {
        captureException({
          error: new Error("recruitmentStatus が想定しない undefined になっています"),
          tags: { type: "answersFilteredByAnswerStatus" },
          extras: {
            answerId: answer.id,
            employeeId: answer.employee.id,
            recruitmentStatusId: answer.employee.recruitmentStatusId,
            recruitmentStatusMap: Object.fromEntries(recruitmentStatusMap),
          },
        });
        return null;
      }

      const onnEventDeterminedDate = getOnnEventDeterminedDate(answer.employee.id);
      const answeredCandidateDate = getAnsweredCandidateDate(answer.employee.id);
      const employeeActiveLog = employeeIdToLatestEmployeeActiveLogMap.get(answer.employeeId);

      const statusForDisplay = getStatusForDisplayEventTable({
        recruitmentStatus,
        newGraduate: answer.employee,
        onnEventAnswer: answer,
        onnEventDeterminedDate,
        answeredCandidateDate,
        employeeActiveLog,
      });

      return selectedStatusTypes.includes(statusForDisplay);
    });
  }, [
    employeeIdToLatestEmployeeActiveLogMap,
    onnEventAnswersWithEmployee,
    recruitmentStatusMap,
    selectedStatusTypes,
    getOnnEventDeterminedDate,
    getAnsweredCandidateDate,
  ]);

  return {
    answersFilteredByAnswerStatus,
    setSelectedStatusTypes,
    selectedStatusTypes,
  };
};
