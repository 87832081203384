import { RecruitmentStatusWithRelatedInfo, RecruitmentStatus } from "@onn/common";
import React, {
  FC,
  createContext,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from "react";

import { useCurrentUser } from "~/hooks/employee";

import { useRecruitmentStatuses } from "~/hooks/recruitmentStatus";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { RecruitmentStatusRevisionRepository } from "~/infrastructure/api/recruitmentStatusRevisionRepository";
import { captureException } from "~/util";

const recruitmentStatusRevisionRepository = new RecruitmentStatusRevisionRepository();

type recruitmentStatusId = string;
export const RecruitmentStatusesContext = createContext<{
  recruitmentStatuses: RecruitmentStatusWithRelatedInfo[];
  recruitmentStatusMap: Map<recruitmentStatusId, RecruitmentStatusWithRelatedInfo>;
  getByRecruitmentStatusId: (recruitmentStatusId: string) => RecruitmentStatus | undefined;
}>({
  recruitmentStatuses: [],
  recruitmentStatusMap: new Map(),
  getByRecruitmentStatusId: () => undefined,
});

export const RecruitmentStatusProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  // ポータルの場合は、ステータス一覧を使用しないので即時レンダリングを行う
  if (currentUser.assignedAsNewcomer) {
    return <>{children}</>;
  }

  return <RecruitmentStatusProviderCore>{children}</RecruitmentStatusProviderCore>;
};

export const RecruitmentStatusProviderCore: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  const isFinishedFirstListenerChanged = useRef(false);

  const {
    data: recruitmentStatuses = [],
    isLoading,
    mutate,
  } = useRecruitmentStatuses({ withDeleted: false });
  useEffect(() => {
    if (!isLoading && recruitmentStatuses.length === 0) {
      captureException({
        error: new Error("選考ステータス一覧が取得できませんでした"),
        tags: { type: "RecruitmentStatusProvider" },
      });
    }
  }, [isLoading, recruitmentStatuses]);

  useEffect(() => {
    const unsubscribeFunction = recruitmentStatusRevisionRepository.listenRecruitmentStatusRevision(
      {
        tenantId: currentUser.tenantId,
        spaceId: currentSpace.id,
        onChangedRecruitmentStatusRevision: (newRevision) => {
          // NOTE: リスナー登録時に必ず1回発火してしまうため、初回の発火は無視する
          if (!isFinishedFirstListenerChanged.current) {
            isFinishedFirstListenerChanged.current = true;
            return;
          }
          if (newRevision.updatedEmployeeId === currentUser.id) return;
          enqueueSnackbar("他の管理者によって選考ステータスが編集されました。", {
            variant: "success",
          });
          mutate();
        },
      }
    );
    return () => {
      unsubscribeFunction();
      isFinishedFirstListenerChanged.current = false;
    };
  }, [currentSpace.id, currentUser.id, currentUser.tenantId, enqueueSnackbar, mutate]);

  const getByRecruitmentStatusId = useCallback(
    (recruitmentStatusId: string) => {
      return recruitmentStatuses.find(
        (recruitmentStatus) => recruitmentStatus.id === recruitmentStatusId
      );
    },
    [recruitmentStatuses]
  );

  const recruitmentStatusMap = useMemo(() => {
    return new Map(
      recruitmentStatuses.map((recruitmentStatus) => [recruitmentStatus.id, recruitmentStatus])
    );
  }, [recruitmentStatuses]);

  if (isLoading) return null;

  return (
    <RecruitmentStatusesContext.Provider
      value={{ recruitmentStatuses, recruitmentStatusMap, getByRecruitmentStatusId }}
    >
      {children}
    </RecruitmentStatusesContext.Provider>
  );
};

export const useRecruitmentStatusList = () => useContext(RecruitmentStatusesContext);
