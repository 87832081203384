import { ScenarioEditLock } from "@onn/common";
import { useCallback, useEffect, useMemo } from "react";

import { useCurrentUser } from "~/hooks/employee";

import {
  useMutateUncompletedScenarioEditLocks,
  useUncompletedScenarioEditLocks,
} from "~/hooks/scenario/useUncompletedScenarioEditLocks";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { ScenarioEditLockRepository } from "~/infrastructure/api/scenarioEditLockRepository";

const scenarioEditLockRepository = new ScenarioEditLockRepository();

/**
 * シナリオ編集がロック中かどうかを取得する
 * firestoreの変更を監視しているため、ロックが解除された場合は即座に反映される
 */
export const useIsLocking = () => {
  const { data: uncompletedLocks = [], isValidating } = useUncompletedScenarioEditLocks();
  const { currentSpace } = useCurrentSpace();

  const { mutateUncompletedScenarioEditLocks } = useMutateUncompletedScenarioEditLocks();
  const onChangedScenarioEditLock = useCallback(
    (_: ScenarioEditLock) => {
      mutateUncompletedScenarioEditLocks({
        tenantId: currentSpace.tenantId,
        spaceId: currentSpace.id,
      });
    },
    [currentSpace.id, currentSpace.tenantId, mutateUncompletedScenarioEditLocks]
  );
  useScenarioEditLockListener({ onChangedScenarioEditLock });

  const isLocking = useMemo(
    () => uncompletedLocks.length > 0 || isValidating,
    [uncompletedLocks.length, isValidating]
  );
  const lockInfo = uncompletedLocks[0];

  const { currentUser } = useCurrentUser();
  const isLockingByOthers = useMemo(
    () => isLocking && !!lockInfo && lockInfo?.operationEmployeeId !== currentUser?.id,
    [currentUser?.id, isLocking, lockInfo]
  );

  const isLockingByOwn = useMemo(
    () => isLocking && !!lockInfo && lockInfo?.operationEmployeeId === currentUser?.id,
    [currentUser?.id, isLocking, lockInfo]
  );

  return { isLocking, isLockingByOthers, isLockingByOwn };
};

const useScenarioEditLockListener = ({
  onChangedScenarioEditLock,
}: {
  onChangedScenarioEditLock: (lock: ScenarioEditLock) => void;
}) => {
  const { currentSpace } = useCurrentSpace();

  useEffect(() => {
    const unsubscribe = scenarioEditLockRepository.listenScenarioEditLocks({
      tenantId: currentSpace.tenantId,
      spaceId: currentSpace.id,
      onChangedScenarioEditLock,
    });

    return () => {
      unsubscribe();
    };
  }, [currentSpace.id, currentSpace.tenantId, onChangedScenarioEditLock]);
};
