import { EmployeePrediction } from "@onn/common";
import React, { FC, useCallback, useContext, useMemo } from "react";

import { EmployeePredictionContext } from "~/components/providers/EmployeePredictionProvider";

import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

type Props = {
  selectedPredictionId: string | null;
  onChange: (prediction: EmployeePrediction | null) => void;
};
export const EmployeePredictionSelector: FC<Props> = ({ selectedPredictionId, onChange }) => {
  const { employeePredictions, employeePredictionsMap } = useContext(EmployeePredictionContext);

  const onChange_ = useCallback(
    (predictionId: string) => {
      const prediction = employeePredictionsMap.get(predictionId);
      onChange(prediction || null);
    },
    [employeePredictionsMap, onChange]
  );

  const menuItemOptions = useMemo(() => {
    return employeePredictions
      .map((employeePrediction) => {
        return {
          value: employeePrediction.id,
          name: employeePrediction.label,
        };
      })
      .concat({
        value: "",
        name: "-",
      });
  }, [employeePredictions]);

  return (
    <SelectFormV2<string>
      selected={selectedPredictionId || ""}
      onChange={(e) => onChange_(e.target.value)}
      menuItems={menuItemOptions}
      fullWidth
    />
  );
};
