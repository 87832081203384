import { z } from "zod";

import {
  BriefingSessionCategory,
  CandidateDateWithNumberOfParticipants,
  OnnEvent,
  OnnEventAnswer,
  OnnEventPlace,
  OnnEventSlotDate,
  OnnEventSlotDatesForDisplay,
  onnEventSchema,
} from "../../../OnnEvent";
import { OnnEventDeterminedDate } from "../../../OnnEvent/OnnEventDeterminedDate";
import { OnnEventSlotDateWithNumberOfParticipants } from "../../../OnnEvent/OnnEventSlotDateWithNumberOfParticipants";
import { uploadCsvRequestType } from "../_shared";

export interface APISchemaOnnEvent {
  ["/onn_event_api/onn-event"]: {
    PATCH: {
      body: z.infer<typeof patchOnnEventSchema>["body"];
    };
    POST: {
      body: z.infer<typeof postOnnEventSchema>["body"];
      response: {
        data: {
          onnEvent: ExcludeMethods<OnnEvent>;
          briefingSessionCategories: ExcludeMethods<BriefingSessionCategory>[];
        };
      };
    };
  };
  ["/onn_event_api/slots"]: {
    GET: {
      query: z.infer<typeof getOnnEventSlotSchema>["query"];
      response: {
        data: ExcludeMethods<OnnEventSlotDate>;
      };
    };
  };
  ["/onn_event_api/slots/search-by-conditions"]: {
    POST: {
      body: z.infer<typeof searchOnnEventSlotsByConditionsSchema>["body"];
      response: {
        data: OnnEventSlotDate["id"][];
      };
    };
  };
  ["/onn_event_api/answer-box"]: {
    POST: {
      body: z.infer<typeof createOnnEventAnswerBoxRequestSchema>["body"];
    };
  };
  ["/onn_event_api/answer-new-interview-with-csv"]: {
    POST: {
      body: z.infer<typeof answerNewInterviewOnBehalfOfNewGraduatesByCsvRequestSchema>["body"];
      response:
        | {
            isOk: true;
          }
        | {
            isOk: false;
            errorMessages: string[];
          };
    };
  };
  ["/onn_event_api/answer-on-behalf-to-exist-slot-date-with-csv"]: {
    POST: {
      body: z.infer<typeof answerOnBehalfToExistedSlotDateWithCsvRequestSchema>["body"];
      response:
        | {
            isOk: true;
          }
        | {
            isOk: false;
            errorMessages: string[];
          };
    };
  };

  ["/onn_event_api/briefing-session-categories"]: {
    GET: {
      query: z.infer<typeof getBriefingSessionCategoriesSchema>["query"];
      response: {
        data: ExcludeMethods<BriefingSessionCategory>[];
      };
    };
  };
  ["/api/onn-events/id"]: {
    GET: {
      query: z.infer<typeof getOnnEventRequestSchema>["query"];
      response: {
        data: {
          onnEvent: ExcludeMethods<OnnEvent>;
          onnEventAnswer: ExcludeMethods<OnnEventAnswer> | null;
          onnEventDeterminedDate: ExcludeMethods<OnnEventDeterminedDate> | null;
        };
      };
    };
  };
  ["/api/portal/onn-events/id"]: {
    GET: {
      query: z.infer<typeof getOnnEventForPortalRequestSchema>["query"];
      response: {
        data: {
          onnEvent: ExcludeMethods<OnnEvent>;
          onnEventAnswer: OnnEventAnswer;
          onnEventDeterminedDate: OnnEventDeterminedDate | null;
          onnEventPlaces: ExcludeMethods<OnnEventPlace>[];
          candidateDatesWithNumberOfParticipants: CandidateDateWithNumberOfParticipants[] | null;
          onnEventSlotDateWithNumberOfParticipants: ExcludeMethods<OnnEventSlotDateWithNumberOfParticipants>[];
          briefingSessionCategories: ExcludeMethods<BriefingSessionCategory>[];
        };
      };
    };
  };
  ["/api/portal/onn-events"]: {
    GET: {
      response: {
        data: {
          onnEvent: ExcludeMethods<OnnEvent>;
          isAnswered: boolean;
        }[];
      };
    };
  };
  ["/api/onn-event-slot-dates"]: {
    GET: {
      query: z.infer<typeof getOnnEventSlotDatesForDisplayRequestSchema>["query"];
      response: {
        data: {
          onnEventSlotDatesForDisplay: ExcludeMethods<OnnEventSlotDatesForDisplay>[];
        };
      };
    };
    PATCH: {
      body: z.infer<typeof patchOnnEventSlotsSchema>["body"];
      response: {
        onnEventSlotsDate: ExcludeMethods<OnnEventSlotDate>[];
      };
    };
    POST: {
      body: z.infer<typeof createOnnEventSlotSchema>["body"];
      response: {
        onnEventSlotDate: ExcludeMethods<OnnEventSlotDate>;
      };
    };
    DELETE: {
      body: {
        onnEventId: string;
        onnEventSlotIds: string[];
      };
      response: {
        successfullyDeletedSlotIds: string[];
        failedToDeleteSlotIds: string[];
        notifiedEmployeeIds: string[];
      };
    };
  };
  ["/api/onn-event-slot-dates/id"]: {
    PATCH: {
      body: z.infer<typeof patchOnnEventSlotSchema>["body"];
      response: {
        onnEventSlotDate: ExcludeMethods<OnnEventSlotDate>;
      };
    };
    DELETE: {
      body: {
        onnEventId: string;
        onnEventSlotId: string;
      };
    };
  };
  ["/api/onn-event-answers/new-interview/on-behalf"]: {
    POST: {
      body: z.infer<typeof answerNewEventOnBehalfOfNewGraduateRequestSchema>["body"];
    };
  };
  ["/api/onn-event-answers/new-interview"]: {
    POST: {
      body: z.infer<typeof answerNewInterviewEventRequestSchema>["body"];
    };
  };
  ["/api/onn-event-answers/id/attendance-status"]: {
    PATCH: {
      body: z.infer<typeof updateAttendanceStatusRequestSchema>["body"];
    };
  };
  ["/api/onn-event-answers/remove-determined-date-of-slot-date"]: {
    POST: {
      body: z.infer<typeof removeNewInterviewDeterminedDateRequestSchema>["body"];
      response: { isNotified: boolean; isRemoved: boolean };
    };
  };
  ["/api/onn-event-answers/attend"]: {
    POST: {
      body: z.infer<typeof onnEventAnswerAttendSchema>["body"];
    };
  };
  ["/api/onn-event-slot-dates/csv"]: {
    POST: {
      body: z.infer<typeof createOnnEventSlotDatesByCsvRequestSchema>["body"];
      response:
        | {
            isOk: true;
          }
        | {
            isOk: false;
            errorMessages: string[];
          };
    };
  };
}

export const patchOnnEventSchema = z.object({
  body: z.object({
    id: z.string(),
    updateObject: z.object({
      title: onnEventSchema.shape.title.optional(),
      content: onnEventSchema.shape.content.optional(),
      isRestrictedAnswerFromNewGraduate:
        onnEventSchema.shape.isRestrictedAnswerFromNewGraduate.optional(),
      isRestrictedDaysAgoWhenAnswer: onnEventSchema.shape.isRestrictedDaysAgoWhenAnswer.optional(),
      isRestrictedEditAnswerFromNewGraduate:
        onnEventSchema.shape.isRestrictedEditAnswerFromNewGraduate.optional(),
      daysAgoRestrictAnswer: onnEventSchema.shape.daysAgoRestrictAnswer.optional(),
      slotDefaultValueSetting: onnEventSchema.shape.slotDefaultValueSetting.optional(),
    }),
    briefingSessionCategoriesToCreate: z
      .array(
        z.object({
          title: z.string(),
          description: z.string(),
        })
      )
      .optional(),
    briefingSessionCategoriesToUpdate: z
      .array(
        z.object({
          id: z.string(),
          title: z.string(),
          description: z.string(),
        })
      )
      .optional(),
    briefingSessionCategoryIdsToDelete: z.array(z.string()).optional(),
  }),
});

export const postOnnEventSchema = z.object({
  body: z.object({
    createParams: z.object({
      title: z.string(),
      content: z.string(),
      isRestrictedAnswerFromNewGraduate: z.boolean(),
      isRestrictedDaysAgoWhenAnswer: z.boolean(),
      isRestrictedEditAnswerFromNewGraduate: z.boolean(),
      daysAgoRestrictAnswer: z.number().optional(),
      briefingSessions: z
        .array(
          z.object({
            title: z.string(),
            description: z.string(),
          })
        )
        .min(1),
      type: z.literal("briefing_session"),
    }),
  }),
});

export const getOnnEventSlotSchema = z.object({
  query: z.object({
    id: z.string(),
  }),
});

export const getBriefingSessionCategoriesSchema = z.object({
  query: z.object({
    onnEventId: z.string(),
  }),
});

export const getOnnEventRequestSchema = z.object({
  query: z.object({
    id: z.string(),
    employeeId: z.string(),
  }),
});

export const getOnnEventForPortalRequestSchema = z.object({
  query: z.object({
    id: z.string(),
  }),
});

export const getOnnEventSlotDatesForDisplayRequestSchema = z.object({
  query: z.object({
    onnEventId: z.string(),
  }),
});

export const answerNewEventOnBehalfOfNewGraduateRequestSchema = z.object({
  body: z.union([
    z.object({
      onnEventId: z.string(),
      employeeId: z.string(),
      assigneeId: z.string().nullable(),
      subAssigneeIds: z.array(z.string()),
      selectedOnnEventSlotDateId: z.null(),
      from: z.date(),
      until: z.date(),
      capacity: z.number().int().min(1, { message: "最低1名以上で設定してください" }),
      eventType: z.enum(["offline", "online"]),
      eventPlaceId: z.string().nullable(),
      eventAddressPostCode: z.string().nullable(),
      eventAddressText: z.string().nullable(),
      description: z.string(),
      url: z.string().nullable(),
      briefingSessionCategory: z.discriminatedUnion("type", [
        z.object({ type: z.literal("briefing_session"), briefingSessionCategoryId: z.string() }),
        z.object({ type: z.literal("new_interview"), briefingSessionCategoryId: z.null() }),
      ]),
    }),
    z.object({
      onnEventId: z.string(),
      employeeId: z.string(),
      selectedOnnEventSlotDateId: z.string(),
    }),
  ]),
});

export const answerNewInterviewEventRequestSchema = z.object({
  body: z.object({
    onnEventId: z.string(),
    onnEventSlotDateId: z.string(),
  }),
});

export const updateAttendanceStatusRequestSchema = z.object({
  body: z.discriminatedUnion("type", [
    z.object({
      onnEventId: z.string(),
      targetEmployeeId: z.string(),
      type: z.enum(["new_interview", "briefing_session"]),
      updates: z.object({
        attendanceStatus: z.union([
          z.literal("ABSENT"),
          z.literal("UNREGISTERED"),
          z.literal("ATTENDED"),
        ]),
      }),
    }),
    z.object({
      onnEventId: z.string(),
      targetEmployeeId: z.string(),
      type: onnEventSchema.shape.type.extract(["normal"]),
      updates: z.union([
        z.object({
          attendanceStatus: z.literal("ATTENDED"),
          selectedCandidateDateId: z.string(),
        }),
        z.object({
          attendanceStatus: z.union([z.literal("ABSENT"), z.literal("UNREGISTERED")]),
          selectedCandidateDateId: z.undefined(),
        }),
      ]),
    }),
  ]),
});

export const removeNewInterviewDeterminedDateRequestSchema = z.object({
  body: z.object({
    onnEventId: z.string(),
    targetEmployeeId: z.string(),
  }),
});

export const onnEventAnswerAttendSchema = z.object({
  body: z.object({
    onnEventId: z.string(),
    candidateDateId: z.string(),
  }),
});

export const patchOnnEventSlotsSchema = z.object({
  body: z.object({
    onnEventId: z.string(),
    paramsToUpdate: z.array(
      z.object({
        onnEventSlotId: z.string(),
        from: z.date().optional(),
        until: z.date().optional(),
        capacity: z.number().optional(),
        assigneeId: z.string().optional(),
        eventType: z.enum(["online", "offline"]).optional(),
        description: z.string().optional(),
        url: z.string().optional(),
        eventPlaceId: z.string().optional(),
        eventAddressPostCode: z.string().optional(),
        eventAddressText: z.string().optional(),
        status: z.enum(["draft", "published", "closed"]).optional(),
      })
    ),
  }),
});

const baseParamsToUpdateSchema = z.object({
  from: z.date().optional(),
  until: z.date().optional(),
  capacity: z.number().optional(),
  assigneeId: z.string().optional(),
  subAssigneeIds: z.array(z.string()).optional(),
  eventType: z.enum(["online", "offline"]).optional(),
  description: z.string().optional(),
  url: z.string().optional(),
  eventPlaceId: z.string().optional(),
  eventAddressPostCode: z.string().optional(),
  eventAddressText: z.string().optional(),
  status: z.enum(["draft", "published", "closed"]).optional(),
});

export const patchOnnEventSlotSchema = z.object({
  body: z.object({
    onnEventId: z.string(),
    onnEventSlotId: z.string(),
    paramsToUpdate: z.discriminatedUnion("type", [
      baseParamsToUpdateSchema.merge(
        z.object({
          type: z.literal("briefing_session"),
          briefingSessionCategoryId: z.string().optional(),
        })
      ),
      baseParamsToUpdateSchema.merge(
        z.object({
          type: z.literal("new_interview"),
          briefingSessionCategoryId: z.null().optional(),
        })
      ),
    ]),
  }),
});

const baseParamsToCreateSchema = z.object({
  from: z.date(),
  until: z.date(),
  capacity: z.number(),
  assigneeId: z.string().nullable(),
  subAssigneeIds: z.array(z.string()),
  eventType: z.enum(["online", "offline"]),
  description: z.string(),
  url: z.string().nullable(),
  eventPlaceId: z.string().nullable(),
  eventAddressPostCode: z.string().nullable(),
  eventAddressText: z.string().nullable(),
  status: z.enum(["draft", "published", "closed"]),
});

export const createOnnEventSlotSchema = z.object({
  body: z.object({
    onnEventId: z.string(),
    paramsToCreate: z.discriminatedUnion("type", [
      baseParamsToCreateSchema.merge(
        z.object({ type: z.literal("briefing_session"), briefingSessionCategoryId: z.string() })
      ),
      baseParamsToCreateSchema.merge(
        z.object({ type: z.literal("new_interview"), briefingSessionCategoryId: z.null() })
      ),
    ]),
  }),
});

export const answerNewInterviewOnBehalfOfNewGraduatesByCsvRequestSchema = z.object({
  body: z
    .object({
      onnEventId: z.string(),
    })
    .and(uploadCsvRequestType),
});

export const answerOnBehalfToExistedSlotDateWithCsvRequestSchema = z.object({
  body: z
    .object({
      onnEventId: z.string(),
    })
    .and(uploadCsvRequestType),
});

export const createOnnEventSlotDatesByCsvRequestSchema = z.object({
  body: z
    .object({
      onnEventId: z.string(),
    })
    .and(uploadCsvRequestType),
});

export const createOnnEventAnswerBoxRequestSchema = z.object({
  body: z.object({
    onnEventId: z.string(),
    employeeIds: z.array(z.string()),
  }),
});

export const slotFromDateRangeConditionSchema = z.object({
  target: z.literal("slotFromDateRange"),
  rangeStart: z.date().optional(),
  rangeEnd: z.date().optional(),
});

export const briefingSessionCategoryConditionSchema = z.object({
  target: z.literal("briefingSessionCategoryIds"),
  categoryIds: z.array(z.string()).optional(),
});

export const placesConditionSchema = z.object({
  target: z.literal("placeIds"),
  placeIds: z.array(z.string()).optional(),
});

export const assigneesConditionSchema = z.object({
  target: z.literal("assigneeIds"),
  assigneeIds: z.array(z.string()).optional(),
});

export const statusesConditionSchema = z.object({
  target: z.literal("statuses"), // 予約枠のステータスで検索 ex) draft, published, closed
  statuses: z.array(z.string()).optional(),
});

export const eventTypeConditionSchema = z.object({
  target: z.literal("eventTypes"), // イベントの種類で検索 ex) online, offline
  eventTypes: z.array(z.union([z.literal("online"), z.literal("offline")])).optional(),
});

export const searchOnnEventSlotsByConditionSchema = z.union([
  slotFromDateRangeConditionSchema,
  placesConditionSchema,
  assigneesConditionSchema,
  statusesConditionSchema,
  eventTypeConditionSchema,
  briefingSessionCategoryConditionSchema,
]);

export const searchOnnEventSlotsByConditionsSchema = z.object({
  body: z.object({
    onnEventId: z.string(),
    type: z.enum(["AND", "OR"]),
    conditions: z.array(searchOnnEventSlotsByConditionSchema), // NOTE: 検索項目が増えるごとに追加される
  }),
});
