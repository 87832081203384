import { Box } from "@mui/material";
import {
  contactMessagePlaceHolderToKey,
  DEFAULT_MAX_FILE_SIZE_MB,
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
} from "@onn/common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore,
} from "react";

import { Control, Controller } from "react-hook-form";
import styled from "styled-components";

import { ContactMessagesOrderForm } from "../hooks/contactMessagesOrderFormSchema";

import { useContactMessageTemplatesMenuHandling } from "../hooks/useContactMessageTemplatesMenuHandling";

import { ContactMessageTemplateMenu } from "~/components/domains/contactRooms/ContactRoomItem/parts";
import { IconButton, TextareaAutosizeAttachableFile } from "~/components/uiParts";
import { FormWithTitlePaper } from "~/components/uiParts/FormWithTitlePaper";
import theme from "~/config/theme";
import { ModalOfContactMessageTextSetting } from "~/pages/scenario/Main/editMode/components/Modal/ModalOfContactMessageTextSetting";

type Props = {
  control: Control<ContactMessagesOrderForm, unknown>;
  changeFiles: (newFiles: (File | Pick<File, "name">)[]) => void;
  files: File[];
  setText: (templateMessage: string) => void;
  currentMessage: string;
};

// NOTE: {}を囲んだ文字列をハイライトとする
const patterns = Object.keys(contactMessagePlaceHolderToKey).map((v) => `{${v}}`);

export const TextFormPaper = ({
  control,
  changeFiles,
  files,
  setText,
  currentMessage,
}: Props): JSX.Element => {
  const [isOpenModalOfContactMessageTextSetting, setIsOpenModalOfContactMessageTextSetting] =
    useState(false);
  const contactMessageTemplatesMenuHandling = useContactMessageTemplatesMenuHandling({
    setNewMessage: setText,
    currentMessage,
  });

  const highlightRef = useRef<HTMLDivElement>(null);
  const [isFocusedTextArea, setIsFocusedTextArea] = useState(false);

  const store = useRef({ textareaHeight: 0 });
  const textareaHeight = useSyncExternalStore(
    (callback) => {
      const textarea =
        contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef.current;
      if (!textarea) return () => {};

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (!entry.borderBoxSize[0]) return;
          store.current.textareaHeight = entry.borderBoxSize[0].blockSize;
          callback();
        }
      });

      resizeObserver.observe(textarea);

      return () => {
        resizeObserver.disconnect();
      };
    },
    () => store.current.textareaHeight
  );

  useEffect(() => {
    const inputElement =
      contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef.current;
    if (inputElement) {
      // フォーカスが当たった時の処理
      const handleFocus = () => {
        setIsFocusedTextArea(true);
      };

      // フォーカスが外れた時の処理
      const handleBlur = () => {
        setIsFocusedTextArea(false);
      };

      // イベントリスナーを追加
      inputElement.addEventListener("focus", handleFocus);
      inputElement.addEventListener("blur", handleBlur);

      // クリーンアップ関数でイベントリスナーを削除
      return () => {
        inputElement.removeEventListener("focus", handleFocus);
        inputElement.removeEventListener("blur", handleBlur);
      };
    }
  }, [contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef]);

  const getHighlightedText = useCallback((text: string) => {
    // パターンを正規表現にまとめて全てを分割
    const regex = new RegExp(patterns.join("|"), "g");
    const parts = text.split(regex);

    const matches = text.match(regex) || [];

    return parts
      .reduce((acc, part, index) => {
        acc.push(part);
        if (matches[index]) {
          acc.push(
            <span key={index} style={{ color: theme.palette.primary.main }}>
              {matches[index]}
            </span>
          );
        }
        return acc;
      }, [] as React.ReactNode[])
      .concat("\n");
  }, []);

  // NOTE: 表示のズレを解消するためにハイライト用のdivとtextareaのスクロールを連動させる
  const handleScroll = useCallback(() => {
    if (
      highlightRef.current &&
      contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef.current
    ) {
      highlightRef.current.scrollTop =
        contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef.current.scrollTop;
      highlightRef.current.scrollLeft =
        contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef.current.scrollLeft;
    }
  }, [contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef]);

  const form = useMemo(
    () => (
      <Controller
        name="text"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box position="relative">
            <StyledHighlight
              ref={highlightRef}
              $isFocusedTextArea={isFocusedTextArea}
              $height={textareaHeight}
            >
              {getHighlightedText(field.value)}
            </StyledHighlight>
            <StyledTextareaAutosizeAttachableFile
              fullWidth
              minRows={3}
              maxRows={15}
              maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
              accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
              onChangeFiles={changeFiles}
              attachedFiles={files}
              error={!!error}
              helperText={error?.message}
              onScroll={handleScroll}
              footerLeftActions={
                <>
                  {/* メッセージテンプレートボタン */}
                  <IconButton
                    icon="document"
                    size="sm"
                    color="grey"
                    borderRadius="regular"
                    onClick={contactMessageTemplatesMenuHandling.handleOpen}
                  />
                  {/* 変数適用ボタン */}
                  <StyledIconButton
                    icon="code"
                    size="md"
                    color="grey"
                    borderRadius="regular"
                    onClick={() => setIsOpenModalOfContactMessageTextSetting(true)}
                  />
                </>
              }
              {...field}
              ref={contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef}
            />
          </Box>
        )}
      />
    ),
    [
      control,
      isFocusedTextArea,
      textareaHeight,
      getHighlightedText,
      changeFiles,
      files,
      handleScroll,
      contactMessageTemplatesMenuHandling.handleOpen,
      contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef,
    ]
  );

  return (
    <>
      <FormWithTitlePaper
        title={"メッセージ本文"}
        tooltipTitle={"配信対象全員のコンタクトへ個別に同じ内容のメッセージが送信されます。"}
        form={form}
      />
      <ContactMessageTemplateMenu
        menuStartAnchorEl={contactMessageTemplatesMenuHandling.anchorEl}
        closeMenu={contactMessageTemplatesMenuHandling.handleClose}
        reflectMessage={contactMessageTemplatesMenuHandling.handleReflectMessage}
      />
      <ModalOfContactMessageTextSetting
        open={isOpenModalOfContactMessageTextSetting}
        onCancel={() => setIsOpenModalOfContactMessageTextSetting(false)}
        onSubmit={(value) => contactMessageTemplatesMenuHandling.handleReflectMessage(`{${value}}`)} // 変数は{}で囲む
      />
    </>
  );
};

const StyledTextareaAutosizeAttachableFile = styled(TextareaAutosizeAttachableFile)`
  border: none !important;

  text-decoration-color: black;
  caret-color: black;
  background: transparent;
  color: transparent;
  z-index: 1;
`;

const StyledHighlight = styled(Box)<{
  $fullWidth?: boolean;
  $error?: boolean;
  $isDisplayCounter?: boolean;
  $isFocusedTextArea: boolean;
  $height: number;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.$isDisplayCounter ? "16px 16px 32px 16px" : "16px")};
  ${(props) => (props.$isFocusedTextArea ? "padding: 18.5px 16px;" : "")}
  height: ${(props) => props.$height}px;

  font-size: 16px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-y: auto;
  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 4px;
  }
`;
