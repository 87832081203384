import { RecruitmentStatusType } from "../Recruitment/RecruitmentStatus";

import { Employee } from "./Employee";

export class NewGraduateToDisplay extends Employee {
  spaceId: string;

  isNewGraduate: true;
  assignedAsNewcomer: true;
  recruitmentStatusId: string;
  recruitmentStatusLabel: string;
  recruitmentStatusType: RecruitmentStatusType;
  uniqueId: string;

  employeeTagIds: string[];
  predictionId: string | null; // NOTE: 実装時点では単数シナリオのみのため、単数としている
  constructor(
    employee: Employee & { spaceId: string; uniqueId: string },
    employeeTagIds: string[],
    recruitmentStatus: {
      id: string;
      label: string;
      type: RecruitmentStatusType;
    },
    predictionId: string | null
  ) {
    super(employee);
    this.spaceId = employee.spaceId;
    this.employeeTagIds = employeeTagIds;
    this.isNewGraduate = true;
    this.assignedAsNewcomer = true;
    this.recruitmentStatusId = recruitmentStatus.id;
    this.recruitmentStatusLabel = recruitmentStatus.label;
    this.recruitmentStatusType = recruitmentStatus.type;
    this.uniqueId = employee.uniqueId;
    this.predictionId = predictionId;
  }

  /**
   * Onnの通知機能とそれに関連する機能を利用可能かどうかを返す
   * ただしポータルが利用可能かの判定には使わないこと
   */
  canUseNotificationFeatures(): boolean {
    const isRejectedOrWithdrew = ["rejected", "withdrew"].includes(this.recruitmentStatusType);
    return !this.deleted && !isRejectedOrWithdrew && !this.isNotRegisteredAndInvited();
  }

  /**
   * ポータルが利用可能かどうかを返す
   */
  canUsePortal(): boolean {
    const isRejectedOrWithdrew = ["rejected", "withdrew"].includes(this.recruitmentStatusType);
    return !this.deleted && !isRejectedOrWithdrew && this.isRegistered();
  }

  isPreEntry(): boolean {
    return this.recruitmentStatusType === "pre_entry";
  }

  isRejectedOrWithdrew(): boolean {
    return ["rejected", "withdrew"].includes(this.recruitmentStatusType);
  }
}
