import { Box } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import { ScenarioListTable } from "./ScenarioListTable/ScenarioListTable";

import { OptionSettingDrawer } from "~/components/domains/richmenu/OptionSettingDrawer/OptionSettingDrawer";

export const ScenarioTab = () => {
  const [isOpenOptionSettingDrawer, setIsOpenOptionSettingDrawer] = useState(false);
  const onClickRichMenuSetting = useCallback(() => {
    setIsOpenOptionSettingDrawer(true);
  }, []);
  const closeOptionSettingDrawer = useCallback(() => {
    setIsOpenOptionSettingDrawer(false);
  }, []);

  const optionSettingDrawerKey = isOpenOptionSettingDrawer ? "open" : "close";
  return (
    <Box pt={4}>
      <ScenarioListTable onClickRichMenuSetting={onClickRichMenuSetting} />
      <OptionSettingDrawer
        key={optionSettingDrawerKey}
        isOpen={isOpenOptionSettingDrawer}
        close={closeOptionSettingDrawer}
        recruitmentStatusId={null}
        type={"registrationRichMenu"}
      />
    </Box>
  );
};
