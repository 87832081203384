import { Box } from "@material-ui/core";
import { NewGraduateToDisplay, RecruitmentStatus } from "@onn/common";
import React, { ComponentProps, FC, memo, useCallback } from "react";

import { ChangeRecruitmentStatusModal } from "../ChangeRecruitmentStatusModal";

import { NewGraduateInvitationChip } from "./NewGraduateInvitationChip";
import { NewGraduateRecruitmentStatusChip } from "./NewGraduateRecruitmentStatusChip";

import { useModal } from "~/hooks/modal";

type Props = {
  newGraduate: NewGraduateToDisplay;
  onUpdateRecruitmentStatus?: ComponentProps<
    typeof ChangeRecruitmentStatusModal
  >["onUpdateRecruitmentStatus"];
};

/**
 * 新卒候補者用
 * 候補者(選考)ステータス・招待ステータス を表示するためのチップ
 * 候補者(選考)ステータスは変更可能
 */
export const NewGraduateStatusChip: FC<Props> = memo(
  ({ newGraduate, onUpdateRecruitmentStatus }) => {
    const { handleModal } = useModal();
    const updateRecruitmentStatus = useCallback(
      (recruitmentStatusToChange: RecruitmentStatus) => {
        if (newGraduate.recruitmentStatusId === recruitmentStatusToChange.id) return;

        handleModal({
          name: "changeRecruitmentStatusModal",
          args: {
            newGraduate,
            recruitmentStatus: recruitmentStatusToChange,
            onUpdateRecruitmentStatus,
          },
        });
      },
      [handleModal, newGraduate, onUpdateRecruitmentStatus]
    );

    return (
      <Box display={"flex"} gridColumnGap={"8px"}>
        <NewGraduateRecruitmentStatusChip
          recruitmentStatusInfo={{
            id: newGraduate.recruitmentStatusId,
            label: newGraduate.recruitmentStatusLabel,
            type: newGraduate.recruitmentStatusType,
          }}
          updateRecruitmentStatus={updateRecruitmentStatus}
        />
        {!newGraduate.isRegistered() && (
          <NewGraduateInvitationChip invited={newGraduate.isInvited()} />
        )}
      </Box>
    );
  }
);
