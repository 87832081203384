import { Tooltip } from "@mui/material";
import React, { FC, useRef } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

// 一行のTypographyのテキストが省略されている場合にTooltipを表示するコンポーネント
export const TooltipWhenTextTruncatedOneLine: FC<{
  text: string;
  typographyProps?: Omit<React.ComponentProps<typeof Typography>, "ref">;
}> = ({ text, typographyProps }) => {
  const ref = useRef<HTMLSpanElement>(null);

  // StyledTypographyの要素のサイズを監視して、テキストが省略されているかどうかを判定するために使用
  const subscribeResizeObserver = React.useCallback((onStoreChange: () => void) => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((_) => {
        onStoreChange();
      });
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  const isTruncated = React.useSyncExternalStore(subscribeResizeObserver, () => {
    if (!ref.current) return false;
    return ref.current.offsetWidth < ref.current.scrollWidth;
  });

  return (
    // NOTE: uiPartのTooltipを使用すると無限レンダリングエラーになるため、material-uiのTooltipを使用
    <Tooltip title={isTruncated ? text : ""} arrow>
      <StyledTypography ref={ref} {...typographyProps}>
        {text}
      </StyledTypography>
    </Tooltip>
  );
};

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
