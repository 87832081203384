import { Box } from "@material-ui/core";
import { AllContactRoom, ContactMessageDraft } from "@onn/common";
import { isEmpty, throttle } from "lodash";
import React, { useCallback, FC, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { MessagesList } from "./MessagesList";
import { TextareaFooter } from "./TextareaFooter";

import { AllContactMessage } from "./type";

import { ContactContextV2 } from "~/components/providers";
import { Loading, Paper, Typography } from "~/components/uiParts";
import { useCreateContactMessageByNewHire } from "~/hooks/contactMessage";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useContactMessages } from "~/hooks/contactMessage/useContactMessages";
import { useContactMessageDraftByCreatedUserId } from "~/hooks/contactMessageDraft";
import { useUpdateReadLogOfContactRoom } from "~/hooks/contactRoom";
import { useCurrentUser } from "~/hooks/employee";
import { NotFound } from "~/pages/NotFound";

export const ContactMessageDetailContainer: FC = () => {
  const { id: contactRoomId = "" } = useParams<"id">();
  const { currentUser } = useCurrentUser();
  const { lastUpdatedAt } = useContactContext();

  const {
    data: contactRoomAndMessages = {
      contactRoom: undefined,
      messages: [],
      meta: { fileSignedUrlMapObj: {} as Record<string, string> },
    },
    isLoading,
    mutate,
  } = useContactMessages({
    tenantId: currentUser.tenantId,
    contactRoomId,
    isWithAttachedFileSignedUrl: true,
  });

  const fileSignedUrlMapObj = contactRoomAndMessages.meta?.fileSignedUrlMapObj;

  const { contactRooms, isLoadingContactRooms } = useContext(ContactContextV2);

  // NOTE:throttleを使用することで、一気にメッセージの更新が行われても、CFへのリクエストが一気に行われることを防ぐ
  const throttleMutate = throttle(() => {
    mutate();
  }, 3000);

  useEffect(() => {
    throttleMutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedAt]);

  const {
    contactMessageDrafts,
    isLoading: isLoadingDraft,
    saveContactMessageDraft,
    refetchContactMessageDraft,
  } = useContactMessageDraftByCreatedUserId(currentUser.id);

  if (contactRoomId === "") return <NotFound />;
  // getter, setterが抜けていないcontactRoomのインスタンスを作成
  const contactRoom = contactRooms.find((cr) => cr.id === contactRoomId);
  if (isLoading || isLoadingDraft || isLoadingContactRooms)
    return <Loading size={"large"} fullHeight />;
  if (!contactRoomAndMessages.contactRoom || !contactRoom) return <NotFound />;

  return (
    <ContactMessageDetail
      contactRoom={contactRoom}
      contactMessages={contactRoomAndMessages.messages}
      contactMessageDrafts={contactMessageDrafts}
      saveContactMessageDraft={saveContactMessageDraft}
      refetchContactMessageDraft={refetchContactMessageDraft}
      fileSignedUrlMapObj={fileSignedUrlMapObj}
    />
  );
};

type ContactMessageDetailProps = {
  contactRoom: AllContactRoom;
  contactMessages: AllContactMessage[];
  contactMessageDrafts?: ContactMessageDraft[];
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
  refetchContactMessageDraft: () => void;
  fileSignedUrlMapObj: Record<string, string>;
};

export const ContactMessageDetail: FC<ContactMessageDetailProps> = ({
  contactRoom,
  contactMessages,
  contactMessageDrafts,
  saveContactMessageDraft,
  refetchContactMessageDraft,
  fileSignedUrlMapObj,
}) => {
  const { id: contactRoomId } = useParams<"id">();
  const { currentUser } = useCurrentUser();

  const [messageListRef, setMessageListRef] = useState<HTMLDivElement>();
  const [footerHeight, setFooterHeight] = useState(0);

  const { createContactMessage } = useCreateContactMessageByNewHire();

  const { updateReadLogOfContactRoom } = useUpdateReadLogOfContactRoom();

  useEffect(() => {
    if (!messageListRef) {
      return;
    }

    messageListRef.scrollTop = messageListRef.scrollHeight;
    // messageに変更があったときに一番したまでスクロールする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageListRef, contactMessages]);

  useEffect(() => {
    if (!isEmpty(contactMessages) && contactRoomId) {
      const fn = async () => {
        await Promise.all([
          refetchContactMessageDraft(),
          updateReadLogOfContactRoom(currentUser.id, contactRoomId),
        ]);
      };

      fn();
    }
    // ページアクセス時のみの実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id, contactRoomId]);

  const handleSubmit = useCallback(
    async (newMessage: string, newMessageFiles: File[]) => {
      if (contactRoom) {
        await createContactMessage(
          newMessage,
          currentUser.tenantId,
          currentUser.id,
          contactRoom,
          newMessageFiles
        );
      }
    },
    [contactRoom, createContactMessage, currentUser.tenantId, currentUser.id]
  );

  const contactMessageDraft =
    contactMessageDrafts?.find(({ contactRoomId: cmid }) => contactRoomId === cmid) ??
    ContactMessageDraft.create({
      tenantId: currentUser.tenantId,
      contactRoomId: contactRoom.id,
      createdUserId: currentUser.id,
      text: "",
    });

  return (
    <StyledBox height="100%">
      <Box height="100%" display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          gridGap="32px"
          padding="16px"
          overflow="scroll"
          height="100%"
          width="100%"
          mx="auto"
          mb={`${footerHeight}px`}
          // Box に ref を渡すための ワークアラウンド:https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360
          // TODO: Mui v5に更新したら、refを直接渡せるようになるので修正する
          {...{ ref: (node: HTMLDivElement) => setMessageListRef(node) }}
        >
          {isEmpty(contactMessages) ? (
            <StyledPaper>
              <Typography variant="caption">
                {`入社にあたって聞きたいことや相談したいことがある方は、\nこちらから人事・受け入れ担当者へ問い合わせましょう。`}
              </Typography>
            </StyledPaper>
          ) : (
            <MessagesList
              contactMessages={contactMessages}
              fileSignedUrlMapObj={fileSignedUrlMapObj}
            />
          )}
        </Box>
        <TextareaFooter
          onSubmit={(newMessage, newMessageFiles) => handleSubmit(newMessage, newMessageFiles)}
          contactMessageDraft={contactMessageDraft}
          onChangeHeight={(height) => setFooterHeight(height)}
          saveContactMessageDraft={saveContactMessageDraft}
        />
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledPaper = styled(Paper)`
  text-align: center;
  padding: 16px;
  margin: 0 auto;
`;
