import { v4 } from "uuid";

import { IPortalRichMenuRelation, portalRichMenuRelationSchema } from "./schema";

export class PortalRichMenuRelation implements IPortalRichMenuRelation {
  static readonly validator = portalRichMenuRelationSchema;
  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly recruitmentStatusId: string;
  readonly internalPortalRichMenuId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(data: ExcludeMethods<PortalRichMenuRelation>) {
    const parsedInit = PortalRichMenuRelation.validator.parse(data);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;
    this.internalPortalRichMenuId = parsedInit.internalPortalRichMenuId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(
    init: Omit<ExcludeMethods<PortalRichMenuRelation>, "id" | "createdAt" | "updatedAt">
  ): PortalRichMenuRelation {
    return new PortalRichMenuRelation({
      ...init,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(data: { internalPortalRichMenuId: string }): PortalRichMenuRelation {
    return new PortalRichMenuRelation({
      ...this,
      ...data,
      updatedAt: new Date(),
    });
  }
}
