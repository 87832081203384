import { OnnFormTaskAnswer, NewGraduateToDisplay } from "@onn/common";
import { includes } from "lodash";
import React, { useCallback } from "react";

import { AnswerCell } from "../../components/AnswerResultTab/table/AnswerCell";
import { AnswerStatusCell } from "../../components/AnswerResultTab/table/AnswerStatusCell";
import { UserIconWithLabelCell } from "../../components/AnswerResultTab/table/UserIconWithLabelCell";

import { SortedRevisionQuestionDataList } from "./useGenerateSortedRevisionQuestionDataList";

export const useGenerateDateRow = () => {
  const generateDataRow = useCallback(
    ({
      onnFormTaskAnswer,
      newGraduate,
      sortedRevisionQuestionDataList,
    }: {
      sortedRevisionQuestionDataList: SortedRevisionQuestionDataList;
      onnFormTaskAnswer: OnnFormTaskAnswer;
      newGraduate: NewGraduateToDisplay;
    }): JSX.Element[] => {
      const isWithdrewOrRejected =
        newGraduate.recruitmentStatusType &&
        includes(["withdrew", "rejected"], newGraduate.recruitmentStatusType);

      const answerCells = sortedRevisionQuestionDataList.map((revisionQuestionData, index) => {
        return (
          <AnswerCell
            key={`${newGraduate.id}-${index}`}
            question={revisionQuestionData.question}
            answers={onnFormTaskAnswer.answers}
            isWithdrewOrRejected={isWithdrewOrRejected}
          />
        );
      });

      return [
        <UserIconWithLabelCell
          key={`UserIconWithLabelCell-${onnFormTaskAnswer.id}`}
          newGraduate={newGraduate}
          onnTaskId={onnFormTaskAnswer.formTaskId}
          onnFormTaskAnswerId={onnFormTaskAnswer.id}
          isAnswered={onnFormTaskAnswer.isAnswered()}
        />,
        <AnswerStatusCell
          key={`AnswerResult-Data-${onnFormTaskAnswer.id}`}
          isAnswered={onnFormTaskAnswer.isAnswered()}
          recruitmentStatusType={newGraduate.recruitmentStatusType}
          recruitmentStatusLabel={newGraduate.recruitmentStatusLabel}
          lastReadAt={onnFormTaskAnswer.lastReadAt}
        />,
        ...answerCells,
      ];
    },
    []
  );
  return { generateDataRow };
};
