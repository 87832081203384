import { Employee } from "../../Employee";
import { KanaName } from "../../EmployeeInformation";
import { ContactRoom } from "../ContactRoom";

export abstract class ContactRoomWithEmployee extends ContactRoom {
  employee?: Employee; // contactRoomのemployeeIdに紐づくEmployee

  // NOTE: employeeInformationに格納されているコンタクトルーム検索対象
  // employeeInformationをそのまま格納するとパフォーマンスが落ちるため、検索に必要な情報だけを格納する
  kanaName?: KanaName;

  constructor(init: { contactRoom: ContactRoom; employee?: Employee; kanaName?: KanaName }) {
    super(init.contactRoom);
    this.employee = init.employee;
    this.kanaName = init.kanaName;
  }

  abstract getRoomName(): string;

  abstract getHonorificRoomName(): string;
}
