import { useMemo } from "react";

export const useTableHeaders = ({
  checked,
  indeterminate,
  onChangeCheckBox,
}: {
  checked: boolean;
  indeterminate: boolean;
  onChangeCheckBox: () => void;
}) => {
  const headers = useMemo(
    () => [
      {
        text: "",
        onChangeCheckBox,
        checked,
        indeterminate,
      },
      {
        text: "候補者",
      },
      {
        text: "シナリオ／選考ステータス",
      },
      {
        text: "担当者",
      },
      {
        text: "",
      },
    ],
    [checked, indeterminate, onChangeCheckBox]
  );

  return headers;
};
