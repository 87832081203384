import { Box, TableCell, TableRow } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { headers } from "../Header/TableHeaderRow";

import { ChangeEmployeePredictionSelector } from "~/components/domains/employeePrediction/ChangeEmployeePredictionSelector";
import { ChangeRecruitmentStatusSelector } from "~/components/domains/recruitmentStatus/ChangeRecruitmentStatusSelector";
import { Typography } from "~/components/uiParts";
import theme from "~/config/theme";

type Props = {
  employee: NewGraduateToDisplay;
};

export const Row: FC<Props> = ({ employee }) => {
  const cells = [
    <ScenarioName key={0} />,
    <ChangeRecruitmentStatusSelector key={1} newGraduate={employee} />,
    <ChangeEmployeePredictionSelector key={2} newGraduate={employee} />,
  ];
  return (
    <TableRow>
      {headers.map((v, index) => {
        return <Cell key={index}> {cells[index]}</Cell>;
      })}
    </TableRow>
  );
};

const ScenarioName: FC = () => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderRadius: "4px",
        borderColor: theme.palette.grey[200],
        backgroundColor: theme.palette.grey[50],
        height: "40px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "12px",
      }}
    >
      <Typography variant="body2" color="textPrimary">
        共通
      </Typography>
    </Box>
  );
};

export const Cell = styled(TableCell)`
  &.MuiTableCell-root {
    height: 80px;
    padding: 0px 16px 0px 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;
