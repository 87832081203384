import { Box } from "@material-ui/core";
import {
  NewGraduateToDisplay,
  OnnEvent,
  EmployeeActiveLog,
  CandidateDateWithNumberOfParticipants,
  RecruitmentStatus,
  OnnEventDeterminedDate,
} from "@onn/common";
import React, { useCallback } from "react";

import styled from "styled-components";

import { CAN_NOT_JOIN } from "../../hooks/OnnEventAnswerResultTab/filter/useFilterByCandidateDates";

import { AnswerIconForUnAvailable } from "./TableCell/Body/AnswerIconForUnAvailable";
import { AnswerIconWhenExistDeterminedDate } from "./TableCell/Body/AnswerIconWhenExistDeterminedDate";
import { AnswerIconWhenNotExistDeterminedDate } from "./TableCell/Body/AnswerIconWhenNotExistDeterminedDate";
import { AnswerStatusCellWrapper } from "./TableCell/Body/AnswerStatusCellWrapper";
import { UserIconWithLabel } from "./TableCell/Body/UserIconWithLabel";

import { getStatusForDisplayEventTable } from "./utils/getStatusForDisplayEventTable";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

export const useGenerateDateRow = () => {
  const generateDataRow = useCallback(
    ({
      onnEvent,
      onnEventAnswer,
      determinedDate,
      employeeActiveLog,
      recruitmentStatusMap,
      candidateDateMap,
      candidateDateList,
      selectedCandidateDateIds,
    }: {
      onnEvent: OnnEvent;
      onnEventAnswer: OnnEventAnswerWithEmployee;
      determinedDate?: OnnEventDeterminedDate;
      employeeActiveLog?: EmployeeActiveLog;
      recruitmentStatusMap: Map<string, RecruitmentStatus>;
      candidateDateMap: Map<string, CandidateDateWithNumberOfParticipants>;
      candidateDateList: CandidateDateWithNumberOfParticipants[];
      selectedCandidateDateIds: string[];
    }): JSX.Element[] => {
      const employee = onnEventAnswer.employee;
      const recruitmentStatus = recruitmentStatusMap.get(employee.recruitmentStatusId || "");

      if (!recruitmentStatus) return [];

      const answeredCandidateDate = determinedDate
        ? candidateDateMap.get(determinedDate.candidateDateId)
        : undefined;

      const statusForDisplayEventTable = getStatusForDisplayEventTable({
        recruitmentStatus,
        onnEventDeterminedDate: determinedDate,
        onnEventAnswer,
        answeredCandidateDate,
        employeeActiveLog,
        newGraduate: employee,
      });

      // NOTE: 「回答結果」列のセル
      const answerCellRenderer = (candidateDate: CandidateDateWithNumberOfParticipants) => {
        if (determinedDate) {
          // 日程が確定している場合
          return (
            <BorderBox>
              <AnswerIconWhenExistDeterminedDate
                key={determinedDate.id + candidateDate.id}
                determinedDate={determinedDate}
                candidateDate={candidateDate}
                statusForDisplayEventTable={statusForDisplayEventTable}
              />
            </BorderBox>
          );
        } else {
          // 日程が確定していない場合
          return (
            <BorderBox>
              <AnswerIconWhenNotExistDeterminedDate
                key={onnEventAnswer.id + candidateDate.id}
                candidateDate={candidateDate}
                onnEventAnswerWithEmployee={onnEventAnswer}
                statusForDisplayEventTable={statusForDisplayEventTable}
              />
            </BorderBox>
          );
        }
      };

      const bodyRow = [
        <BorderBox key="userIconWithLabel">
          <UserIconWithLabel
            newGraduate={employee as NewGraduateToDisplay}
            key={onnEventAnswer.id}
            onnEvent={onnEvent}
            onnEventAnswer={onnEventAnswer}
          />
        </BorderBox>,
        <AnswerStatusCellWrapper
          key="answerStatusCellWrapper"
          onnEvent={onnEvent}
          onnEventAnswer={onnEventAnswer}
          statusForDisplayEventTable={statusForDisplayEventTable}
          lastReadAt={employeeActiveLog ? employeeActiveLog.createdAt : null}
        />,
      ];

      // NOTE: 開催日時フィルターが選択されている場合は、選択された日程のみ表示する
      const displayedCandidateDates =
        selectedCandidateDateIds.length !== 0
          ? candidateDateList.filter((candidateDate) => {
              return selectedCandidateDateIds.includes(candidateDate.id);
            })
          : candidateDateList;

      displayedCandidateDates.forEach((candidateDate) => {
        bodyRow.push(answerCellRenderer(candidateDate));
      });

      // NOTE: 開催日時フィルターが選択されていない場合か、「参加できる日程がない」のみ表示する
      if (
        selectedCandidateDateIds.length === 0 ||
        selectedCandidateDateIds.includes(CAN_NOT_JOIN)
      ) {
        bodyRow.push(
          <BorderBox>
            <AnswerIconForUnAvailable
              key={`${onnEventAnswer.id}-unavailable`}
              onnEventAnswerWithEmployee={onnEventAnswer}
              statusForDisplayEventTable={statusForDisplayEventTable}
            />
          </BorderBox>
        );
      }

      return bodyRow;
    },
    []
  );
  return { generateDataRow };
};

const BorderBox = styled(Box)`
  height: 100%;
  width: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px 1px 0px 0px;
  display: flex;
  align-items: center;
`;
