import { Employee, NewGraduateToDisplay } from "@onn/common";
import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import { uniqBy } from "lodash";
import React, { useCallback } from "react";

import { useAddNewGraduateToOnnEventWithCSVButtonHandler } from "./useAddNewGraduateToOnnEventWithCSVButtonHandler";

import { useModal } from "~/hooks/modal";

export const useAddNewGraduateToOnnEventButtonHandler = ({
  selectedNewGraduates,
  alreadyRegisteredNewGraduateIds,
  setSelectedNewGraduates,
  onnEvent,
}: {
  selectedNewGraduates: Employee[];
  alreadyRegisteredNewGraduateIds: string[];
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
}) => {
  const { handleModal } = useModal();
  const { onClickUploadCSVButton } = useAddNewGraduateToOnnEventWithCSVButtonHandler({
    selectedNewGraduates,
    alreadyRegisteredNewGraduateIds,
    setSelectedNewGraduates,
    onnEvent,
  });

  const onSubmit = useCallback(
    async (modalSelectedNewGraduates: Employee[]) => {
      setSelectedNewGraduates(
        uniqBy([...modalSelectedNewGraduates, ...selectedNewGraduates], "id")
      );
    },
    [selectedNewGraduates, setSelectedNewGraduates]
  );

  // モーダルで選択可能な候補者をフィルタリング
  const filterSelectableEmployeeOnModal = useCallback(
    (newGraduate: NewGraduateToDisplay) => {
      if (onnEvent.type === "briefing_session") {
        if (!onnEvent.canDeliverTo(newGraduate)) return false;
      } else if (onnEvent.type === "new_interview") {
        // NOTE: ゆくゆくは説明会も新面談イベントも同じ挙動になる
        if (!newGraduate.canUseNotificationFeatures()) return false;
      } else {
        const _exhaustiveCheck: never = onnEvent;
      }
      const isAlreadySelected = selectedNewGraduates.map((e) => e.id).includes(newGraduate.id);
      const isAlreadyRegistered = alreadyRegisteredNewGraduateIds.includes(newGraduate.id);
      return !isAlreadySelected && !isAlreadyRegistered;
    },
    [alreadyRegisteredNewGraduateIds, onnEvent, selectedNewGraduates]
  );

  // 「配信対象を追加」ボタンハンドラー
  const onClickAddNewGraduateToOnnEventButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateModal",
      args: {
        filter: filterSelectableEmployeeOnModal,
        onSubmit,
        openCsvUploadModal: onClickUploadCSVButton,
      },
    });
  }, [filterSelectableEmployeeOnModal, handleModal, onClickUploadCSVButton, onSubmit]);

  return { onClickAddNewGraduateToOnnEventButton };
};
