import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useAllNewcomers } from "./useAllNewcomers";

import { apiClient } from "~/libs";

export const useCreateNewGraduateWithCsv = () => {
  const { mutate: mutateAllNewcomers } = useAllNewcomers();

  const createNewGraduateWithCsv = useCallback(
    async ({ base64EncodedCsvFile }: { base64EncodedCsvFile: string }) => {
      const requestBody: APISchema["/api/employee/create-new-graduates-with-csv"]["POST"]["body"] =
        {
          base64EncodedCsvFile,
          isDryRun: false,
        };

      const response = await apiClient.post(
        "/api/employee/create-new-graduates-with-csv",
        requestBody
      );

      mutateAllNewcomers();
      return response;
    },
    [mutateAllNewcomers]
  );

  return { createNewGraduateWithCsv };
};
