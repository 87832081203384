import { Menu, MenuItem } from "@material-ui/core";
import { RecruitmentStatus, RecruitmentStatusType } from "@onn/common";
import React, { FC, MouseEvent, useContext, useState } from "react";
import styled from "styled-components";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";

import { Chip, Icon, Typography } from "~/components/uiParts";
import theme from "~/config/theme";
import { useCurrentUser } from "~/hooks/employee";

const colorEmployeeStatus: Record<RecruitmentStatusType, string> = {
  pre_entry: theme.palette.blue.light || "",
  screening: theme.palette.background.default,
  job_offer: theme.palette.secondary.light || "",
  offer_accepted: theme.palette.primary.light,
  rejected: theme.palette.grey[200],
  withdrew: theme.palette.grey[200],
};

type Props = {
  recruitmentStatusInfo: {
    id: string;
    label: string;
    type: RecruitmentStatusType;
  };
  updateRecruitmentStatus: (updatedRecruitmentStatus: RecruitmentStatus) => void;
};

/**
 * 新卒候補者用
 * 現在の選考ステータスを表示、および変更するためのチップ
 */
export const NewGraduateRecruitmentStatusChip: FC<Props> = ({
  recruitmentStatusInfo,
  updateRecruitmentStatus,
}) => {
  const { currentUser } = useCurrentUser();
  const { recruitmentStatuses } = useContext(RecruitmentStatusesContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleClickUpdateStatus = (
    event: MouseEvent<HTMLElement>,
    updatedRecruitmentStatus: RecruitmentStatus
  ) => {
    event.stopPropagation();
    updateRecruitmentStatus(updatedRecruitmentStatus);
    setAnchorEl(null);
  };

  const isAdmin = currentUser?.isAdmin() || false;

  return (
    <>
      <StyledChip
        // NOTE: recruitmentStatusが未設定の場合は内定として扱う
        $bgColor={colorEmployeeStatus[recruitmentStatusInfo.type]}
        label={recruitmentStatusInfo.label}
        color="muted"
        isOutlined
        icon={isAdmin ? <Icon icon="dropdownArrow" color="darkGrey" size="sm" /> : undefined}
        onClick={isAdmin ? openMenu : undefined}
        clickable={isAdmin}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {recruitmentStatuses.map((recruitmentStatus) => (
          <StyledMenuItem
            key={recruitmentStatus.id}
            onClick={async (e) => handleClickUpdateStatus(e, recruitmentStatus)}
          >
            <Typography variant="body2">{recruitmentStatus.label}</Typography>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

const StyledChip = styled(Chip)<{ $bgColor: string }>`
  &.MuiChip-root {
    > .MuiChip-icon {
      order: 1; // アイコンと文字列の表示順を入れ替える
      margin-left: -12px;
      margin-right: 4px;
    }
    .MuiChip-label {
      color: ${({ theme }) => theme.palette.text.primary};
      padding-left: 4px;
    }
    background-color: ${({ $bgColor }) => $bgColor};
    :hover,
    :focus {
      background-color: ${({ $bgColor }) => $bgColor};
    }
    ${({ $bgColor }) =>
      $bgColor !== "#fff" &&
      `
      border: none;
    `}
  }
`;

const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 24px;
  }
`;
