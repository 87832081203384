import { OnnEvent } from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";

import { InputCondition, OnnEventCondition } from "../../../../types/condition";
import {
  Choice,
  SelectMultipleConditionDropdown,
} from "../../../parts/dropdown-menus/SelectMultipleConditionDropdown";

type Props = {
  selectedOnnEvent: OnnEvent | undefined;
  condition: InputCondition<OnnEventCondition>;
  slotChoices: Choice<string>[];
  candidateDateChoices: Choice<string>[];
  onChangeCandidateDates: (newChoices: Choice<string>[]) => void;
  onChangeSlotIds: (newChoices: Choice<string>[]) => void;
  isLoading?: boolean;
};

/**
 * NOTE: CandidateDate は同じラベルでもIDが違う場合があるため、IDをコンマつなぎで繋いで value にする
 * (Choice の型を変える手もあったが、このためだけに変えたくなかったため)
 */
export const CANDIDATE_DATE_ID_VALUE_SEPARATOR = ",";
export const SLOT_ID_VALUE_SEPARATOR = CANDIDATE_DATE_ID_VALUE_SEPARATOR;

export const SlotOrCandidateDateSelector: FC<Props> = ({
  selectedOnnEvent,
  candidateDateChoices,
  slotChoices,
  condition,
  onChangeCandidateDates,
  onChangeSlotIds,
  isLoading,
}) => {
  const choices = useMemo(() => {
    if (!selectedOnnEvent) return [];
    switch (selectedOnnEvent.type) {
      case "briefing_session":
      case "new_interview":
        return slotChoices;
      case "normal":
        return candidateDateChoices;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = selectedOnnEvent.type;
        return _exhaustiveCheck;
    }
  }, [candidateDateChoices, selectedOnnEvent, slotChoices]);

  const selectedChoices = useMemo(() => {
    if (!selectedOnnEvent) return [];
    switch (selectedOnnEvent.type) {
      case "briefing_session":
      case "new_interview":
        return slotChoices.filter((c) =>
          c.value
            .split(CANDIDATE_DATE_ID_VALUE_SEPARATOR)
            .some((v) => condition.slotIds?.includes(v))
        );
      case "normal":
        return candidateDateChoices.filter((c) =>
          c.value
            .split(CANDIDATE_DATE_ID_VALUE_SEPARATOR)
            .some((v) => condition.candidateDateIds?.includes(v))
        );
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = selectedOnnEvent.type;
        return _exhaustiveCheck;
    }
  }, [
    selectedOnnEvent,
    slotChoices,
    candidateDateChoices,
    condition.slotIds,
    condition.candidateDateIds,
  ]);

  const onChange = useCallback(
    (newChoices: Choice<string>[]) => {
      if (!selectedOnnEvent) return;
      switch (selectedOnnEvent.type) {
        case "briefing_session":
        case "new_interview":
          onChangeSlotIds(newChoices);
          break;
        case "normal":
          onChangeCandidateDates(newChoices);
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const _exhaustiveCheck: never = selectedOnnEvent.type;
          return _exhaustiveCheck;
      }
    },
    [onChangeCandidateDates, onChangeSlotIds, selectedOnnEvent]
  );

  return (
    <SelectMultipleConditionDropdown
      placeHolder="開催日時を選択"
      key={"candidateDatesOrSlots"}
      selectedChoices={selectedChoices}
      choices={choices}
      onChange={onChange}
      isLoading={isLoading}
    />
  );
};
