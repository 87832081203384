import { useTenant } from "~/hooks/tenant";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

export const useCreateFileQuestionFilePath = () => {
  const {
    tenant: { tenantId },
  } = useTenant();

  const createFileQuestionFilePath = ({
    questionId,
    fileName,
    onnFormTaskId,
    uuidV4,
  }: {
    questionId: string;
    fileName: string;
    onnFormTaskId: string;
    uuidV4: string;
  }) => {
    return `tenants/${tenantId}/onn_tasks/${onnFormTaskId}/${questionId}/answers/${uuidV4}/${fileName}`.normalize(
      FileAPIAdapter.pathNormalizeForm
    );
  };

  return { createFileQuestionFilePath };
};
