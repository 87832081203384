import { Avatar, Badge } from "@material-ui/core";
import { convertOriginalToResizedImageUrl } from "@onn/common";
import React, { ReactNode, FC, useMemo, useState, useCallback } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { Tooltip } from "~/components/uiParts/Tooltip";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

export const ICON_SIZE = {
  extraSmall: 24,
  semiExtraSmall: 28,
  semiSmall: 32,
  small: 40,
  medium: 72,
  large: 160,
};
export type IconSize = keyof typeof ICON_SIZE;

const resizedImageWidth = ICON_SIZE.medium * 2,
  resizedImageHeight = ICON_SIZE.medium * 2;

type Props = {
  username: string;
  size: IconSize;
  profileIconImageUrl?: string;
  badgeType?: "slack" | "lineChat" | "email" | "dot";
  circular?: boolean;
  borderColor?: "primary" | "secondary" | "blue" | "grey" | "white";
  backgroundColor?: "primary" | "grey";
  hover?: boolean;
  touch?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

type BadgeWrapperTypes = Pick<Props, "badgeType"> & {
  children: ReactNode;
};

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

const BadgeWrapper: FC<BadgeWrapperTypes> = ({ badgeType, children }) => {
  const badgeTypeMap = useMemo(() => {
    return new Map<"slack" | "lineChat" | "email", ReactNode>([
      [
        "slack",
        <StyledSlackIconWrapper key="slack">
          <Icon icon="slack" color="primary" />
        </StyledSlackIconWrapper>,
      ],
      [
        "lineChat",
        <StyledIconWrapper key="lineChat">
          <Icon icon="lineChat" color="primary" size="md" />
        </StyledIconWrapper>,
      ],
      [
        "email",
        <StyledIconWrapper key="email">
          <Icon key="email" icon="email" color="primary" size="md" />
        </StyledIconWrapper>,
      ],
    ]);
  }, []);

  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={
        !badgeType || badgeType === "dot"
          ? undefined
          : {
              vertical: "bottom",
              horizontal: "right",
            }
      }
      variant={badgeType === "dot" ? "dot" : undefined}
      badgeContent={!badgeType || badgeType === "dot" ? undefined : badgeTypeMap.get(badgeType)}
    >
      {children}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)`
  /* MuiBadgeはrootとdotの要素が入れ子になっているため、子要素に対して適用という形にしている */
  & > .MuiBadge-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`;

export const UserIcon: FC<Props> = (props) => {
  const [isIconUrlError, setIconUrlError] = useState(false);

  const onAvatarIconErrorHandler: React.ReactEventHandler<HTMLDivElement> = useCallback(() => {
    if (!isIconUrlError) setIconUrlError(true);
  }, [isIconUrlError]);

  const iconUrl = useMemo(() => {
    let urlStr: string;
    // GCS でない URL
    if (!props.profileIconImageUrl) {
      return "";
    }
    if (/data:image\//i.test(props.profileIconImageUrl)) {
      // dataUrl型の場合は、それをそのままurlとする
      return props.profileIconImageUrl;
    }

    // GCS の URL
    if (/(http(s?)):\/\//i.test(props.profileIconImageUrl)) {
      // https:// から始まるpropsを受け取った場合は、それをそのままurlとする
      urlStr = props.profileIconImageUrl;
    } else {
      // バケットの相対パスを受け取った場合は、絶対パスをurlに入れる
      urlStr = fileAPIAdapter.getFileUrl(props.profileIconImageUrl);
    }

    // NOTE:
    // 現時点で Slack から取得したアイコン画像を使用している場合は MIME type が なし or application / octet - stream になる場合がほとんど。
    // 現在使用している https://extensions.dev/extensions/firebase/storage-resize-images ではこれらの画像はリサイズの対象にならないため、
    // Slack から取得されたアイコン画像はオリジナル画像を使う必要がある。
    // その際、Slack から取得されたアイコン画像が必ず持つであろう文字列の `_192.`  or `-192.` が urlStr に含まれているかでアイコンの保存元を
    // 特定している。Line 由来の画像は Line の CDN から直でとって表示しているが、現在は妥協している。 -- Taichi Masuyama 2023.02.16
    const isIconOriginallyRetrievedFromSlack = urlStr.includes("192.");

    const shouldUseResizedImageUrl = !isIconUrlError && !isIconOriginallyRetrievedFromSlack;
    if (shouldUseResizedImageUrl) {
      const url = new URL(urlStr);
      const resizedImageUrl = convertOriginalToResizedImageUrl(
        url,
        resizedImageWidth,
        resizedImageHeight
      );
      urlStr = resizedImageUrl.toString();
    }

    return urlStr;
  }, [isIconUrlError, props.profileIconImageUrl]);

  return (
    <Tooltip
      title={props.username}
      arrow
      disableHoverListener={!props.hover}
      disableTouchListener={!props.touch}
    >
      {props.badgeType ? (
        <BadgeWrapper badgeType={props.badgeType}>
          <StyledAvatarIcon
            variant={props.circular ? "circular" : "rounded"}
            src={iconUrl}
            size={props.size}
            imgProps={{ loading: "lazy" }}
            onClick={props.onClick}
            onError={onAvatarIconErrorHandler}
            $borderColor={props.borderColor}
            $backgroundColor={props.backgroundColor}
          />
        </BadgeWrapper>
      ) : (
        <StyledInlineBlock>
          <StyledAvatarIcon
            variant={props.circular ? "circular" : "rounded"}
            src={iconUrl}
            size={props.size}
            imgProps={{ loading: "lazy" }}
            onClick={props.onClick}
            onError={onAvatarIconErrorHandler}
            $borderColor={props.borderColor}
            $backgroundColor={props.backgroundColor}
          />
        </StyledInlineBlock>
      )}
    </Tooltip>
  );
};

const StyledSlackIconWrapper = styled.div`
  background-color: white;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 4px;
  svg {
    height: 12px;
    width: 12px;
  }
`;

const StyledIconWrapper = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

const StyledAvatarIcon = styled(Avatar)<
  Pick<Props, "size"> & { src: string; $borderColor?: string; $backgroundColor?: string }
>`
  &.MuiAvatar-root {
    height: ${(props) => ICON_SIZE[props.size]}px;
    width: ${(props) => ICON_SIZE[props.size]}px;
    ${(props) => props.onClick && `cursor: pointer;`}
  }

  &.MuiAvatar-rounded {
    background-color: ${(props) =>
      props.$backgroundColor === "grey"
        ? props.theme.palette.grey[200]
        : props.theme.palette.primary.main};

    ${(props) =>
      props.$borderColor === "primary" && `border: 2px solid ${props.theme.palette.primary.main};`}
    ${(props) =>
      props.$borderColor === "secondary" &&
      `border: 2px solid ${props.theme.palette.secondary.main};`}
    ${(props) =>
      props.$borderColor === "blue" && `border: 2px solid ${props.theme.palette.blue.main};`}
    ${(props) =>
      props.$borderColor === "grey" && `border: 2px solid ${props.theme.palette.grey[400]};`}
    ${(props) =>
      props.$borderColor === "white" && `border: 1px solid ${props.theme.palette.common.white};`}
  }

  &.MuiAvatar-circular {
    background-color: white;
    color: ${(props) => props.theme.palette.grey[200]};

    ${(props) => !props.src && `border: 2px dashed ${props.theme.palette.grey[200]} !important;`}
    ${(props) =>
      props.$borderColor === "primary" && `border: 2px solid ${props.theme.palette.primary.main};`}
    ${(props) =>
      props.$borderColor === "secondary" &&
      `border: 2px solid ${props.theme.palette.secondary.main};`}
    ${(props) =>
      props.$borderColor === "blue" && `border: 2px solid ${props.theme.palette.blue.main};`}
    ${(props) =>
      props.$borderColor === "grey" && `border: 2px solid ${props.theme.palette.grey[400]};`}
    ${(props) =>
      props.$borderColor === "white" && `border: 1px solid ${props.theme.palette.common.white};`}
  }
`;

const StyledInlineBlock = styled.div`
  display: inline-block;
`;
