import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC, memo } from "react";
import styled from "styled-components";

import { NewGraduateUseIcon } from "../NewGraduateUseIcon";

import { Paper, Loading, Typography } from "~/components/uiParts";

import { useEmployeeTags } from "~/hooks/employeeTag";

type Props = {
  newGraduate: NewGraduateToDisplay;
};

export const NewGraduateSummaryPaper: FC<Props> = memo(function EmployeeSummary({ newGraduate }) {
  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();

  if (isLoadingEmployeeTagsData) {
    return (
      <Box height="116px">
        <Loading size="small" />
      </Box>
    );
  } else {
    return (
      <StyledPaper>
        <Stack spacing="16px">
          <NewGraduateUseIcon newGraduate={newGraduate} shouldDisplayStatus={false} />
          <StyledGrayStack spacing="16px">
            <Stack spacing="4px">
              <Typography variant="caption" color="textPrimary">
                タグ
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {newGraduate.employeeTagIds.length === 0
                  ? "未設定"
                  : newGraduate.employeeTagIds
                      .map((tagId) => {
                        const tag = employeeTagsData?.employeeTags?.find((t) => t.id === tagId);
                        return tag ? tag.name : null;
                      })
                      .join(", ")}
              </Typography>
            </Stack>

            {/* TODO: 電話番号を表示する */}
            {/* <Stack spacing="4px">
              <Typography variant="caption" color="textPrimary">
                電話番号
              </Typography>
              <Typography variant="caption" color="textSecondary">
                000-0000-0000
              </Typography>
            </Stack> */}
          </StyledGrayStack>
        </Stack>
      </StyledPaper>
    );
  }
});

const StyledPaper = styled(Paper)`
  width: 400px;
  padding: 16px;
`;

const StyledGrayStack = styled(Stack)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  border-radius: 8px;
  padding: 16px;
`;
