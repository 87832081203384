import React, { FC, useContext, useEffect } from "react";

import { SpaceContext } from "./SpaceProvider";

import { useCurrentUser } from "~/hooks/employee";
import { getStorageKey } from "~/libs/spaceIdFromStorage";
import { getStorageAccessors } from "~/util";

/**
 * ローカルストレージで管理しているspaceIdとSpaceProviderのcurrentSpaceを同期する
 */
export const AsyncLocalStorageSpaceId = () => {
  const { currentUser } = useCurrentUser();

  // 候補者の場合はスペース機能を使用しないので即時レンダリングを行う
  if (currentUser.assignedAsNewcomer) {
    return <></>;
  }

  return <AsyncLocalStorageSpaceIdCore />;
};

const { retrieveValue } = getStorageAccessors(localStorage);

const AsyncLocalStorageSpaceIdCore: FC = () => {
  const { currentSpace, switchSpace, onSwitchSpaceDirectlyChangeLS } = useContext(SpaceContext);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === getStorageKey(currentUser.tenantId)) {
        const spaceIdInStorage = (() => {
          try {
            // NOTE: localStorageのspaceIdはダブルクォーテーションで囲まれているため、それを取り除く目的でretrieveValueを使用
            return retrieveValue<string>(event.key);
          } catch (_e) {
            return null;
          }
        })();
        spaceIdInStorage !== null && onSwitchSpaceDirectlyChangeLS(spaceIdInStorage);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [currentSpace, currentUser.tenantId, onSwitchSpaceDirectlyChangeLS, switchSpace]);

  return <></>;
};
