import { NewGraduateToDisplay } from "@onn/common";

import { useCallback, useState } from "react";

import { Condition } from "../_share_InvitationQR/type";

import { useCurrentUser, useUpdateIndividualInvitationSetting } from "~/hooks/employee";
import { useAddTagToEmployee } from "~/hooks/employee/useAddTagToEmployee";
import { useRemoveTagFromEmployee } from "~/hooks/employee/useRemoveTagFromEmployee";
import { useUpdateNewGraduateRecruitmentStatus } from "~/hooks/employee/useUpdateNewGraduateRecruitmentStatus";
import { OnUpdateRecruitmentStatus } from "~/hooks/employee/useUpdateNewGraduateRecruitmentStatus/useUpdateNewGraduateRecruitmentStatus";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * 「条件を付与」ボタンが押されたときの処理
 */
export const useOnAddConditions = ({
  toShowQRMode,
  selectedCondition,
  newGraduate,
  individualInvitationSettingId,
  onUpdateRecruitmentStatus,
}: {
  toShowQRMode: () => void;
  selectedCondition: Condition;
  newGraduate: NewGraduateToDisplay;
  individualInvitationSettingId: string;
  onUpdateRecruitmentStatus?: OnUpdateRecruitmentStatus;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  const [isUpdating, setIsUpdating] = useState(false);

  const { updateNewGraduateRecruitmentStatus } = useUpdateNewGraduateRecruitmentStatus({
    onUpdateRecruitmentStatus,
  });
  const { updateEmployeeTagAttachment } = useUpdateEmployeeTagAttachment(newGraduate.id);
  const { updateIndividualInvitationSetting } = useUpdateIndividualInvitationSetting();

  const onAddConditions = async () => {
    try {
      setIsUpdating(true);
      await EmployeeUseCase.update(newGraduate.id, {
        selectableAuthenticationFlowTypes: selectedCondition.authenticationFlowTypes,
      });
      await updateNewGraduateRecruitmentStatus(newGraduate, selectedCondition.recruitmentStatusId);
      await updateEmployeeTagAttachment({
        newGraduate,
        selectedTagIds: selectedCondition.tagIds,
      });
      await updateIndividualInvitationSetting({
        employeeId: newGraduate.id,
        individualInvitationSettingId,
        onnEventIds: selectedCondition.onnEventIds,
        onnTaskIds: selectedCondition.onnTaskIds,
      });
      toShowQRMode();
    } catch (_error) {
      enqueueSnackbar("招待QRへの条件付与に失敗しました。管理者までお問い合わせください。", {
        variant: "error",
      });
      captureException({
        error: new Error("共通招待QRへの条件付与に失敗しました。"),
        tags: { type: "useGenerateRegistrationInvitationLinkUrl:handleSubmit" },
        extras: {
          employeeId: currentUser.id,
          spaceId: currentSpace.id,
          selectedCondition,
        },
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return { onAddConditions, isUpdating };
};

// タグのつけ外しを行うhooks
// 処理が少し複雑なので、hooksに切り出しています
const useUpdateEmployeeTagAttachment = (employeeId: string) => {
  const { trigger: addTagToEmployee } = useAddTagToEmployee({
    employeeID: employeeId,
  });
  const { removeTagFromEmployee } = useRemoveTagFromEmployee();

  const updateEmployeeTagAttachment = useCallback(
    async ({
      newGraduate,
      selectedTagIds,
    }: {
      newGraduate: NewGraduateToDisplay;
      selectedTagIds: string[];
    }) => {
      const targetTagIds = new Set([...selectedTagIds, ...newGraduate.employeeTagIds]);

      const tagsToAdd = [];
      const tagsToRemove = [];
      for (const tagId of targetTagIds) {
        const isAlreadyAdded = newGraduate.employeeTagIds.includes(tagId);
        const isSelected = selectedTagIds.includes(tagId);

        if (isAlreadyAdded && !isSelected) {
          tagsToRemove.push(tagId);
        } else if (!isAlreadyAdded && isSelected) {
          tagsToAdd.push(tagId);
        }
      }

      // NOTE: 既存のhooksを使うために、一旦ループを分けている。パフォーマンスが気になる場合は改修する。
      // （複数削除のエンドポイントが実装時点で存在しなかった）
      for (const tagId of tagsToRemove) {
        await removeTagFromEmployee(tagId, newGraduate.id);
      }
      await addTagToEmployee({ newTagIDs: tagsToAdd });
    },
    [addTagToEmployee, removeTagFromEmployee]
  );

  return { updateEmployeeTagAttachment };
};
