import liff from "@line/liff";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { RegisterIndividual } from "../RegisterIndividual";
import { RegistrationIndividualDetail } from "../Registration/RegistrationIndividualDetail";

import { ThanksToApplicationCore } from "~/components/domains/employees/ThanksToApplication";
import { Loading } from "~/components/uiParts";
import { useLineAccessToken } from "~/hooks/context";
import { useRegistrationInfoByInvitationToken } from "~/hooks/employee/useRegistrationInfoByInvitationToken";
import { useRedirectAfterRegistration } from "~/hooks/portal/redirect";
import { useSnackbar } from "~/hooks/shared";
import { useTenantLineLoginChannelInfo } from "~/hooks/tenant/useTenantLineLoginChannelInfo";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";
import { NotFound } from "~/pages/NotFound";
import { captureException } from "~/util/loggerUtil";

export const CommonWrapperForAccountPage: FC<{
  children: typeof RegistrationIndividualDetail | typeof RegisterIndividual;
}> = ({ children: Children }) => {
  const { id: invitationToken } = useParams<"id">();

  const { guardAndGetLineAccessTokenFromLiff } = useLineAccessToken();

  const {
    data: registrationInfoByInvitationToken,
    isLoading: isLoadingRegistrationInfoByInvitationToken,
  } = useRegistrationInfoByInvitationToken(
    invitationToken || "",
    liff.isInClient() ? guardAndGetLineAccessTokenFromLiff() : ""
  );

  const tenantId = getTenantIdFromDomain();
  const { data: dataLineLoginChannelInfo, isLoading: isLoadingLineLoginChannelInfo } =
    useTenantLineLoginChannelInfo(tenantId || undefined);

  const { enqueueSnackbar } = useSnackbar();
  const { redirectAfterRegistration } = useRedirectAfterRegistration();

  useEffect(() => {
    const employee = registrationInfoByInvitationToken?.employee;
    if (employee && employee.isRegistered()) {
      const shouldNavigateToLineQR = !employee.isFollowedLineOfficialAccount;
      if (!shouldNavigateToLineQR) {
        enqueueSnackbar("すでにアカウントが登録されています", { variant: "info" });
      }

      redirectAfterRegistration(employee);
    }
  }, [enqueueSnackbar, redirectAfterRegistration, registrationInfoByInvitationToken?.employee]);

  const isLoading = isLoadingRegistrationInfoByInvitationToken || isLoadingLineLoginChannelInfo;
  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!invitationToken || !registrationInfoByInvitationToken || !dataLineLoginChannelInfo) {
    captureException({
      error: new Error("アカウント登録ページの情報が取得できず、NotFoundが開かれました"),
      tags: { type: "CommonWrapperForAccountPage" },
      extras: {
        invitationToken,
        registrationInfoByInvitationToken,
        dataLineLoginChannelInfo,
      },
    });

    return <NotFound />;
  }

  // NOTE: 新卒の場合、不採用・辞退の場合は、登録させないように「お祈り画面」を表示する
  if (registrationInfoByInvitationToken.employee instanceof NewGraduateToDisplay) {
    if (registrationInfoByInvitationToken.employee.isRejectedOrWithdrew()) {
      return (
        <ThanksToApplicationCore tenantId={registrationInfoByInvitationToken.employee.tenantId} />
      );
    }
  }

  return (
    <Children
      registrationInfoByInvitationToken={registrationInfoByInvitationToken}
      liffAccessToken={liff.isInClient() ? guardAndGetLineAccessTokenFromLiff() : undefined}
      dataLineLoginChannelInfo={dataLineLoginChannelInfo}
    />
  );
};
