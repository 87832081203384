import { APISchemaNewGraduates, Employee, NewGraduateToDisplay } from "@onn/common";
import { useCallback } from "react";
import useSWRInfinite, { SWRInfiniteKeyLoader } from "swr/infinite";

import { AnyValidCondition } from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";

import { apiClient } from "~/libs";

export const useSearchNewGraduatesPerPage = ({
  conditions,
  logicType,
  keywordsString,
  onFetch,
}: SearchArgs & { onFetch?: () => void | Promise<void> }) => {
  const keyLoader: SWRInfiniteKeyLoader<Data, Arguments> = useCallback(
    (index, previousPageData) => {
      if (previousPageData && previousPageData.nextCursor == null) {
        // NOTE: これ以上データがない場合は null を返す
        return null;
      }

      // NOTE: 1 ページ目の場合は previousPageData がない
      if (index === 0 || previousPageData == null) {
        return {
          endpoint: "/api/new-graduates/search",
          cursor: null,
          conditions,
          logicType,
          keywordsString,
        } as const;
      }

      return {
        endpoint: "/api/new-graduates/search",
        cursor: previousPageData.nextCursor,
        conditions,
        logicType,
        keywordsString,
      } as const;
    },
    [conditions, keywordsString, logicType]
  );

  const swrRes = useSWRInfinite<Data, Error, SWRInfiniteKeyLoader<Data, Arguments>>(
    keyLoader,
    async ({ endpoint, cursor, keywordsString, conditions, logicType }) => {
      const result = await apiClient.post(endpoint, {
        cursor,
        type: logicType,
        conditions,
        keywordsString,
      });

      onFetch?.();

      return {
        ...result,
        newGraduates: result.newGraduates.map(
          (n) =>
            new NewGraduateToDisplay(
              Employee.castToNewGraduate(Employee.plainToInstance(n)),
              n.employeeTagIds,
              {
                id: n.recruitmentStatusId,
                label: n.recruitmentStatusLabel,
                type: n.recruitmentStatusType,
              },
              n.predictionId
            )
        ),
      };
    },
    { keepPreviousData: true }
  );

  /**
   * ページングの結果のうち、置き換えたい候補者を渡してミューテーションする
   */
  const mutateWithOptimisticUpdate = useCallback(
    (
      newGraduate: NewGraduateToDisplay,
      waitPromise: Promise<unknown> = new Promise((r) => r(void 0))
    ) => {
      const generateNewData = (prev: Data[] | undefined): Data[] | undefined => {
        if (!prev) {
          return prev;
        }

        const next = [...prev];
        const targetPageIndex = next.findIndex((page) =>
          page.newGraduates.some((n) => n.id === newGraduate.id)
        );
        if (targetPageIndex === -1) {
          return prev;
        }
        const targetPage = next[targetPageIndex];
        if (!targetPage) {
          return prev;
        }
        const targetIndex = targetPage.newGraduates.findIndex((n) => n.id === newGraduate.id);
        if (targetIndex === -1) {
          return prev;
        }

        const newPage = {
          ...targetPage,
          newGraduates: [
            ...targetPage.newGraduates.slice(0, targetIndex),
            newGraduate,
            ...targetPage.newGraduates.slice(targetIndex + 1),
          ],
        };

        next[targetPageIndex] = newPage;
        return next;
      };

      swrRes.mutate(
        async (data) => {
          await waitPromise;
          return generateNewData(data);
        },
        {
          optimisticData: swrRes.data
            ? (prev) => generateNewData(prev) ?? [] // NOTE: optimisticData はコールバックの返り値として Data[] しか受け付けないので妥協 (swrRes.data に依存させることで、実質的に data が undefined になるケースを除外できている)
            : undefined,
          rollbackOnError: true,
        }
      );
    },
    [swrRes]
  );

  const loadNextPage = useCallback(() => swrRes.setSize((s) => s + 1), [swrRes]);

  return Object.assign(swrRes, {
    mutateWithOptimisticUpdate,
    loadNextPage,
  });
};

type SearchArgs = {
  conditions: AnyValidCondition[];
  logicType: LogicType;
  keywordsString: string;
};

type Data = Omit<
  APISchemaNewGraduates["/api/new-graduates/search"]["POST"]["response"],
  "newGraduates"
> & {
  newGraduates: NewGraduateToDisplay[];
};
type Arguments =
  | ({
      endpoint: "/api/new-graduates/search";
      cursor: string | null;
    } & SearchArgs)
  | null;

export type SearchNewGraduatesPerPageData = ReturnType<typeof useSearchNewGraduatesPerPage>["data"];
