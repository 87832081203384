import { throttle } from "lodash";
import { useEffect, useMemo } from "react";

import { useFilterAndSortContactRooms } from "./filter/useFilterAndSortContactRooms";

import { useLatestContactMessage } from "~/hooks/contactMessage";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useContactRoomsV2 } from "~/hooks/contactRoom";
import { useCurrentUser } from "~/hooks/employee";

const useShowContactRooms = () => {
  const { currentUser } = useCurrentUser();

  const {
    // 自分自身が対象となっているルームは取得しない
    // 入社者のユーザーでログインし、他の入社者のコンタクトルームの閲覧権限を有する場合に表示されてしまうことを防ぐ
    contactRoomsWithoutCurrentUser: _allContactRooms,
    isLoadingContactRooms: isLoadingAllContactRooms,
  } = useContactContext();

  const allContactRooms = useMemo(() => _allContactRooms, [_allContactRooms]);
  const { data: limitContactRooms, isLoading: isLoadingContactRooms } = useContactRoomsV2({
    tenantId: currentUser.tenantId,
    isIncludeCurrentUserContactRoom: false,
    limit: 100,
  });

  // 初回表示を速くするために、最初は100件分のコンタクトルームを表示する。全件のコンタクトルームが表示された後は切り替える。
  const showContactRooms = useMemo(() => {
    if (!isLoadingAllContactRooms) return allContactRooms;
    if (limitContactRooms) return limitContactRooms;
    return [];
  }, [allContactRooms, limitContactRooms, isLoadingAllContactRooms]);

  return { showContactRooms, isLoadingContactRooms, isLoadingAllContactRooms };
};

const useShowLatestContactMessages = () => {
  const { currentUser } = useCurrentUser();

  const {
    data: allLatestContactMessages = [],
    isLoading: isLoadingAllLatestMessages,
    mutate: latestMessageMutate,
  } = useLatestContactMessage({
    tenantId: currentUser.tenantId,
  });

  const { data: limitLatestContactMessages = [], isLoading: isLoadingLatestMessages } =
    useLatestContactMessage({
      tenantId: currentUser.tenantId,
      limit: 100,
    });

  // 初回表示を速くするために、最初は100件分のコンタクトルームを表示する。全件のコンタクトルームが表示された後は切り替える。
  const showLatestContactMessages = useMemo(() => {
    if (!isLoadingAllLatestMessages) return allLatestContactMessages;
    if (limitLatestContactMessages) return limitLatestContactMessages;
    return [];
  }, [allLatestContactMessages, isLoadingAllLatestMessages, limitLatestContactMessages]);

  return { isLoadingLatestMessages, showLatestContactMessages, latestMessageMutate };
};

export const useContactRoomListV2 = ({
  selectedRecruitmentStatusIds,
  isDisplayOnlyMyCandidates,
}: {
  selectedRecruitmentStatusIds: string[];
  isDisplayOnlyMyCandidates: boolean;
}) => {
  const { currentUser } = useCurrentUser();
  const { lastUpdatedAt } = useContactContext();

  const { showContactRooms, isLoadingContactRooms, isLoadingAllContactRooms } =
    useShowContactRooms();

  const { contactRoomUnreadCountMap, isLoadingUnreadMessageCountList } = useContactContext();

  const { showLatestContactMessages, isLoadingLatestMessages, latestMessageMutate } =
    useShowLatestContactMessages();

  // NOTE:throttleを使用することで、一気にメッセージの更新が行われても、CFへのリクエストが一気に行われることを防ぐ
  // 特にsystemMessageの更新は、一気に行われることが想定されるため、throttleを使用している
  const throttleLatestMessageMutate = useMemo(
    () =>
      throttle(() => {
        latestMessageMutate();
      }, 5000), // 負荷が高めなので、一旦5秒に設定
    [latestMessageMutate]
  );

  useEffect(() => {
    throttleLatestMessageMutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedAt]);

  const { currentContactRooms, searchValue, handleChangeSearchValue } =
    useFilterAndSortContactRooms({
      contactRooms: showContactRooms,
      currentUser,
      selectedRecruitmentStatusIds,
      isDisplayOnlyMyCandidates,
      latestContactMessages: showLatestContactMessages,
    });

  return {
    currentContactRooms,
    searchValue,
    handleChangeSearchValue,

    /** Map<コンタクトルームID, 未読数> */
    contactRoomUnreadCountMap,
    isLoadingUnreadMessageCountList,
    isLoadingContactRooms,
    latestContactMessages: showLatestContactMessages,
    isLoadingLatestMessages,
    isLoadingAllContactRooms,
  };
};
