import { Box } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { ComponentProps, FC } from "react";

import styled from "styled-components";

import { NewGraduateStatusChip } from "../../NewGraduateStatusChip";

import { ChangeEmployeePredictionSelector } from "~/components/domains/employeePrediction/ChangeEmployeePredictionSelector";
import { ChangeRecruitmentStatusSelector } from "~/components/domains/recruitmentStatus/ChangeRecruitmentStatusSelector";
import { Typography } from "~/components/uiParts";
import { OnEditEmployeePredictionRelation } from "~/hooks/employeePrediction/useEditEmployeePredictionRelations";

type Props = {
  newGraduate: NewGraduateToDisplay;
  onUpdateRecruitmentStatus?: ComponentProps<
    typeof NewGraduateStatusChip
  >["onUpdateRecruitmentStatus"];
  onEditEmployeePredictionRelation?: OnEditEmployeePredictionRelation;
};

export const NewGraduateRecruitmentStatusAndPrediction: FC<Props> = ({
  newGraduate,
  onUpdateRecruitmentStatus,
  onEditEmployeePredictionRelation,
}) => {
  const scenarioName = "共通";
  return (
    <Root>
      <ChangeSelectorWrapper
        title={scenarioName}
        selectorComponent={
          <ChangeRecruitmentStatusSelector
            newGraduate={newGraduate}
            onUpdateRecruitmentStatus={onUpdateRecruitmentStatus}
          />
        }
      />
      <ChangeSelectorWrapper
        title="ヨミ"
        selectorComponent={
          <ChangeEmployeePredictionSelector
            newGraduate={newGraduate}
            onEditEmployeePredictionRelation={onEditEmployeePredictionRelation}
          />
        }
      />
    </Root>
  );
};

const ChangeSelectorWrapper: FC<{
  title: string;
  selectorComponent: React.ReactElement;
}> = ({ title, selectorComponent }) => {
  return (
    <Box
      width={"120px"}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="overline" color="textPrimary">
        {title}
      </Typography>
      {selectorComponent}
    </Box>
  );
};

const Root = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
