import { NewInterviewEvent } from "@onn/common";
import { OnnEventAnswerForDisplay } from "@onn/common/domain/OnnEvent/OnnEventAnswer";
import React, { FC } from "react";
import { GridCellRenderer, Index } from "react-virtualized";

import { BodyCell } from "./BodyCell";
import { HeaderCell } from "./HeaderCell";
import { OnClickChip } from "./cells/StatusCell";
import { BODY_CELL_HEIGHT_PX, HEADER_CELL_HEIGHT_PX, columnTypes, widthPxMap } from "./utils";

import { AttendanceStatusMenu } from "~/components/domains/onnEvents/AttendanceStatusMenu";
import { useAttendanceStatusMenu } from "~/components/domains/onnEvents/AttendanceStatusMenu/useAttendanceStatusMenu";
import { VirtualizedMultiGrid } from "~/components/uiParts/VirtualizedMultiGrid";

const useMultiGridOnPaper = ({
  onnEventAnswersForDisplay,
  onnEvent,
  onClickStatusChip,
}: {
  onnEventAnswersForDisplay: OnnEventAnswerForDisplay[];
  onnEvent: NewInterviewEvent;
  onClickStatusChip: OnClickChip;
}) => {
  const headerRowCount = 1;
  const rowCount = headerRowCount + onnEventAnswersForDisplay.length;
  const columnCount = columnTypes.length;

  // MultiGridが各列の幅を算出するための関数
  const columnWidth = (params: Index) => {
    const columnType = columnTypes[params.index];
    if (columnType) return widthPxMap[columnType];

    return widthPxMap.slotTime;
  };

  const cellRenderer: GridCellRenderer = ({ style, rowIndex, columnIndex }) => {
    const columnType = columnTypes[columnIndex];
    if (!columnType) return;
    const isLastColumn = columnIndex === columnTypes.length - 1;
    const isShowRightBorder = !isLastColumn;

    const key = String(rowIndex) + String(columnIndex);

    if (rowIndex === 0) {
      return (
        <HeaderCell
          key={key}
          style={style}
          columnType={columnType}
          numberOfAdjusted={onnEventAnswersForDisplay.filter((v) => v.isAlreadyAnswered()).length}
          numberOfIsRejectedOrWithdrew={
            onnEventAnswersForDisplay.filter((v) => v.newGraduateToDisplay.isRejectedOrWithdrew())
              .length
          }
          isShowRightBorder={isShowRightBorder}
        />
      );
    }
    const onnEventAnswerForDisplay = onnEventAnswersForDisplay[rowIndex - 1];
    if (!onnEventAnswerForDisplay) return;

    return (
      <BodyCell
        key={key}
        onnEventAnswerForDisplay={onnEventAnswerForDisplay}
        style={style}
        columnType={columnType}
        isShowRightBorder={isShowRightBorder}
        onnEvent={onnEvent}
        onClickStatusChip={onClickStatusChip}
      />
    );
  };

  return {
    rowCount,
    columnCount,
    columnWidth,
    cellRenderer,
    enableFixedColumnScroll: true,
  };
};

type Props = {
  onnEventAnswersForDisplay: OnnEventAnswerForDisplay[];
  isLoading: boolean;
  onnEvent: NewInterviewEvent;
};

export const OnnEventAnswersMultiGrid: FC<Props> = ({
  onnEventAnswersForDisplay,
  isLoading,
  onnEvent,
}) => {
  const {
    anchorEl,
    closeMenu: closeAnswerStatusMenu,
    onClickMenuItem: onClickAnswerStatusMenuItem,
    onClickChip: onClickStatusChip,
    currentStatus,
    currentEmployeeId,
  } = useAttendanceStatusMenu({ onnEvent });

  const { cellRenderer, columnWidth, columnCount, rowCount } = useMultiGridOnPaper({
    onnEventAnswersForDisplay,
    onnEvent,
    onClickStatusChip,
  });

  return (
    <>
      <VirtualizedMultiGrid
        cellRenderer={cellRenderer}
        columnWidth={columnWidth}
        columnCount={columnCount}
        rowCount={rowCount}
        headerCellHeight={HEADER_CELL_HEIGHT_PX}
        isLoading={isLoading}
        emptyText="面談予定がありません"
        bodyCellHeight={BODY_CELL_HEIGHT_PX}
        paddingSize="sm"
      />
      {currentStatus && currentEmployeeId && (
        <AttendanceStatusMenu
          anchorEl={anchorEl}
          onClose={closeAnswerStatusMenu}
          onClickMenuItem={onClickAnswerStatusMenuItem}
          currentStatus={currentStatus}
          employeeId={currentEmployeeId}
        />
      )}
    </>
  );
};
