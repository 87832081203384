import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { ForNewInterviewAndBriefingSessionPage } from "./forNewInterviewAndBriegingSession/page";
import { ForNormalEventPage } from "./forNormalEvent/page";

import { EventTypeDisplaySwitcher } from "~/components/domains/events/EventTypeDisplaySwitcher";
import { Loading } from "~/components/uiParts";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant/useTenant";

import { NotFound } from "~/pages/NotFound";

export const Page: React.FC = () => {
  const params = useParams();
  const onnEventId = params.id;
  const { tenant } = useTenant();
  const { switchSpaceTemporary } = useCurrentSpace();
  const {
    data: onnEvent,
    isLoading: isLoadingOnnEvent,
    mutate: mutateOnnEvent,
  } = useOnnEvent({
    tenantId: tenant.tenantId,
    onnEventId,
  });

  const {
    data: onnEventAnswers,
    isLoading: isLoadingOnnEventAnswers,
    mutate: mutateOnnEventAnswers,
  } = useOnnEventAnswers({
    onnEventId,
  });

  useEffect(() => {
    if (!onnEvent) return;
    switchSpaceTemporary(onnEvent.spaceId);
  }, [switchSpaceTemporary, onnEvent]);

  const isLoading = isLoadingOnnEvent || isLoadingOnnEventAnswers;

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }
  if (!onnEventId || !onnEvent || !onnEventAnswers) {
    return <NotFound />;
  }
  return (
    <EventTypeDisplaySwitcher
      onnEvent={onnEvent}
      renderForNormal={(onnEvent) => (
        <ForNormalEventPage
          onnEvent={onnEvent}
          onnEventAnswers={onnEventAnswers}
          mutateOnnEvent={mutateOnnEvent}
          mutateOnnEventAnswers={mutateOnnEventAnswers}
        />
      )}
      renderForNewInterview={(onnEvent) => (
        <ForNewInterviewAndBriefingSessionPage
          onnEvent={onnEvent}
          onnEventAnswers={onnEventAnswers}
        />
      )}
      renderForBriefingSession={(onnEvent) => (
        <ForNewInterviewAndBriefingSessionPage
          onnEvent={onnEvent}
          onnEventAnswers={onnEventAnswers}
        />
      )}
    />
  );
};
