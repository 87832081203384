import { OnnEventAnswerForDisplay, OnnEventPlace } from "@onn/common";
import { format } from "date-fns";
import { useCallback } from "react";

export const useGenerateCsvData = ({ onnEventPlaces }: { onnEventPlaces: OnnEventPlace[] }) => {
  const { generateBodyRow } = useGenerateBodyRow({ onnEventPlaces });
  const { generateHeaderRow } = useGenerateHeaderRow();
  const generateCsvData = useCallback(
    ({ onnEventAnswersForDisplay }: { onnEventAnswersForDisplay: OnnEventAnswerForDisplay[] }) => {
      const csvHeaderRow = generateHeaderRow({ onnEventAnswersForDisplay });
      const csvBodyRows = onnEventAnswersForDisplay.map((answer) => {
        return generateBodyRow(answer);
      });

      return [csvHeaderRow, ...csvBodyRows];
    },
    [generateBodyRow, generateHeaderRow]
  );

  return { generateCsvData };
};

const useGenerateHeaderRow = () => {
  const generateHeaderRow = useCallback(
    ({ onnEventAnswersForDisplay }: { onnEventAnswersForDisplay: OnnEventAnswerForDisplay[] }) => {
      const adjustedAnswerCount = onnEventAnswersForDisplay.filter((data) =>
        data.isAlreadyAnswered()
      ).length;
      const rejectedOrWithdrawnEmployeeCount = onnEventAnswersForDisplay.filter((data) =>
        data.newGraduateToDisplay.isRejectedOrWithdrew()
      ).length;

      const HeaderRow = [
        `回答済み：${adjustedAnswerCount} / 辞退・不合格：${rejectedOrWithdrawnEmployeeCount}`,
        "開催日時",
        "ステータス",
        "選考担当",
        "開催方法",
        "概要",
        "（オンラインのみ）URL",
        "（オフラインのみ）会場名",
        "（オフラインのみ）会場郵便番号",
        "（オフラインのみ）会場住所",
      ];
      return HeaderRow;
    },
    []
  );

  return { generateHeaderRow };
};

const useGenerateBodyRow = ({ onnEventPlaces }: { onnEventPlaces: OnnEventPlace[] }) => {
  const generateBodyRow = useCallback(
    (answer: OnnEventAnswerForDisplay) => {
      const isOnline = answer.selectedOnnEventSlotDate?.eventType === "online";
      const isOffline = answer.selectedOnnEventSlotDate?.eventType === "offline";

      const slotDate = answer.selectedOnnEventSlotDate
        ? `${format(answer.selectedOnnEventSlotDate.from, "yyyy/MM/dd HH:mm")}〜${format(
            answer.selectedOnnEventSlotDate.until,
            "HH:mm"
          )}`
        : "";

      const eventType = isOnline ? "オンライン" : isOffline ? "オフライン・現地" : "未設定";

      const description = answer.selectedOnnEventSlotDate
        ? answer.selectedOnnEventSlotDate.description
        : "";
      const onlineUrl = isOnline ? answer.selectedOnnEventSlotDate?.url || "" : "";
      const eventPlaceName = isOffline
        ? onnEventPlaces
            .find((place) => place.id === answer.selectedOnnEventSlotDate?.eventPlaceId)
            ?.getFullAddress() || ""
        : "";
      const eventAddressPostCode = isOffline
        ? answer.selectedOnnEventSlotDate?.eventAddressPostCode || ""
        : "";
      const eventAddressText = isOffline
        ? answer.selectedOnnEventSlotDate?.eventAddressText || ""
        : "";

      return [
        answer.newGraduateToDisplay.getName(),
        slotDate,
        OnnEventAnswerForDisplay.getLabelFromStatus(answer.getStatusForDisplay()),
        answer.assigneeInfo?.name || "",
        eventType,
        description,
        onlineUrl,
        eventPlaceName,
        eventAddressPostCode,
        eventAddressText,
      ];
    },
    [onnEventPlaces]
  );

  return { generateBodyRow };
};
