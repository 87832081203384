import { Box } from "@material-ui/core";
import { NewGraduateToDisplay, RecruitmentStatus } from "@onn/common";
import React, { useCallback, FC } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";

import { useUpdateNewGraduateRecruitmentStatus } from "~/hooks/employee/useUpdateNewGraduateRecruitmentStatus";
import { OnUpdateRecruitmentStatus } from "~/hooks/employee/useUpdateNewGraduateRecruitmentStatus/useUpdateNewGraduateRecruitmentStatus";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  newGraduate: NewGraduateToDisplay;
  recruitmentStatus: RecruitmentStatus;
  onUpdateRecruitmentStatus?: OnUpdateRecruitmentStatus;
};

export const ChangeRecruitmentStatusModal: FC<Props> = ({
  open,
  onCancel,
  newGraduate,
  recruitmentStatus,
  onUpdateRecruitmentStatus,
}) => {
  const { updateNewGraduateRecruitmentStatus } = useUpdateNewGraduateRecruitmentStatus({
    onUpdateRecruitmentStatus,
  });

  const handleSubmit = useCallback(() => {
    updateNewGraduateRecruitmentStatus(newGraduate, recruitmentStatus.id);
    onCancel();
  }, [newGraduate, onCancel, recruitmentStatus.id, updateNewGraduateRecruitmentStatus]);

  return (
    <Modal
      open={open}
      title="選考ステータス変更"
      titleHint="選考ステータスを変更すると、設定に基づいてイベント・タスクが候補者に配信されます。"
      content={
        <Box>
          <Box mb="10px">
            <Typography variant="body1" align="center">
              選考ステータス「{recruitmentStatus.label}」にしますか？
            </Typography>
          </Box>

          <Box>
            <Typography variant="body1" align="center">
              ※シナリオの途中での変更をする場合、トリガーされていないアクションは実行・配信されません
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            変更
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
