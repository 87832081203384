import { Employee, NewGraduate, NewGraduateToDisplay } from "@onn/common";
import { useCallback, useContext } from "react";

import { mutate } from "swr";

import { useSnackbar } from "../../shared";

import { useMutateAllNewcomers } from "../useAllNewcomers";
import { useGenerateMutateEmployee } from "../useEmployee";

import { useGenerateMutateNewGraduate } from "../useNewGraduate";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { generateUseRecruitmentProcessRecordsByEmployeeKey } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

export type OnUpdateRecruitmentStatus = (args: {
  updatedNewGraduate: NewGraduateToDisplay;
  updateRecruitmentStatusPromise: Promise<unknown>;
}) => void | Promise<void>;

/**
 * 新卒候補者のRecruitmentStatusを更新する関数を提供するhooks
 */
export const useUpdateNewGraduateRecruitmentStatus = (options?: {
  onUpdateRecruitmentStatus?: OnUpdateRecruitmentStatus;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAllNewcomers } = useMutateAllNewcomers();
  const { generateMutateEmployee } = useGenerateMutateEmployee();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();
  const { recruitmentStatusMap } = useContext(RecruitmentStatusesContext);

  const { contactRoomsMutate } = useContactContext();

  const updateNewGraduateRecruitmentStatus = useCallback(
    async (newGraduate: NewGraduateToDisplay, recruitmentStatusId: string) => {
      try {
        const newRecruitmentStatus = recruitmentStatusMap.get(recruitmentStatusId);
        if (!newRecruitmentStatus) {
          throw new Error("recruitmentStatusIdが不正です");
        }
        const employeeTagIds = newGraduate.employeeTagIds;
        const updatedNewGraduate = new NewGraduateToDisplay(
          new Employee({
            ...newGraduate,
            recruitmentStatusId: newRecruitmentStatus.id,
          }) as NewGraduate,
          employeeTagIds,
          {
            id: newRecruitmentStatus.id,
            label: newRecruitmentStatus.label,
            type: newRecruitmentStatus.type,
          },
          newGraduate.predictionId
        );

        const updateRecruitmentStatusPromise = apiClient.patch(
          "/api/employee/update-recruitment-status",
          {
            employeeId: newGraduate.id,
            recruitmentStatusId,
          }
        );

        // 候補者ステータスの変更を画面に即時反映させるために、楽観的更新を行う
        const mutateEmployee = generateMutateEmployee(newGraduate.id);
        mutateEmployee(
          async () => {
            await updateRecruitmentStatusPromise;
            return updatedNewGraduate;
          },
          {
            optimisticData: updatedNewGraduate,
          }
        );
        const mutateNewGraduate = generateMutateNewGraduate(newGraduate.id);
        mutateNewGraduate(
          async () => {
            await updateRecruitmentStatusPromise;
            return updatedNewGraduate;
          },
          {
            optimisticData: updatedNewGraduate,
          }
        );

        options?.onUpdateRecruitmentStatus?.({
          updatedNewGraduate,
          updateRecruitmentStatusPromise,
        });

        await updateRecruitmentStatusPromise;

        // 全候補者のミューテーション
        mutateAllNewcomers();
        // 選考ステータス変更時に、選考プロセス履歴が作成されるので再取得する
        // 作成される履歴の詳細はフロントエンドでは不明のため、楽観的更新はできない
        mutate(generateUseRecruitmentProcessRecordsByEmployeeKey(newGraduate.id));

        // NOTE:
        // 候補者一覧画面で選考ステータスを更新した後に、コンタクトルームに移動した時に、選考ステータスの更新を反映するためにcontactRoomsMutateを呼び出している
        // その際、楽観的更新の必要性はないと思われるので、ここでcontactRoomsMutateを実行している
        contactRoomsMutate();
      } catch (e) {
        enqueueSnackbar("ステータスの保存に失敗しました。改めて操作をお試しください。", {
          variant: "error",
        });
        captureException({
          error: e as Error,
          tags: {
            type: "useUpdateNewGraduateRecruitmentStatus:updateNewGraduateRecruitmentStatus",
          },
        });
      }
    },
    [
      recruitmentStatusMap,
      generateMutateEmployee,
      generateMutateNewGraduate,
      options,
      mutateAllNewcomers,
      contactRoomsMutate,
      enqueueSnackbar,
    ]
  );

  return { updateNewGraduateRecruitmentStatus };
};
