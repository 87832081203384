import { OnnContactRoom, LineContactRoom, Employee, ContactRoom, LineUser } from "@onn/common";

import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

export const useContactRoom = ({
  isIncludeCurrentUserContactRoom,
  limit,
  contactRoomId,
}: {
  tenantId: string;
  isIncludeCurrentUserContactRoom?: boolean;
  limit?: number;
  contactRoomId?: string;
}) => {
  const { currentUser } = useCurrentUser();
  // NOTE: 同一PCで別テナントにログインした際にキャッシュが残ってしまうので、tenantIdをキーにしている
  const keyObj = {
    endpoint: `/api/contact-room/get-contact-room`,
    tenantId: currentUser.tenantId,
    isIncludeCurrentUserContactRoom,
    requestUserId: currentUser.id,
    limit,
    contactRoomId,
  } as const;

  return useSWR(keyObj, async ({ endpoint, isIncludeCurrentUserContactRoom, contactRoomId }) => {
    const response = await apiClient.get(endpoint, {
      "include-current-user-contact-room": isIncludeCurrentUserContactRoom,
      id: contactRoomId,
    });

    const { contactRoom } = response.data;
    const initSuper = {
      contactRoom: ContactRoom.plainToInstance(contactRoom),
      employee: contactRoom.employee && Employee.plainToInstance(contactRoom.employee),
    };
    if (contactRoom.type === "LINE_USER") {
      const data = contactRoom as LineContactRoom;
      return new LineContactRoom({
        initSuper,
        lineUser: LineUser.plainToInstance(data.lineUser),
      });
    } else {
      return new OnnContactRoom({ initSuper });
    }
  });
};
