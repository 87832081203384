import { zodResolver } from "@hookform/resolvers/zod";
import { PortalRichMenu, richMenuCellTypes, RegistrationRichMenu } from "@onn/common";
import { useCallback } from "react";
import { useForm as useReactHookForm } from "react-hook-form";
import { z } from "zod";

import { useRichMenuTabContext } from "./RichMenuTabContext";

import { useTenant } from "~/hooks/tenant";

const positions = ["A", "B", "C", "D", "E", "F"] as const;

export const useForm = () => {
  const { generateDefaultValues } = useGenerateDefaultValues();

  const { tenant } = useTenant();
  const tenantId = tenant.tenantId;
  const liffId = tenant.lineLiffId;

  const form = useReactHookForm<InputState>({
    defaultValues: generateDefaultValues(),
    mode: "onChange",
    resolver: zodResolver(
      generateFormSchema({
        tenantId,
        liffId: liffId || "",
      })
    ),
  });

  return { form };
};

const useGenerateDefaultValues = () => {
  const context = useRichMenuTabContext();

  const getIsSetRichMenu = useCallback((): "true" | "false" => {
    switch (context.type) {
      case "registrationRichMenu":
        return context.registrationRichMenu !== null ? "true" : "false";
      case "portalRichMenu":
        return context.currentPortalRichMenusRelations !== null ? "true" : "false";
      default: {
        const exhaustiveCheck: never = context;
        return exhaustiveCheck;
      }
    }
  }, [context]);

  const getTargetRichMenu = useCallback(() => {
    let richMenu: PortalRichMenu | RegistrationRichMenu | null;
    switch (context.type) {
      case "registrationRichMenu":
        richMenu = context.registrationRichMenu;
        break;
      case "portalRichMenu":
        richMenu = context.currentPortalRichMenusRelations?.portalRichMenu || null;
        break;
      default: {
        const exhaustiveCheck: never = context;
        return exhaustiveCheck;
      }
    }
    return richMenu;
  }, [context]);

  const generateCellLinkSettingDefaultValues = useCallback(() => {
    const richMenu = getTargetRichMenu();
    return {
      A: {
        type: richMenu?.cell1Type || "",
        messageActionText: richMenu?.cell1MessageText || "",
        externalLinkUrl: richMenu?.cell1ExternalSiteUrl || "",
      },
      B: {
        type: richMenu?.cell2Type || "",
        messageActionText: richMenu?.cell2MessageText || "",
        externalLinkUrl: richMenu?.cell2ExternalSiteUrl || "",
      },
      C: {
        type: richMenu?.cell3Type || "",
        messageActionText: richMenu?.cell3MessageText || "",
        externalLinkUrl: richMenu?.cell3ExternalSiteUrl || "",
      },
      D: {
        type: richMenu?.cell4Type || "",
        messageActionText: richMenu?.cell4MessageText || "",
        externalLinkUrl: richMenu?.cell4ExternalSiteUrl || "",
      },
      E: {
        type: richMenu?.cell5Type || "",
        messageActionText: richMenu?.cell5MessageText || "",
        externalLinkUrl: richMenu?.cell5ExternalSiteUrl || "",
      },
      F: {
        type: richMenu?.cell6Type || "",
        messageActionText: richMenu?.cell6MessageText || "",
        externalLinkUrl: richMenu?.cell6ExternalSiteUrl || "",
      },
    } as const;
  }, [getTargetRichMenu]);

  const generateDefaultValues = useCallback((): InputState => {
    const richMenu = getTargetRichMenu();
    const isSetRichMenu = getIsSetRichMenu();
    return {
      isSetRichMenu: isSetRichMenu,
      richMenuImage: {
        file: null,
        filePath: richMenu?.imagePath ?? null,
      },
      asyncTargetRecruitmentStatusIds: [],
      cellLinkSetting: generateCellLinkSettingDefaultValues(),
    };
  }, [generateCellLinkSettingDefaultValues, getIsSetRichMenu, getTargetRichMenu]);

  return { generateDefaultValues };
};

export const generateFormSchema = ({ tenantId, liffId }: { tenantId: string; liffId: string }) => {
  const cellLinkSchema = z.object({
    type: z.enum([...richMenuCellTypes, ""]),
    messageActionText: z.string().max(300, "300文字以内で入力してください"),
    externalLinkUrl: z.string().refine(
      (val) => {
        // NOTE: 実際にリッチメニューに登録される文字列数が1000文字を超えないようにする
        // https://developers.line.biz/ja/reference/messaging-api/#uri-action
        const actualExternalLinkUrl = `https://liff.line.me/${liffId}/portal/external_page/transition?isLineUserActiveLog=true&from=richmenu&href=${encodeURI(
          val
        )}&tenantId=${tenantId}&spaceId=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx`;
        if (actualExternalLinkUrl.length > 1000) {
          return false;
        }
        return true;
      },
      { message: "文字数が多すぎます。" }
    ),
  });

  return z
    .object({
      isSetRichMenu: z.enum(["true", "false"]),
      richMenuImage: z.object({
        file: z.custom<File | Pick<File, "name">>().nullable(),
        filePath: z.string().nullable(),
      }),
      cellLinkSetting: z.object({
        A: cellLinkSchema,
        B: cellLinkSchema,
        C: cellLinkSchema,
        D: cellLinkSchema,
        E: cellLinkSchema,
        F: cellLinkSchema,
      }),
      asyncTargetRecruitmentStatusIds: z.array(z.string()),
    })
    .superRefine((val, ctx) => {
      if (val.isSetRichMenu === "true") {
        if (!val.richMenuImage.filePath) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "リッチメニューの画像ファイルを選択してください",
            path: ["richMenuImage"],
          });
        }
        positions.forEach((position) => {
          if (val.cellLinkSetting[position].type === "") {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "リッチメニューのセルリンク設定を選択してください",
              path: [`cellLinkSetting.${position}.type`],
            });
          }
          if (
            val.cellLinkSetting[position].type === "MESSAGE_ACTION_LINK" &&
            val.cellLinkSetting[position].messageActionText === ""
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "メッセージアクションのテキストを入力してください",
              path: [`cellLinkSetting.${position}.messageActionText`],
            });
          }
          if (
            val.cellLinkSetting[position].type === "PORTAL_EXTERNAL_PAGE_LINK" &&
            val.cellLinkSetting[position].externalLinkUrl === ""
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "外部リンクのURLを入力してください",
              path: [`cellLinkSetting.${position}.externalLinkUrl`],
            });
          }
        });

        return val;
      }
    });
};

export type InputState = z.infer<ReturnType<typeof generateFormSchema>>;
