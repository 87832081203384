import { generateSampleForBulkUpdateNewGraduateInformation } from "@onn/common";
import { useCallback } from "react";

export const useGenerateSampleCSV = () => {
  const generateSampleCSV = useCallback(() => {
    return generateSampleForBulkUpdateNewGraduateInformation();
  }, []);

  return { generateSampleCSV };
};
