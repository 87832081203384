import { Box } from "@material-ui/core";
import { FileType } from "@onn/common";
import React, { ComponentProps, FC, useRef, useState, useCallback, ReactNode } from "react";
import styled from "styled-components";

import { FileDropArea } from "~/components/uiParts/FileDropArea";
import { FilePicker } from "~/components/uiParts/FilePicker";
import { FileTag } from "~/components/uiParts/FileTag";
import { IconButton } from "~/components/uiParts/IconButton";
import { TextareaAutosize } from "~/components/uiParts/TextareaAutosize";
import { Typography } from "~/components/uiParts/Typography";
import { useSnackbar } from "~/hooks/shared";
import { usePreventDragEventFromDropArea } from "~/hooks/shared/usePreventDragEventFromDropArea";
import { getNameAndExtensionFromFilePath } from "~/util/getNameAndExtensionFromFilePath";

type Payload = { status: "success"; files: File[] } | { status: "error"; message: string };

type CustomProps = {
  maxFileSizeMb: number;
  accepts: FileType[];
  inputAccept?: string;
  onChangeFiles: (newFiles: Array<File | Pick<File, "name">>) => void;
  attachedFiles: Array<File | Pick<File, "name">>;
  footerLeftActions?: React.ReactNode;
  footerButtons?: ReactNode[];
  isFileSending?: boolean;
};

type Props = ComponentProps<typeof TextareaAutosize> & CustomProps;

export const TextareaAutosizeAttachableFile: FC<Props> = React.forwardRef<
  HTMLTextAreaElement,
  Props
>(
  (
    {
      maxFileSizeMb,
      accepts,
      inputAccept,
      onChangeFiles,
      attachedFiles,
      footerLeftActions,
      footerButtons,
      readOnly,
      isFileSending,
      error,
      helperText,
      ...props
    },
    ref
  ) => {
    const fileDropAreaRef = useRef<HTMLFormElement>(null);
    const [fileDropAreaVisible, setFileDropAreaVisible] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    usePreventDragEventFromDropArea({
      listenerRef: fileDropAreaRef,
      onDragOver: () => setFileDropAreaVisible(true),
      onDragLeave: () => setFileDropAreaVisible(false),
    });

    const handleChangeFiles = useCallback(
      (payload: Payload) => {
        if (payload.status === "error") {
          return enqueueSnackbar(payload.message, { variant: "error" });
        }

        const attachedFileNames = attachedFiles.map((v) => {
          const { name, extension } = getNameAndExtensionFromFilePath(v.name);
          return `${name}.${extension}`;
        });

        // 同名ファイルの場合は添付できない
        if (payload.files.some((file) => attachedFileNames.includes(file.name))) {
          return enqueueSnackbar("同名ファイルは添付できません", { variant: "error" });
        }

        onChangeFiles([...attachedFiles, ...payload.files]);
      },
      [attachedFiles, enqueueSnackbar, onChangeFiles]
    );

    const handleRemoveFile = useCallback(
      (fileName: string) => onChangeFiles(attachedFiles.filter((file) => file.name !== fileName)),
      [attachedFiles, onChangeFiles]
    );

    return (
      <>
        <StyledForm ref={fileDropAreaRef} $isError={!!error}>
          <StyledTextareaAutosize fullWidth readOnly={readOnly} {...props} ref={ref} />

          <Box pl={2} pr={2} mb={2}>
            {fileDropAreaVisible ? (
              <FileDropArea
                maxFileSizeMb={maxFileSizeMb}
                accepts={accepts}
                onDropFiles={(payload) => {
                  setFileDropAreaVisible(false);
                  handleChangeFiles(payload);
                }}
              >
                <Box display="flex" justifyContent="center" pt={3} pb={3}>
                  <Typography variant="body2">ファイルをドロップしてください。</Typography>
                </Box>
              </FileDropArea>
            ) : attachedFiles.length && !isFileSending ? (
              <Box display="flex" flexWrap="wrap" gridColumnGap="16px" gridRowGap="8px">
                {attachedFiles.map((file, i) => {
                  const { name, extension } = getNameAndExtensionFromFilePath(file.name);
                  return (
                    <FileTag
                      key={i}
                      name={name}
                      extension={extension}
                      onClickDeleteButton={readOnly ? undefined : () => handleRemoveFile(file.name)}
                    />
                  );
                })}
              </Box>
            ) : null}
          </Box>
          <StyledBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="8px"
          >
            <Box display="flex" gridGap="5px">
              {!readOnly && (
                <FilePicker
                  maxFileSizeMb={maxFileSizeMb}
                  accepts={accepts}
                  multiple
                  onChange={handleChangeFiles}
                  inputAccept={
                    inputAccept ||
                    "application/gzip, .pdf, .doc, .docx, .xls, .xlsx, .pptx, .ppt, .gif, .jpg, .jpeg, .png, .zip"
                  }
                >
                  <IconButton
                    icon="clip"
                    size="sm"
                    color="grey"
                    borderRadius="regular"
                    // IconButtonのクリック機能は利用せず、FilePickerの見た目を再現するために利用するため
                    onClick={() => void 0}
                  />
                </FilePicker>
              )}
              {footerLeftActions}
            </Box>

            {footerButtons && (
              <Box display="flex" alignItems="center" gridGap="8px">
                {footerButtons.map((footerButton) => {
                  return footerButton;
                })}
              </Box>
            )}
          </StyledBox>
        </StyledForm>
        {helperText && (
          <StyledTypography variant="overline" color={error ? "error" : "textSecondary"}>
            {helperText}
          </StyledTypography>
        )}
      </>
    );
  }
);

const StyledTextareaAutosize = styled(TextareaAutosize)`
  border: none !important;
`;

const StyledForm = styled.form<{ $isError: boolean }>`
  border: 1px solid
    ${(props) => (props.$isError ? props.theme.palette.error.main : props.theme.palette.grey[200])};
  border-radius: 4px;
  background-color: white;

  &:hover {
    border: 1px solid
      ${(props) =>
        props.$isError ? props.theme.palette.error.main : props.theme.palette.action.active};
  }

  &:focus-within {
    border: 2px solid
      ${(props) =>
        props.$isError ? props.theme.palette.error.main : props.theme.palette.primary.main};
  }
`;

const StyledBox = styled(Box)`
  ${StyledForm}:focus-within & {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    margin: -1px;
  }
`;

const StyledTypography = styled(Typography)`
  display: block;
`;
